function rangeIndices(ranges) {
  const obj = {};

  ranges.forEach(([a, b]) => {
    const min = Math.min(a, b);
    const max = Math.max(a, b);

    for (let i = min; i <= max; i++) {
      obj[i] = true;
    }
  });

  return obj;
}

export default rangeIndices;
