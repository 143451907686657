<script>
  import { onMount, getContext } from "svelte";
  import { navigate } from "svelte-routing";
  import isEmpty from "lodash/isEmpty";
  import { LinearDisplayFormat } from "dimtext";
  import get from "lodash/get";
  import { distSq } from "vector";

  import ItemBadges from "./ItemBadges.svelte";
  import liteDrawing from "@local/extensions/drawing/lite-drawing.js";
  import { draggable } from "svelte-utilities";
  import {
    selectedItems,
    selectedCollections,
    selectedType,
    settingDisplayTnQty,
    settingDisplayTnDims,
    settingDisplayTnMark,
    settingDisplayTnBadges,
    settingDisplayEdgeOffsets,
    pathRoot,
  } from "src/stores/ui.js";

  import imageUrl from "src/extensions/image-url.js";
  import imageDecode from "src/extensions/dom/image-decode.js";
  import { drawingSrc as getDrawingSrc } from "src/api";

  export let group;
  export let item;
  export let itemList;
  export let maxHeight;
  export let cssHeight;
  export let dragging;
  export let isDragging;
  export let category = null;
  export let displayUnit;
  export let displayPrecision;
  export let dimFormat;
  export let isCollection = false;
  export let comments = 0;
  export let attachments = 0;
  export let collectionItems = 0;

  const typeColors = getContext("typeColorsTrue");
  const dpi = window.devicePixelRatio;

  // Padding around each thumbnail, in px
  const top = 4;
  const right = 4;
  const left = 4;

  // Size of thumbnail for uploaded drawing
  const ts = 60;

  let container;
  let canvas;
  let ctx;
  let dragStartPt;
  let imageProps;

  $: typeColor = $typeColors[item.type_id];
  $: currentType = $group.types[item.type_id];
  $: dimstring = getDimString(item, dimFormat, displayPrecision, currentType);
  $: bottom = ($settingDisplayTnDims ? 19 : 0) + ($settingDisplayTnMark ? 30 : 0) + 1;
  $: selectionSet = isCollection ? selectedCollections : selectedItems;
  $: selected = $selectionSet[item.id];
  $: replaceWithImage = item.drawing || (item.shape.type === "none" && currentType?.image);
  $: thumbnailHt = getThumbnailHt(item, cssHeight, maxHeight, replaceWithImage, imageProps);
  $: offsetY = getOffsetY(ctx, thumbnailHt);
  $: containerHt = getContainerHt(item, cssHeight, offsetY);
  $: renderProps = ctx && getRenderProps(item, containerHt, thumbnailHt, imageProps);
  $: offsetX = getOffsetX(ctx, offsetY, renderProps);
  $: thumbnailWd = getThumbnailWd(thumbnailHt, offsetX, maxHeight, item, replaceWithImage, imageProps);
  $: leftOffset = getLeftOffset(renderProps, thumbnailWd, offsetX);
  $: drawingSrc = getImage(item, currentType);
  $: drawing = liteDrawing(item, {
    offsetX,
    offsetY,
    typeColor,
    labelBy: isCollection ? $group.data.settings.collections_label_by : $group.data.settings.label_by,
    showDims: $settingDisplayTnDims,
    showMark: $settingDisplayTnMark,
    showQty: $settingDisplayTnQty,
    showOffsets: $settingDisplayEdgeOffsets,
    isCollection,
    imageProps,
  });
  $: refresh(drawingSrc);
  $: {
    if (ctx && renderProps) {
      item;
      cssHeight;
      selected;
      render(renderProps);
    }
  }

  function getImage(item, currentType) {
    if (item.drawing) {
      return getDrawingSrc(item.drawing);
    } else if (item.shape.type === "none" && currentType?.image) {
      return imageUrl(currentType.image);
    }
  }

  async function refresh(drawingSrc) {
    if (item.shape.type === "none" && currentType?.image) {
      const p = await imageDecode(drawingSrc);
      imageProps = p;
    }
  }

  function getDimString(item, dimFormat, displayPrecision, currentType) {
    if (item.shape.type === "none") {
      return currentType?.name;
    } else {
      const fmt = {
        DECIMAL: LinearDisplayFormat.DECIMAL,
        FRACTIONAL: LinearDisplayFormat.FRACTIONAL,
      }[dimFormat];
      if (replaceWithImage) return "";
      const widthTxt = item.cache.width_prime?.format(fmt, displayPrecision, {
        displayUnit,
        unicodeNumerals: true,
        unicodeSlash: true,
        fractionSeparator: false,
      });

      const heightText = item.cache.height_prime?.format(fmt, displayPrecision, {
        displayUnit,
        unicodeNumerals: true,
        unicodeSlash: true,
        fractionSeparator: false,
      });

      return `${widthTxt} x ${heightText}`;
    }
  }

  function getRenderProps(item, containerHt, thumbnailHt, imageProps) {
    const h = Math.ceil(containerHt * dpi); // drawing height in physical px
    const iH = thumbnailHt * dpi; // item height in physical px
    let width;
    let zs; // zoom scale
    if (item.drawing) {
      if (item.drawing.properties) {
        const ar = item.drawing.properties.width / item.drawing.properties.height;
        width = ar * ts;
        zs = iH / ts;
      } else {
        width = ts;
        zs = iH / ts;
      }
    } else if (imageProps) {
      const ar = imageProps.width / imageProps.height;
      width = ar * ts;
      zs = iH / ts;
    } else {
      width = item.cache.width;
      zs = iH / item.cache.height;
    }
    const asc = dpi / zs; // base annotation scale for drawing
    const w = Math.ceil(width * zs) + dpi * (left + right); // width of drawing in physical px
    const qtw = $settingDisplayTnQty ? qtyTextWidth(ctx, 12, asc) * zs : 0;
    const mw = $settingDisplayTnMark ? markWidth(ctx, 12, asc) * zs : 0; // get width of mark label
    const ox = item.quantity !== 1 && qtw > w ? Math.floor(qtw) : 0; // width of quantity label
    const aw = Math.max(w, ox, mw); // Max of item, qty, mark, dim
    // const ow = Math.max(w * 3, qtw * 2);
    const adjustedW = aw;
    // const adjustedW = aw > w * 2 ? Math.max(ow, aw) : aw;
    const cw = Math.ceil(adjustedW / dpi); // width of drawing in css px
    const xs = (adjustedW - w) / 2;

    return {
      h,
      zs,
      asc,
      w,
      iH,
      qtw,
      mw,
      adjustedW,
      cw,
      xs,
    };
  }

  function getOffsetY(ctx, thumbnailHt) {
    if (!ctx) return null;
    return thumbnailHt < 20 ? thumbnailHt + 15 : null;
  }

  function getOffsetX(ctx, offsetY, renderProps) {
    if (!ctx) return 0;
    if (offsetY) return 0;

    return renderProps.qtw > renderProps.w ? renderProps.qtw / renderProps.zs : 0;
  }

  function getContainerHt(i, c, oy) {
    const ht = replaceWithImage ? ts : item.cache.height;
    const pct = ht / maxHeight;
    const maxH = cssHeight - top - bottom;
    return Math.max(maxH * pct, 30) + top + bottom + (oy ? 15 : 0);
  }

  function getThumbnailHt(item, cssHeight, maxHeight, rwi, ip) {
    let ht;

    if (item.drawing?.properties || rwi) {
      ht = ts;
    } else {
      ht = item.cache.height;
    }

    const pct = ht / maxHeight;
    const maxH = cssHeight - top - bottom;
    return Math.floor(maxH * pct);
  }

  function getThumbnailWd(th, offsetX, maxHeight, item, rwi, ip) {
    let pct;

    if (item.drawing) {
      if (item.drawing?.properties) {
        pct = item.drawing.properties.width / item.drawing.properties.height;
      } else {
        pct = 1;
      }
    } else if (rwi) {
      if (ip) {
        pct = ip.width / ip.height;
      } else {
        pct = 1;
      }
    } else {
      pct = item.cache.width / item.cache.height;
    }

    return Math.floor(th * pct) + 1;
  }

  function getLeftOffset(rp, tw, offsetX) {
    if (!rp) return 0;
    if (offsetX && item.quantity !== 1) {
      const dist = offsetX / 2;
      const scale = rp.zs / dpi;
      return dist * scale;
    }
    return 0;
  }

  function qtyTextWidth(ctx, fontSize, annoScale) {
    const txt = `(${item.quantity})`;
    ctx.font = `${fontSize * annoScale}px Menlo`;
    const m = ctx.measureText(txt);
    return m.width;
  }

  function markWidth(ctx, fontSize, annoScale) {
    ctx.font = `${fontSize * annoScale}px Menlo`;
    const min = 2 * fontSize * annoScale;
    const labelBy = isCollection ? $group.data.settings.collections_label_by : $group.data.settings.label_by;
    const m = ctx.measureText(get(item, labelBy) || "");
    const tw = m.width;
    return min + (tw - fontSize * annoScale) + 3;
  }

  function render(renderProps) {
    const { h, zs, asc, adjustedW, cw, xs } = renderProps;

    canvas.style.width = `${cw}px`;
    canvas.style.height = `${containerHt}px`;
    canvas.width = adjustedW;
    canvas.height = h;

    // zoom
    ctx.transform(zs, 0, 0, zs, 0, 0);

    // translate
    const dx = (left * dpi + xs) / zs;
    const dy = (h - bottom * dpi) / zs;
    ctx.transform(1, 0, 0, 1, dx, dy);

    drawing.render({
      ctx,
      annoScale: asc,
    });
  }

  function selectItem(evt) {
    $selectedType = null;

    if (evt.shiftKey && !isEmpty($selectionSet)) {
      const allIds = itemList.map((i) => i.id);
      const indices = itemList
        .map((i, index) => index)
        .filter((index) => $selectionSet[itemList[index].id] || itemList[index].id === item.id);

      const min = Math.min(...indices);
      const max = Math.max(...indices);

      const ids = allIds.slice(min, max + 1);
      selectionSet.selectOnly(...ids);
    } else if (evt.metaKey) {
      if (!$selectionSet[item.id]) {
        selectionSet.select(item.id);
      } else {
        selectionSet.deselect(item.id);
      }
    } else {
      selectionSet.selectOnly(item.id);
    }
  }

  function dragstart(e) {
    const { event, x, y } = e.detail;
    event.stopPropagation();

    if (event.metaKey || event.shiftKey) {
      selectItem(event);
      return;
    }

    dragStartPt = { x, y };
    if ($selectionSet[item.id]) {
      dragging = { items: $selectionSet, category };
    } else {
      dragging = { items: { [item.id]: true }, category };
    }
  }

  function drag(e) {
    const p = e.detail;

    const d = dragStartPt ? distSq(p, dragStartPt) : 0;

    if (dragging && d > 100) {
      isDragging = true;
    } else {
      isDragging = false;
    }
  }

  function dragend(e) {
    const { event } = e.detail;
    if (!event.metaKey && !event.shiftKey) {
      selectItem(event);
    }

    isDragging = false;
    dragging = null;
  }

  function nav() {
    const newpath = `${$pathRoot}${isCollection ? "openings" : "items"}/${item.id}`;
    navigate(newpath);
  }

  onMount(() => {
    ctx = canvas.getContext("2d");
  });
</script>

<button
  class="flex-none cursor-pointer relative text-center flex flex-col items-center"
  style={`height:${containerHt}px`}
  use:draggable
  on:dragstart={dragstart}
  on:drag={drag}
  on:dragend={dragend}
  on:dblclick={nav}
  bind:this={container}
  on:click|stopPropagation>
  <a class="anchor" id={`thumbnail-${item.id}`} />
  <div
    class="absolute w-full"
    style="
      left:{leftOffset}px;
      bottom:{bottom - 1}px;
    ">
    {#if $settingDisplayTnBadges}
      <ItemBadges {comments} {attachments} items={collectionItems} quantity={item.quantity} />
    {/if}
    <div
      class="mx-auto mt-1"
      class:dragging={isDragging && dragging.items[item.id]}
      class:ring-4={selected}
      style="
        height:{thumbnailHt + 2}px;
        width:{thumbnailWd ? thumbnailWd + 'px' : '100%'};
      ">
      &nbsp;
    </div>
  </div>
  {#if replaceWithImage}
    <div
      class="absolute w-full"
      style="
          left:{leftOffset}px;
          bottom:{bottom - 1}px;
        ">
      <div
        class="mx-auto"
        style="
        height:{thumbnailHt + 2}px;
        width:{thumbnailWd}px;
      ">
        <img
          class="w-full h-full border border-gray-400 object-contain text-xs text-gray-400"
          src={drawingSrc}
          alt="Item Thumbnail" />
      </div>
    </div>
  {/if}
  <div class="h-full contents" class:dragging={isDragging && dragging.items[item.id]}>
    <canvas bind:this={canvas} />
  </div>
  {#if $settingDisplayTnDims && (!!(item.width && item.height) || item.shape.type === "none")}
    <div class="dimension-text" class:shift-bottom={$settingDisplayTnMark}>{dimstring}</div>
  {/if}
</button>

<style lang="scss">
  .anchor {
    display: block;
    position: relative;
    top: -4rem;
    visibility: hidden;
  }

  .dragging {
    @apply border border-dashed bg-gray-200 border-gray-400;
  }
  .dragging.contents {
    visibility: hidden;
  }

  .dimension-text {
    @apply relative text-center text-xs whitespace-nowrap;
    margin-bottom: -3rem;
    margin-top: -1rem;

    &.shift-bottom {
      margin-top: -3rem;
    }
  }
</style>
