import { add, scale } from "vector";

class Pin {
  constructor(center, offset = { x: 0, y: 0 }) {
    this.center = center;
    this.offset = offset;
    this.type = "pin";
  }

  render(options) {
    const ctx = options.ctx;
    const annoScale = options.annoScale;
    const ctr = add(this.center, scale(this.offset, annoScale));
    const size = annoScale * options.fontSize;
    ctx.style("annoScale", annoScale);
    const a = add(ctr, scale({ x: -0.74535, y: 0.833311 }, size));
    const b = add(ctr, scale({ x: 0.74535, y: 0.833311 }, size));
    const c = add(ctr, scale({ x: 0, y: 1.5 }, size));
    ctx.path([
      { command: "M", params: [ctr.x, ctr.y] },
      { command: "L", params: [a.x, a.y] },
      { command: "A", params: [size, size, 0, 1, 0, b.x, b.y] },
      { command: "Z", params: [] },
    ]);
    // ctx.circle(ctr.x, ctr.y, fontSize);

    if (options.fill) {
      ctx.style("fill", options.fill);
      ctx.fill();
    }

    if (options.stroke && options.lineWidth) {
      ctx.style("stroke", options.stroke);
      ctx.style("lineWidth", options.lineWidth * annoScale);
      ctx.stroke();

      ctx.circle(c.x, c.y, 0.2 * size);
      ctx.style("fill", options.stroke);
      ctx.fill();
      ctx.stroke();
    }
  }

  renderHitbox(options) {
    this.render(options);

    const annoScale = options.annoScale;
    const ctr = add(this.center, scale(this.offset, annoScale));

    return { pt: ctr };
  }
}

export default Pin;
