import { add, scale } from "vector";
import transformPt from "../utils/transform-pt.js";
import { sagittaArc } from "../utils/sagitta-arc.js";
import { sweep } from "../utils/sweep.js";
import { fillet } from "../utils/fillet.js";

class Symbol {
  constructor(shapes, center, { offset = { x: 0, y: 0 } } = {}) {
    this.shapes = shapes;
    this.center = center;
    this.offset = offset;
    this.type = "symbol";
  }

  render(options) {
    const ctx = options.ctx;
    const annoScale = options.annoScale;
    const ctr = add(this.center, scale(this.offset, annoScale));
    ctx.style("annoScale", annoScale);

    this.shapes.forEach((shape) => {
      const opts = {
        ...options,
        ...shape.options,
      };
      if (shape.type === "polyline") {
        const vertices = shape.vertices.map((v) =>
          add(scale(v, annoScale), ctr),
        );
        const [start, ...rest] = vertices;

        ctx.beginPath();
        ctx.moveTo(start.x, start.y);
        rest.forEach((v, i) => {
          const next = vertices[i + 2];
          if (v.fillet && next) {
            const { a, b, saf, invalid } = fillet(
              vertices[i],
              v,
              next,
              v.fillet,
            );

            if (invalid) {
              ctx.lineTo(v.x, v.y);
            } else {
              ctx.lineTo(a.x, a.y);
              ctx.arcTo(b.x, b.y, v.fillet, "0", saf);
            }
          } else if (v.bulge) {
            const a = vertices[i];
            const { r, sa, ea, ccw } = sagittaArc(a, v, v.bulge);
            const s = sweep(sa, ea, ccw);
            const laf = s > Math.PI ? "1" : "0";
            const sf = ccw ? "1" : "0";
            ctx.arcTo(v.x, v.y, r, laf, sf);
          } else {
            ctx.lineTo(v.x, v.y);
          }
        });

        ctx.style("stroke", opts.stroke);
        ctx.style("lineWidth", opts.lineWidth * annoScale);
        if (opts.lineDash) {
          ctx.style(
            "lineDash",
            opts.lineDash ? opts.lineDash.map((l) => l * annoScale) : [],
          );
        }
        ctx.stroke();
        if (opts.lineDash) {
          ctx.style("lineDash", []);
        }
      } else if (shape.type === "polyface") {
        const faces = shape.faces.map((face) =>
          face.map((v) => add(scale(v, annoScale), ctr)),
        );
        ctx.beginPath();
        faces.forEach((face) => {
          const [start, ...rest] = face;

          if (start.fillet) {
            const { a, b, saf, invalid } = fillet(
              rest[rest.length - 1],
              start,
              rest[0],
              start.fillet,
            );

            if (invalid) {
              ctx.moveTo(start.x, start.y);
            } else {
              ctx.moveTo(a.x, a.y);
              ctx.arcTo(b.x, b.y, start.fillet, "0", saf);
            }
          } else {
            ctx.moveTo(start.x, start.y);
          }

          rest.forEach((v, i) => {
            if (v.fillet) {
              const { a, b, saf, invalid } = fillet(
                face[i],
                v,
                face[(i + 2) % face.length],
                v.fillet,
              );
              if (invalid) {
                ctx.lineTo(v.x, v.y);
              } else {
                ctx.lineTo(a.x, a.y);
                ctx.arcTo(b.x, b.y, v.fillet, "0", saf);
              }
            } else if (v.bulge) {
              const a = face[i];
              const { r, sa, ea, ccw } = sagittaArc(a, v, v.bulge);
              const s = sweep(sa, ea, ccw);
              const laf = s > Math.PI ? "1" : "0";
              const sf = ccw ? "1" : "0";
              ctx.arcTo(v.x, v.y, r, laf, sf);
            } else {
              ctx.lineTo(v.x, v.y);
            }
          });

          if (start.bulge) {
            const a = rest[rest.length - 1];
            const { r, sa, ea, ccw } = sagittaArc(a, start, start.bulge);
            const s = sweep(sa, ea, ccw);
            const laf = s > Math.PI ? "1" : "0";
            const sf = ccw ? "1" : "0";
            ctx.arcTo(start.x, start.y, r, laf, sf);
          }

          ctx.closePath();
        });

        ctx.style("stroke", opts.stroke);
        ctx.style("fill", opts.fill);
        ctx.style("lineWidth", opts.lineWidth * annoScale);
        if (opts.lineDash) {
          ctx.style(
            "lineDash",
            opts.lineDash.map((l) => l * annoScale),
          );
        }
        ctx.fill();
        ctx.stroke();
        if (opts.lineDash) {
          ctx.style("lineDash", []);
        }
      }
    });
  }

  get bbox() {
    return {
      xmin: this.center.x,
      xmax: this.center.x,
      ymin: this.center.y,
      ymax: this.center.y,
    };
  }

  transform(matrix) {
    const ctr = transformPt(this.center, matrix);
    return new Symbol(this.drawing, ctr, { offset: this.offset });
  }
}

export default Symbol;
