import Polyface from "./polyface.js";
import transformPt from "../utils/transform-pt.js";
import { sagittaArc } from "../utils/sagitta-arc.js";
import { sweep } from "../utils/sweep.js";
import { fillet } from "../utils/fillet.js";

class Mask extends Polyface {
  constructor(faces) {
    super(faces);
    this.type = "mask";
  }

  render(options) {
    const ctx = options.ctx;

    ctx.beginMask();
    this.faces.forEach((face) => {
      const [start, ...rest] = face;

      if (start.fillet) {
        const { a, b, saf, invalid } = fillet(
          rest[rest.length - 1],
          start,
          rest[0],
          start.fillet,
        );

        if (invalid) {
          ctx.moveTo(start.x, start.y);
        } else {
          ctx.moveTo(a.x, a.y);
          ctx.arcTo(b.x, b.y, start.fillet, "0", saf);
        }
      } else {
        ctx.moveTo(start.x, start.y);
      }

      rest.forEach((v, i) => {
        if (v.fillet) {
          const { a, b, saf, invalid } = fillet(
            face[i],
            v,
            face[(i + 2) % face.length],
            v.fillet,
          );
          if (invalid) {
            ctx.lineTo(v.x, v.y);
          } else {
            ctx.lineTo(a.x, a.y);
            ctx.arcTo(b.x, b.y, v.fillet, "0", saf);
          }
        } else if (v.bulge) {
          const a = face[i];
          const { r, sa, ea, ccw } = sagittaArc(a, v, v.bulge);
          const s = sweep(sa, ea, ccw);
          const laf = s > Math.PI ? "1" : "0";
          const sf = ccw ? "1" : "0";
          ctx.arcTo(v.x, v.y, r, laf, sf);
        } else {
          ctx.lineTo(v.x, v.y);
        }
      });

      if (start.bulge) {
        const a = rest[rest.length - 1];
        const { r, sa, ea, ccw } = sagittaArc(a, start, start.bulge);
        const s = sweep(sa, ea, ccw);
        const laf = s > Math.PI ? "1" : "0";
        const sf = ccw ? "1" : "0";
        ctx.arcTo(start.x, start.y, r, laf, sf);
      }

      ctx.closePath();
    });
  }

  transform(matrix) {
    const faces = this.faces.map((face) =>
      face.map((pt) => ({ ...pt, ...transformPt(pt, matrix) })),
    );

    return new Mask(faces);
  }
}

export default Mask;
