import { angle, add, rotate, scale } from "vector";
import alignmentPt from "./alignment-pt.js";

function rectPosition(center, feature, evec, ovec) {
  const width = feature.width.toNumber("inches");
  const height = feature.height.toNumber("inches");
  const orientation = feature.orientation;
  const alignment = feature.alignment;

  let o = center;
  let r = 0;

  if (alignment === "center") {
    if (orientation === "edge-aligned") {
      r = angle(evec);
      const rt = rotate({ x: -width / 2, y: -height / 2 }, r);
      o = add(o, rt);
      o = add(o, { x: width / 2, y: height / 2 });
    }
    o = add(o, { x: -width / 2, y: -height / 2 });
  } else {
    if (orientation === "edge-aligned") {
      r = angle(evec);
      if (feature.reference.start === "end") {
        const ov = scale(ovec, height);
        o = add(o, ov);
      }
    } else {
      const t = alignmentPt(evec, feature.reference, width, height);
      o = add(o, t);
    }
  }

  return { rot: r, pos: o, width, height };
}

export default rectPosition;
