import Polygon from "./polygon.js";
import ngon from "../utils/ngon.js";

class NGon extends Polygon {
  constructor({ x, y }, radius, numPts = 3) {
    const vertices = ngon(x, y, radius, numPts);
    super(vertices);
  }
}

export default NGon;
