<script>
  import { onMount, createEventDispatcher } from "svelte";
  import get from "lodash/get";
  import Polyface from "@local/extensions/geometry/polyface.js";

  export let props;
  export let job;
  export let item;
  export let validator = (v) => true;
  export let parser = (v) => v;

  const dispatch = createEventDispatcher();

  let inputValue = storedValue();
  let input;

  $: polyface = new Polyface(item, $job.data.fabrications);
  $: inputPosition = `left:${props.pt.x}px;top:${props.pt.y}px`;
  $: valid = validate(inputValue);

  function storedValue() {
    return get(item, props.id) || "";
  }

  function validate(v) {
    try {
      return validator(v);
    } catch (err) {
      return false;
    }
  }

  function handleInput() {
    let val = inputValue;

    try {
      val = parser(val);
      inputValue = val;

      if (valid && inputValue !== storedValue()) {
        job.updateItem(item.id, props.id, inputValue);
      }
    } catch (err) {}
  }

  function keydown(e) {
    if (e.key === "Enter") {
      handleInput();
      dispatch("stopEditing");
    }
  }

  function blur() {
    handleInput();
    dispatch("stopEditing");
  }

  onMount(() => {
    input.select();
  });
</script>

<div class="absolute rounded bg-white drop-shadow-lg text-xs font-mono" style={inputPosition}>
  <input
    class="text-center"
    bind:this={input}
    bind:value={inputValue}
    size={Math.max(inputValue.toString().length, 2)}
    on:keydown={keydown}
    on:blur={blur} />
</div>

<style lang="scss">
  div {
    transform: translate(-50%, -50%);
  }

  input {
    min-width: 10px;
    max-width: 100%;
    margin: 0;
    padding: 0.25rem;
  }
</style>
