import loadSheetJS from "smlxl/sheetjs";
import { pick } from "overline/iterable";

/**
 *
 * @param {Array<import('xlsx').ColInfo>} columnMeta
 * @returns {() => Generator<number, never, undefined>}
 */
function visibleColumnIndexer(columnMeta) {
  /** @type {Array<number>} */
  const memo = [];
  let q0 = 0;

  // Safely memoize a column index
  function push(q) {
    if (q >= q0) {
      memo.push(q);
      q0 = q + 1;
    }
  }

  return function* visibleColumns() {
    // Yield memoized column indexes first
    yield* memo;

    let q = q0;
    while (true) {
      if (columnMeta?.[q]?.hidden !== true) {
        push(q);
        yield q;
      }
      q++;
    }
  };
}

/**
 *
 * @param {import('xlsx').WorkBook} book
 * @param {string} sheetName
 */
function getSheet(book, sheetName, utils, skipHidden = true) {
  const sheet = book.Sheets[sheetName];
  if (sheet) {
    let result = utils.sheet_to_json(sheet, {
      skipHidden,
      header: 1, // Return array instead of a mapping
    });

    // Post-process to actually delete hidden columns, rather than just blanking them

    if (skipHidden) {
      const columnMeta = sheet["!cols"];
      const visibleColumns = visibleColumnIndexer(columnMeta);
      result = result.map((row) => [...pick(visibleColumns(), row)]);
    }
    return result;
  }
  return undefined;
}

async function parseXLS(rawData) {
  const { read, utils } = await loadSheetJS();

  const bookMeta = read(rawData, {
    bookSheets: true,
  });

  const sheetNames = bookMeta.SheetNames;

  /** @type  {import('xlsx').WorkBook}*/
  let book;
  const sheets = {};

  /* Lazy load sheets */
  // https://humanwhocodes.com/blog/2021/04/lazy-loading-property-pattern-javascript/

  for (const sheetName of sheetNames) {
    Object.defineProperty(sheets, sheetName, {
      get() {
        if (!book) {
          book = read(rawData, {
            cellFormula: false,
            cellStyles: true, // required to get hidden rows/cols
            dense: true,
          });
        }
        const sheet = getSheet(book, sheetName, utils);

        Object.defineProperty(sheets, sheetName, {
          value: sheet,
          writable: false,
          configurable: false,
        });

        return sheet;
      },
      configurable: true,
      enumerable: true, // yes?
    });
  }

  return {
    sheetNames,
    sheets,
  };
}

export default parseXLS;
