import { Drawing } from "drawing";

export default function axisDimensions(
  tickmap,
  polyface,
  dimstyle,
  axispos,
  edge,
  axis = "vertical",
  direction = "bottom",
) {
  const ticks = [...tickmap.keys()];
  const below = ticks.filter((t) => t < axispos).sort((a, b) => b - a);
  const above = ticks.filter((t) => t > axispos).sort((a, b) => a - b);
  const side = axis === "vertical" ? "x" : "y";

  let dims = [];
  let tier = 1;
  if (["top", "left"].includes(direction)) tier = -1;
  let i = 0;

  below.forEach((d, index) => {
    const vertices = tickmap.get(d);
    let a;
    let b;

    const prev = index > 0 ? below[index - 1] : axispos;
    if (axis === "vertical") {
      a = { x: prev, y: edge };
      b = { x: d, y: edge };
    } else {
      a = { x: edge, y: prev };
      b = { x: edge, y: d };
    }

    const callback = (v) => {
      const dx = prev - d - v;

      return below
        .slice(index)
        .reduce((list, tick) => {
          list.push(...tickmap.get(tick));
          return list;
        }, [])
        .map(([li, vi]) => ({
          path: `shape.vertices.${li}.${vi}.${side}`,
          value: polyface.shape[li][vi][side] + dx,
        }));
    };

    const dim = new Drawing()
      .aligned_dim(a, b, { callback, tier })
      .style(dimstyle)
      .name(`dim_${direction}axistick_${i}`);
    dims.push(dim);

    i += 1;
  });

  above.forEach((d, index) => {
    let a;
    let b;

    const prev = index > 0 ? above[index - 1] : axispos;
    if (axis === "vertical") {
      a = { x: prev, y: edge };
      b = { x: d, y: edge };
    } else {
      a = { x: edge, y: prev };
      b = { x: edge, y: d };
    }

    const callback = (v) => {
      const dx = v - (d - prev);

      return above
        .slice(index)
        .reduce((list, tick) => {
          list.push(...tickmap.get(tick));
          return list;
        }, [])
        .map(([li, vi]) => ({
          path: `shape.vertices.${li}.${vi}.${side}`,
          value: polyface.shape[li][vi][side] + dx,
        }));
    };

    const dim = new Drawing()
      .aligned_dim(a, b, { callback, tier: tier * -1 })
      .style(dimstyle)
      .name(`dim_${direction}axistick_${i}`);
    dims.push(dim);

    i += 1;
  });

  return dims;
}
