<script>
  import { createEventDispatcher } from "svelte";

  export let value;
  export const focus = () => {
    input.focus();
  };

  const dispatch = createEventDispatcher();

  let input;

  function keydown(e) {
    if (e.key === "Enter" && value !== "") {
      dispatch("update", value);
    }
  }
</script>

<input
  bind:this={input}
  type="text"
  placeholder="Column name"
  on:keydown={keydown}
  on:blur={() => dispatch("update", value)}
  on:click|stopPropagation
  on:mousedown|stopPropagation
  bind:value
/>

<style lang="scss">
  input {
    @apply bg-blue-100;
    min-width: 10px;
    max-width: 100%;
    margin: 0;
    padding-left: 0.25rem;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    outline: none;

    &:focus {
      outline: none;
    }
  }
</style>
