import { DimText, Dimension, Quantity } from "dimtext";
import { Drawing } from "drawing";

const dt = new DimText();
const sqrt2 = Math.sqrt(2);

const w = dt.parse("2").value;
const d = dt.parse("3").value;
const r = dt.parse("1/2").value;

export function cutout(width = w, depth = d, radius = r) {
  const dn = depth.toNumber("inches");
  const wn = width.toNumber("inches");
  const rn = radius.toNumber("inches");

  const bulge = 1 - sqrt2;

  const a = { x: -wn / 2, y: 0 };
  const b = { x: -wn / 2, y: dn - rn };
  const c = { x: -wn / 2 + rn, y: dn, bulge };
  const d = { x: wn / 2 - rn, y: dn };
  const e = { x: wn / 2, y: dn - rn, bulge };
  const f = { x: wn / 2, y: 0 };

  const dd = { x: -wn / 2, y: dn };
  const cd = { x: wn / 2 - rn, y: dn - rn };

  return {
    path: [a, b, c, d, e, f],
    voids: [],
    dims: () => {
      return [
        {
          id: "width",
          dim: new Drawing().aligned_dim(a, f, { tier: -2 }),
        },
        {
          id: "depth",
          dim: new Drawing().aligned_dim(a, dd, { tier: 2 }),
        },
        {
          id: "radius",
          dim: new Drawing().radius_dim(cd, rn),
        },
      ];
    },
  };
}

export const cutoutParams = [
  {
    label: "Width",
    id: "width",
    default: w,
    update: (params, val) => {
      const w = val.toNumber("inches");
      const r = params[2].toNumber("inches");

      if (r > w / 2) {
        params[2] = new Dimension(new Quantity(w / 2, "inches"));
      }

      params[0] = val;
    },
  },
  {
    label: "Depth",
    id: "depth",
    default: d,
    update: (params, val) => {
      const w = val.toNumber("inches");
      const r = params[2].toNumber("inches");

      if (r > w) {
        params[2] = new Dimension(new Quantity(w, "inches"));
      }

      params[1] = val;
    },
  },
  {
    label: "Radius",
    id: "radius",
    default: r,
    update: (params, val) => {
      let r = val.toNumber("inches");
      const w = params[0].toNumber("inches");
      const d = params[1].toNumber("inches");

      if (r > w / 2 || r > d) {
        r = Math.min(w / 2, d);
        params[2] = new Dimension(new Quantity(r, "inches"));
      } else {
        params[2] = val;
      }
    },
  },
];
