import { add, subtract, angle, scale, length } from "vector";

const determinant = (m) =>
  m.length == 1
    ? m[0][0]
    : m.length === 2
    ? m[0][0] * m[1][1] - m[0][1] * m[1][0]
    : m[0].reduce(
        (r, e, i) =>
          r +
          (-1) ** (i + 2) *
            e *
            determinant(m.slice(1).map((c) => c.filter((_, j) => i != j))),
        0
      );

const sliceMatrix = (m, s) => m.map((row) => s.map((i) => row[i]));

export function sagittaArc(a, b, bulge) {
  const l = subtract(b, a);

  const chord = length(l);
  if (chord === 0) {
    return { radius: null, center: null };
  }

  const sagitta = bulge * (chord / 2);

  const n = {
    x: ((b.y - a.y) * sagitta) / chord,
    y: ((a.x - b.x) * sagitta) / chord,
  };

  const ptc = add(n, scale(add(a, b), 0.5));

  const matrix = [
    [a.x ** 2 + a.y ** 2, a.x, a.y, 1],
    [b.x ** 2 + b.y ** 2, b.x, b.y, 1],
    [ptc.x ** 2 + ptc.y ** 2, ptc.x, ptc.y, 1],
  ];

  const m11 = determinant(sliceMatrix(matrix, [1, 2, 3]));
  const m12 = determinant(sliceMatrix(matrix, [0, 2, 3]));
  const m13 = determinant(sliceMatrix(matrix, [0, 1, 3]));
  const m14 = determinant(sliceMatrix(matrix, [0, 1, 2]));

  if (m11 === 0) {
    return { radius: null, center: null };
  }

  const c = {
    x: 0.5 * (m12 / m11),
    y: -0.5 * (m13 / m11),
  };
  const r = Math.sqrt(c.x ** 2 + c.y ** 2 + m14 / m11);

  const sa = angle(subtract(a, c));
  const ea = angle(subtract(b, c));

  const ccw = bulge > 0;

  return { c, r, sa, ea, ccw };
}
