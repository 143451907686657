import log from "loglevel";

const getBaseLogger = log.getLogger;

/** @typedef {import('loglevel').Logger} Logger */

/**
 *
 * @param {string} name
 * @param {Logger} logger
 */
function registerLogger(name, logger) {
  if (!globalThis.logger) {
    // @ts-ignore
    globalThis.logger = {};
  }
  globalThis.logger[name] = logger;
}

/**
 *
 * @param {string | symbol} name
 * @param {import("loglevel").LogLevelDesc} defaultLevel
 * @returns
 */
export function getLogger(name, defaultLevel = "SILENT", register = false) {
  const log = getBaseLogger(name);
  log.setDefaultLevel(defaultLevel);

  if (register) {
    const registeredName = typeof name === "symbol" ? name.description : name;
    registerLogger(registeredName, log);
  }
  return log;
}

function callSig(fn, args) {
    return [fn.name, args.slice(0, fn.length)];
}

/**
 *
 * @param {Logger | typeof console} logger Logger to use when logging invocation
 * @param {string} level Loglevel to use
 * @param {Function} [fn] Function to wrap
 * @returns
 */
export function fnLogger(logger = console, level = "log", fn) {
    // TODO: short circuit completely if level not currently enabled?
  const wrap =
    (f) =>
    (...args) => {
        let result;
        try {
            result = f(...args);
        } catch (e) {
            logger.error("[funcall error] %s %o", ...callSig(f, args));
            throw e;
        }
      logger[level]("[funcall] %s %o", ...callSig(f, args));
      return result;
    };

  if (typeof fn === "function") {
    return wrap(fn);
  } else if (typeof fn === "undefined") {
    return (g) => wrap(g);
  }
}
