import { Drawing } from "drawing";
import { dwgShape } from "./drawing-symbol.js";

/**
 *
 * @param {object} item
 * @returns {Drawing}
 */
function liteDrawing(item, { typeColor = null } = {}) {
  const isShape = !!(item.width && item.height);
  let fill;

  if (item.is_collection) {
    fill = "#fff";
  } else if (item.is_imported) {
    fill = "#F7F7B7";
  } else if (typeColor) {
    fill = typeColor;
  } else {
    fill = "#B7D7F7";
  }
  const style = isShape
    ? { stroke: "#333", fill }
    : { stroke: "#333", fill: "#ddd" };

  const swf = item.cache.shapeWithFeatures;

  let dwg;
  if (item.drawing) {
    const s = dwgShape();
    dwg = new Drawing().polyface([s[0]]).polyline(s[1]).polyline(s[2]).style({
      stroke: "#888",
      fill: "transparent",
    });
  } else {
    dwg = new Drawing().polyface(swf.shape).style(style);
  }

  if (item.is_collection) {
    const { xmin, ymin, xmax, ymax } = item.cache.bbox;
    const x = new Drawing()
      .polyline([
        { x: xmin, y: ymin },
        { x: xmax, y: ymax },
      ])
      .polyline([
        { x: xmin, y: ymax },
        { x: xmax, y: ymin },
      ])
      .style({ stroke: "#bbb" })
      .mask(swf.shape);

    return new Drawing().add(dwg, x);
  } else {
    return dwg;
  }
}

export default liteDrawing;
