import { dist } from "vector";
import transformPt from "../utils/transform-pt.js";

class Circle {
  constructor(center, radius) {
    this.center = center;
    this.radius = radius;
    this.type = "circle";
  }

  render(options) {
    const ctx = options.ctx;
    const annoScale = options.annoScale;

    ctx.circle(this.center.x, this.center.y, this.radius);
    ctx.style("stroke", options.stroke);
    ctx.style("fill", options.fill);
    ctx.style("lineWidth", options.lineWidth * annoScale);
    ctx.fill();
    ctx.stroke();
  }

  get bbox() {
    return {
      xmin: this.center.x - this.radius,
      xmax: this.center.x + this.radius,
      ymin: this.center.y - this.radius,
      ymax: this.center.y + this.radius,
    };
  }

  transform(matrix) {
    const radiusPt = { x: this.center.x + this.radius, y: this.center.y };
    const c = transformPt(this.center, matrix);
    const r = transformPt(radiusPt, matrix);
    const newRadius = dist(c, r);
    return new Circle(c, newRadius);
  }
}

export default Circle;
