<script>
  import { createEventDispatcher, tick } from "svelte";

  export let defaultValue;
  export let value = undefined;
  export let newRow = false;
  export let column;

  const dispatch = createEventDispatcher();

  let input;

  export const focus = () => {
    input.focus();
  };

  async function handleFocus() {
    if (value === undefined && defaultValue !== undefined) {
      value = defaultValue;
    }
    await tick();
    dispatch("touch");
  }
</script>

<div class="px-2">
  <select
    bind:this={input}
    on:focus={handleFocus}
    class="bg-amber-50"
    class:bg-amber-50={newRow}
    on:input={(e) => dispatch("update", e.target.value)}
    bind:value>
    {#each column.options as option}
      <option value={option.value}>{option.label}</option>
    {/each}
  </select>
</div>
