<script context="module">
  /** @type {Map<string, Promise<Blob>>} */
  const _blob_cache = new Map();

  /*
    Not currently in use

    NOTE: Before enabling, ensure that error on rejected getBlob is properly handled.
    If enabled as-is, an error will throw at small breakpoints due to unhandled
    promise rejection.
  */
  let cacheEnabled = false;

  export const cache = {
    /**
     * @param {string} id
     * @returns {Promise<Blob>}
     */
    get(id) {
      return _blob_cache.get(id);
    },

    /**
     * @param {string} id
     */
    has(id) {
      return _blob_cache.has(id);
    },

    clear() {
      _blob_cache.clear();
    },
  };
</script>

<script>
  import { onMount } from "svelte";
  import { getZoomscale, setCanvasCtx } from "src/extensions/canvas-viewport";

  export let drawing;
  export let padding = 100;
  export let paddingTop = padding;
  export let paddingRight = padding;
  export let paddingBottom = padding;
  export let paddingLeft = padding;
  export let autofit = true;
  export let key = null;
  export let cacheKey = null;

  const dpi = window.devicePixelRatio;

  /** @type {Element} */
  let container;

  /** @type {HTMLCanvasElement}*/
  let canvas;

  let cw;
  let ch;
  let ctx;
  let bbox;
  let prevkey = key;

  $: extents = drawing.bbox;
  $: {
    cw;
    ch;
    if (ctx && drawing && bbox) {
      if (autofit || shouldReFit(key)) fit();
      render();
      cacheBlob();
    }
  }

  function shouldReFit(key) {
    const v = key !== prevkey;
    prevkey = key;
    return v;
  }

  function fit() {
    const cWidth = Math.ceil(cw * dpi);
    const cHeight = Math.ceil(ch * dpi);
    bbox = getZoomscale(
      cWidth,
      cHeight,
      extents,
      paddingTop * dpi,
      paddingRight * dpi,
      paddingBottom * dpi,
      paddingLeft * dpi
    );
  }

  function render() {
    canvas.style.width = `${cw}px`;
    canvas.style.height = `${ch}px`;
    canvas.width = Math.ceil(cw * dpi);
    canvas.height = Math.ceil(ch * dpi);
    setCanvasCtx(ctx, bbox.zoomscale, bbox.x, bbox.y);

    drawing.render({
      ctx,
      annoScale: dpi / bbox.zoomscale,
    });
  }

  /**
   * @param {string} type
   * @param {number=} quality
   * @returns {Promise<Blob>}
   */
  async function getBlob(type = "image/png", quality) {
    return new Promise((resolve, reject) => {
      canvas.toBlob(
        (blob) => {
          if (blob) {
            resolve(blob);
          } else {
            reject(null); // Right thing to do?
          }
        },
        type,
        quality
      );
    });
  }

  function cacheBlob() {
    if (cacheEnabled && cacheKey) {
      _blob_cache.set(cacheKey, getBlob());
    }
  }

  onMount(() => {
    canvas.width = cw;
    canvas.height = ch;
    ctx = canvas.getContext("2d");
    fit();
  });
</script>

<div
  class="w-full h-full overflow-hidden absolute"
  bind:this={container}
  bind:offsetWidth={cw}
  bind:offsetHeight={ch}>
  <canvas bind:this={canvas} />
</div>
