<script>
  import { createEventDispatcher } from "svelte";
  import { Datagrid } from "datagrid";

  export let data;

  const dispatch = createEventDispatcher();

  let pivot = false;
  let selected = 1;

  function togglePivot() {
    selected = 1;
    pivot = !pivot;
  }

  function next() {
    const d = pivot ? data[0].map((c, i) => data.map((r) => r[i])) : [...data];

    const ordered = d.slice(selected - 1);

    // Ensure that header row consists of strings
    ordered[0] = ordered[0].map((h) => h?.toString() || "");
    dispatch("next", { ordered });
  }

  function prev() {
    dispatch("prev");
  }

  // $: console.log('select_header_row', data);
</script>

<div class="space-y-4 flex flex-col overflow-hidden">
  <div class="space-y-4">
    <p>Choose a row to define column names.</p>
    <button class="btn" on:click={togglePivot}>Switch Rows/Columns</button>
  </div>
  <Datagrid
    {data}
    {pivot}
    colHeader={false}
    highlightedRows={[selected]}
    bind:selectedRow={selected}
    editable={false}
    rowSelect />
  <div class="flex justify-end items-center space-x-2">
    <button class="btn w-32" on:click={prev}>Previous</button>
    <button class="btn btn-primary w-32" on:click={next}>Next</button>
  </div>
</div>
