import { scale, angle } from "vector";
import { edgeOffsetPoint } from "./edge-offset-center.js";
import { translate, rotate } from "./transform-points.js";
import alignmentPt from "./alignment-pt.js";

export function edgeRectanglePoints(edge, feature) {
  const { center, evec, ovec } = edgeOffsetPoint(edge, feature.reference);
  const w = feature.width.toNumber("inches");
  const h = feature.height.toNumber("inches");
  const orientation = feature.orientation;
  const alignment = feature.alignment;

  let pts = [
    { x: 0, y: 0 },
    { x: 0, y: h },
    { x: w, y: h },
    { x: w, y: 0 },
  ];

  if (alignment === "center") {
    if (orientation === "edge-aligned") {
      pts = translate(
        rotate(translate(pts, -w / 2, -h / 2), angle(evec)),
        w / 2,
        h / 2,
      );
    }
    pts = translate(pts, center.x - w / 2, center.y - h / 2);
  } else {
    if (orientation === "edge-aligned") {
      pts = rotate(pts, angle(evec));
      if (feature.reference.start === "end") {
        const ov = scale(ovec, h);
        pts = translate(pts, ov.x, ov.y);
      }
      pts = translate(pts, center.x, center.y);
    } else {
      const t = alignmentPt(evec, feature.reference, w, h);
      pts = translate(pts, center.x + t.x, center.y + t.y);
    }
  }

  return pts;
}
