import { DimText, LinearDisplayFormat } from "dimtext";

import dimSettings from "@local/extensions/utilities/dim-settings.js";

function getTotalThickness(type, settings) {
  if (!type.data) return null;
  if (type.data === "Mixed") return "Mixed";
  if (!type.data?.layers) return null;
  if (type.data.layers.some((l) => !l?.material?.data?.thickness)) {
    return null;
  }

  if (!type.data.layers.length) return null;

  const val = type.data.layers.reduce((t, layer) => {
    return t + layer.material.data.thickness.toNumber("inches");
  }, 0);

  const ds = dimSettings(settings);
  const dt = new DimText({ defaultUnit: "inches" });
  const dtresult = dt.parse(val.toString());
  if (!dtresult.ok) return null;

  const dtval = dtresult.value;
  const formatted = dtval.format(
    LinearDisplayFormat[ds.dimFormat],
    ds.dimPrecision,
    { displayUnit: ds.displayUnit },
  );

  if (!formatted) return null;

  const unit = ds.displayUnit === "inches" ? '"' : "mm";
  return `${formatted}${unit}`;
}

export default getTotalThickness;
