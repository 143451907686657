// What is the length of a line segment that crosses through
// the center of a rectangle of width w and height h, at angle vector v?
export default function rectSpan(v, w, h) {
  if (!w || !h) return 0;
  if (!v.x) return h;
  if (!v.y) return w;

  const s = Math.abs(v.y / v.x);
  const boxS = h / w;

  if (s > boxS) {
    return Math.sqrt(h ** 2 + (h / s) ** 2);
  } else {
    return Math.sqrt(w ** 2 + (w * s) ** 2);
  }
}
