import { DimText, LinearDisplayFormat } from "dimtext";
import transformPt from "../utils/transform-pt.js";
import rectSpan from "../utils/rect-span.js";
import {
  length as magnitude,
  subtract,
  normalize,
  reverse,
  rotate,
  scale,
  add,
  distSq,
} from "vector";

const dt = new DimText();
class AlignedDim {
  constructor(
    ps,
    pe,
    {
      tier = 1,
      callback,
      override = null,
      trimStartWitness = false,
      trimEndWitness = false,
    } = {},
  ) {
    this.ps = ps;
    this.pe = pe;
    this.tier = tier;
    this.type = "aligned_dim";
    this.callback = callback;
    this.override = override;
    this.trimStartWitness = trimStartWitness;
    this.trimEndWitness = trimEndWitness;
  }

  get value() {
    return magnitude(subtract(this.pe, this.ps));
  }

  render(options) {
    const ctx = options.ctx;
    const annoScale = options.annoScale;
    const ex = options.annoExtension * annoScale;
    const hl = options.annoHashLength * annoScale;
    const fontSize = options.fontSize * annoScale;
    const conversion = options.dimConversion || 1;
    ctx.style("fontSize", 12);
    ctx.style("font", "Menlo");
    ctx.style("textAlign", "center");
    ctx.style("annoScale", annoScale);

    const length = magnitude(subtract(this.pe, this.ps));
    const dimvec =
      length !== 0 ? normalize(subtract(this.pe, this.ps)) : { x: 1, y: 0 };

    const ovec =
      this.tier < 0
        ? reverse(rotate(dimvec, Math.PI / 2))
        : rotate(dimvec, Math.PI / 2);

    const offset = options.annoOffset * annoScale * Math.abs(this.tier);

    const ho1 = scale(ovec, ex);
    const to1 = scale(ovec, offset - hl);
    const co = scale(ovec, offset);
    const ho2 = scale(ovec, offset + hl);
    const exoffset = scale(dimvec, ex);

    const a = this.trimStartWitness ? add(this.ps, to1) : add(this.ps, ho1);
    const b = add(this.ps, ho2);
    const c = this.trimEndWitness ? add(this.pe, to1) : add(this.pe, ho1);
    const d = add(this.pe, ho2);
    const e = add(subtract(this.ps, exoffset), co);
    const f = add(this.pe, exoffset, co);
    const g = add(this.ps, co);
    const h = add(this.pe, co);

    const ldim = dt.parse((length * conversion).toString());
    let ltext;
    if (this.override) {
      ltext = this.override;
    } else if (ldim.ok) {
      ltext = ldim.value.format(
        LinearDisplayFormat[options.annoFormat],
        options.annoPrecision,
      );
    } else {
      ltext = " ";
    }

    const mt = ctx.measureText(ltext.toString());
    const tw = mt.width * annoScale;
    const tha =
      ((mt.actualBoundingBoxAscent - mt.actualBoundingBoxDescent) / 2) *
      annoScale;
    const maskW = tw + fontSize * 0.5;

    // If overall text width is less than the distance between points, then
    // position the dimension on the line. Otherwise position the dimension
    // above the line.
    const textMask = rectSpan(dimvec, maskW, fontSize * 2);
    const altMask = rectSpan(dimvec, fontSize * 2, maskW);
    const textOnLine = textMask * textMask < distSq(this.ps, this.pe);
    const to = scale(ovec, altMask / 2);
    const cp = textOnLine
      ? add(this.ps, scale(dimvec, length / 2), co)
      : add(this.ps, scale(dimvec, length / 2), co, to);

    const e1 = add(this.ps, scale(dimvec, length / 2 - textMask / 2), co);
    const e2 = add(this.ps, scale(dimvec, length / 2 + textMask / 2), co);

    // Draw line
    ctx.beginPath();
    ctx.moveTo(a.x, a.y);
    ctx.lineTo(b.x, b.y);
    ctx.moveTo(c.x, c.y);
    ctx.lineTo(d.x, d.y);
    ctx.moveTo(e.x, e.y);

    if (!textOnLine) {
      ctx.lineTo(f.x, f.y);
    } else {
      ctx.lineTo(e1.x, e1.y);
      ctx.moveTo(e2.x, e2.y);
      ctx.lineTo(f.x, f.y);
    }

    ctx.style("stroke", options.stroke);
    ctx.style("lineWidth", options.lineWidth * annoScale);
    ctx.stroke();

    // Draw dots at hash intersection
    ctx.style("fill", options.stroke);
    const r = 2 * annoScale;
    ctx.circle(g.x, g.y, r);
    ctx.fill();
    ctx.circle(h.x, h.y, r);
    ctx.fill();

    // Draw text
    ctx.style("fill", options.textColor);
    ctx.text(ltext, cp.x, cp.y - tha);
  }

  renderHitbox(options) {
    const ctx = options.ctx;
    const annoScale = options.annoScale;
    const fontSize = options.fontSize * annoScale;
    ctx.style("fill", options.textColor);
    ctx.style("fontSize", 12);
    ctx.style("font", "Menlo");
    ctx.style("textAlign", "center");
    ctx.style("annoScale", annoScale);

    const length = magnitude(subtract(this.pe, this.ps));
    const dimvec =
      length !== 0 ? normalize(subtract(this.pe, this.ps)) : { x: 1, y: 0 };

    const ovec =
      this.tier < 0
        ? reverse(rotate(dimvec, Math.PI / 2))
        : rotate(dimvec, Math.PI / 2);

    const offset = options.annoOffset * annoScale * Math.abs(this.tier);

    const co = scale(ovec, offset);

    const ldim = dt.parse(length.toString());
    const ltext = ldim.ok
      ? ldim.value.format(
          LinearDisplayFormat[options.annoFormat],
          options.annoPrecision,
        )
      : " ";

    const mt = ctx.measureText(ltext.toString());
    const tw = mt.width * annoScale;
    const tha =
      ((mt.actualBoundingBoxAscent - mt.actualBoundingBoxDescent) / 2) *
      annoScale;
    const maskW = tw + fontSize * 0.5;

    // If overall text width is less than the distance between points, then
    // position the dimension on the line. Otherwise position the dimension
    // above the line.
    const textMask = rectSpan(dimvec, maskW, fontSize * 2);
    const altMask = rectSpan(dimvec, fontSize * 2, maskW);
    const textOnLine = textMask * textMask < distSq(this.ps, this.pe);
    const to = scale(ovec, altMask / 2);
    const cp = textOnLine
      ? add(this.ps, scale(dimvec, length / 2), co)
      : add(this.ps, scale(dimvec, length / 2), co, to);

    const w = maskW / 2;
    const h = tha / 2;
    const x0 = cp.x - w;
    const x1 = cp.x + w;
    const y0 = cp.y - h;
    const y1 = cp.y + h;

    ctx.beginPath();
    ctx.moveTo(x0, y0);
    ctx.lineTo(x1, y0);
    ctx.lineTo(x1, y1);
    ctx.lineTo(x0, y1);
    ctx.closePath();
    ctx.style("fill", options.fill);
    ctx.style("stroke", options.stroke);
    ctx.style("lineWidth", options.lineWidth * annoScale);
    ctx.fill();
    ctx.stroke();

    return { pt: cp };
  }

  get bbox() {
    return {
      xmin: Math.min(this.ps.x, this.pe.x),
      xmax: Math.max(this.ps.x, this.pe.x),
      ymin: Math.min(this.ps.y, this.pe.y),
      ymax: Math.max(this.ps.y, this.pe.y),
    };
  }

  transform(matrix) {
    const ps = transformPt(this.ps, matrix);
    const pe = transformPt(this.pe, matrix);
    return new AlignedDim(ps, pe, {
      tier: this.tier,
      callback: this.callback,
      override: this.override,
      trimStartWitness: this.trimStartWitness,
      trimEndWitness: this.trimEndWitness,
    });
  }
}

export default AlignedDim;
