import get from "lodash/get.js";
import { Drawing } from "drawing";
import markColor from "./mark-color";
import { add } from "vector";

function liteDrawing(
  item,
  {
    // offsetX = null,
    offsetY = null,
    labelBy = "mark",
    showQty = true,
    showDims = true,
    showMark = true,
    shiftMark = false,
    showOffsets = true,
    isCollection = false,
    typeColor = null,
    imageProps = null,
  } = {},
) {
  if (!item?.cache) return null;
  const { xmin, ymin, width, height } = item.cache.bbox;
  const bb = 60;
  const edges = item.cache.edges[0];
  let offsets;

  let fill;
  if (isCollection) {
    fill = "#fff";
  } else if (item.is_imported) {
    fill = "#F7F7B7";
  } else if (typeColor) {
    fill = typeColor;
  } else {
    fill = "#B7D7F7";
  }
  const solidStyle = {
    stroke: "#333",
    fill,
  };
  const partiallyDefinedStyle = {
    stroke: "#333",
    fill: "#ddd",
    lineDash: [3, 3],
  };
  const isShape = !!(item.width && item.height);

  let shape;
  let annotations = [];
  const swf = item.cache.shapeWithFeatures;
  const mask = swf.shape.map((l) =>
    l.map((p) => add(p, { x: -xmin, y: -ymin })),
  );
  if (!item.drawing && item.shape.type !== "none") {
    shape = new Drawing()
      .polyface(swf.shape)
      .translate(-xmin, -ymin)
      .style(isShape ? solidStyle : partiallyDefinedStyle);

    if (isCollection) {
      annotations = [
        new Drawing()
          .polyline([
            { x: 0, y: 0 },
            { x: width, y: height },
          ])
          .polyline([
            { x: 0, y: height },
            { x: width, y: 0 },
          ])
          .style({ stroke: "#bbb" })
          .mask(mask),
        ...swf.annotations,
      ];
    } else {
      annotations = swf.annotations;
    }

    if (item.cache.offsets && showOffsets && isShape) {
      offsets = new Drawing();

      if (item.cache.width_offset_in === 0) {
        const a = new Drawing().polyline([
          { x: 0, y: 0 },
          { x: 0, y: item.cache.height_prime_in },
        ]);

        const b = new Drawing().polyline([
          { x: item.cache.width_prime_in, y: 0 },
          { x: item.cache.width_prime_in, y: item.cache.height_prime_in },
        ]);

        offsets = offsets.add(a, b);
      } else if (item.cache.width_offset_in > 0) {
        const a = new Drawing()
          .polyline([
            { x: 0, y: 0 },
            { x: 0, y: item.cache.height_prime_in },
          ])
          .shift({ x: 3, y: 0 });

        const b = new Drawing()
          .polyline([
            { x: item.cache.width_prime_in, y: 0 },
            { x: item.cache.width_prime_in, y: item.cache.height_prime_in },
          ])
          .shift({ x: -3, y: 0 });

        offsets = offsets.add(a, b);
      } else if (item.cache.width_offset_in < 0) {
        const a = new Drawing()
          .polyline([
            { x: 0, y: 0 },
            { x: 0, y: item.cache.height_prime_in },
          ])
          .shift({ x: -3, y: 0 });

        const b = new Drawing()
          .polyline([
            { x: item.cache.width_prime_in, y: 0 },
            { x: item.cache.width_prime_in, y: item.cache.height_prime_in },
          ])
          .shift({ x: 3, y: 0 });

        offsets = offsets.add(a, b);
      } else if (item.shape.type === "free") {
        offsets = offsets.add(
          new Drawing().rectangle(
            0,
            0,
            item.cache.width_prime_in,
            item.cache.height_prime_in,
          ),
        );
      }

      if (item.cache.height_offset_in === 0) {
        const a = new Drawing().polyline([
          { x: 0, y: 0 },
          { x: item.cache.width_prime_in, y: 0 },
        ]);

        const b = new Drawing().polyline([
          { x: 0, y: item.cache.height_prime_in },
          { x: item.cache.width_prime_in, y: item.cache.height_prime_in },
        ]);

        offsets = offsets.add(a, b);
      } else if (item.cache.height_offset_in > 0) {
        const a = new Drawing()
          .polyline([
            { x: 0, y: 0 },
            { x: item.cache.width_prime_in, y: 0 },
          ])
          .shift({ x: 0, y: 3 });

        const b = new Drawing()
          .polyline([
            { x: 0, y: item.cache.height_prime_in },
            { x: item.cache.width_prime_in, y: item.cache.height_prime_in },
          ])
          .shift({ x: 0, y: -3 });

        offsets = offsets.add(a, b);
      } else if (item.cache.height_offset_in < 0) {
        const a = new Drawing()
          .polyline([
            { x: 0, y: 0 },
            { x: item.cache.width_prime_in, y: 0 },
          ])
          .shift({ x: 0, y: -3 });

        const b = new Drawing()
          .polyline([
            { x: 0, y: item.cache.height_prime_in },
            { x: item.cache.width_prime_in, y: item.cache.height_prime_in },
          ])
          .shift({ x: 0, y: 3 });

        offsets = offsets.add(a, b);
      }

      offsets = offsets
        .style({
          stroke: "#333333",
          fill: "transparent",
          lineDash: [5, 3],
        })
        .name("offsets");
    }
  }

  // if (offsetX && item.quantity !== 1) {
  //   shape = shape.translate(offsetX / 2, 0);
  // }

  let mark;
  if (showMark) {
    let mX;
    if (item.drawing || item.shape.type === "none") {
      if (item.drawing?.properties) {
        const ar =
          item.drawing.properties.width / item.drawing.properties.height;
        const wd = ar * bb;
        mX = wd / 2;
      } else if (item.shape.type === "none" && imageProps) {
        const ar = imageProps.width / imageProps.height;
        const wd = ar * bb;
        mX = wd / 2;
      } else {
        mX = bb / 2;
      }
    } else {
      mX = item.cache.width / 2;
    }

    const markText = get(item, labelBy);
    if (markText) {
      mark = new Drawing()
        .mark({ x: mX, y: 0 }, get(item, labelBy), {
          shape: isCollection ? "rectangle" : "hexagon",
          offset: {
            x: 0,
            y: showDims || shiftMark ? -30 : -18,
          },
        })
        .style(markColor(item));
    }
  }

  // let qty;
  // if (showQty && !isCollection) {
  //   const qtyX = item.drawing ? bb / 2 : item.cache.width / 2;
  //   if (item.quantity !== 1) {
  //     qty = new Drawing()
  //       .text(
  //         `(${item.quantity})`,
  //         { x: offsetX ? offsetX / 2 : qtyX, y: 0 },
  //         {
  //           offset: { x: offsetX ? -2 : 0, y: offsetY || 15 },
  //           alignment: offsetX ? "right" : "center",
  //         },
  //       )
  //       .style({ textColor: "black" });
  //   }
  // }

  let thickLines = null;
  const edgeTreatments = get(item, "data.fabrications.edge_treatment") || [];
  if (isShape) {
    const tl = [];
    edgeTreatments.forEach((t, index) => {
      const edge = edges[index];
      if (t && edge) {
        let line = new Drawing()
          .polyline([edge.start, edge.end])
          .translate(-xmin, -ymin)
          .style({ stroke: "black", lineWidth: 3 });

        // if (offsetX && item.quantity !== 1) {
        //   line = line.translate(item.cache.width, 0);
        // }

        tl.push(line);
      }
      if (tl.length > 0 && !item.drawing) {
        thickLines = new Drawing().add(...tl).mask(mask);
      }
    });
  }

  return new Drawing().add(
    shape,
    offsets,
    ...annotations,
    thickLines,
    mark,
    // qty,
  );
}

export default liteDrawing;
