<script>
  import { onMount } from "svelte";
  import OptionEditor from "./OptionEditor.svelte";
  import TextEditor from "./TextEditor.svelte";
  import BooleanEditor from "./BooleanEditor.svelte";
  import MultistateEditor from "./MultistateEditor.svelte";

  export let value;
  export let rowData;
  export let row;
  export let column;
  export let type;
  export let disabled;
  export let props;
  export let rowType;

  let editor;

  const editors = {
    text: TextEditor,
    select: OptionEditor,
    boolean: BooleanEditor,
    multistate: MultistateEditor,
  };

  onMount(async () => {
    if (editor && editor.focus) {
      editor.focus();
    }
  });
</script>

<div class="active-input-container">
  {#if props.prefix}
    <div class="pl-1">{props.prefix}</div>
  {/if}
  <div class="inner-container">
    <svelte:component
      this={editors[type] || TextEditor}
      bind:this={editor}
      bind:value
      {rowData}
      on:updateValue
      {row}
      {column}
      {disabled}
      {...props}
      {rowType} />
  </div>
</div>

<style lang="scss">
  div.active-input-container {
    outline: none;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    position: absolute;
    top: 0;
    z-index: 20;
    @apply ring-2 ring-blue-500;
  }

  div.inner-container {
    outline: none;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    position: relative;
    top: 0;
  }
</style>
