import range from "lodash/range.js";

export function hashify(arr) {
  return arr.reduce((obj, i) => {
    obj[i] = true;
    return obj;
  }, {});
}

export function objectify(arr, id = "id") {
  return arr.reduce((obj, a) => {
    obj[a[id]] = a;
    return obj;
  }, {});
}

export function bucketArray(arr, id = "id") {
  return arr.reduce((obj, a) => {
    if (obj[a[id]]) {
      obj[a[id]].push(a);
    } else {
      obj[a[id]] = [a];
    }
    return obj;
  }, {});
}

export function bucketArrayMultiple(arr, ...ids) {
  return arr.reduce(
    (obj, a) => {
      const present = ids.find((id) => a[id]);
      if (present && obj[a[present]]) {
        obj[a[present]].push(a);
      } else if (present) {
        obj[a[present]] = [a];
      } else {
        obj.none.push(a);
      }

      return obj;
    },
    { none: [] }
  );
}

export function hashifyRanges(ranges) {
  const result = {};

  ranges.forEach((r) => {
    const min = Math.min(...r);
    const max = Math.max(...r);

    range(min, max + 1).forEach((index) => {
      result[index] = true;
    });
  });

  return result;
}
