import { DimText } from "dimtext";

function parseDim(defaultUnit = "inches") {
  const dt = new DimText({ defaultUnit });

  return (v) => {
    if (v === null || v === "") return null;
    const result = dt.parse(v.toString());
    if (!result.ok) throw result.err;
    return result.value;
  };
}

export default parseDim;
