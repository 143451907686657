import { writable } from "svelte/store";
import isEqual from "lodash/isEqual";
import { contiguousRanges } from "overline";
import rangeIndices from "../lib/range-indices";

function createFocused() {
  const { subscribe, set, update } = writable({
    initialCol: 0,
    rowCount: 0,
    colCount: 0,
    row: null,
    col: null,
    selected: [],
    selectedRows: [],
    selectedCols: [],
  });

  return {
    subscribe,
    set,
    update,
    focus(row, col) {
      update((f) => {
        f.row = row;
        f.col = col;
        f.selected = [[row, col, row, col]];
        f.selectedRows = [];
        f.selectedCols = [];
        return f;
      });
    },
    focusTd(td) {
      if (!td) return;

      const row = parseInt(td.getAttribute("data-row"));
      const col = parseInt(td.getAttribute("data-column"));

      this.focus(row, col);
    },
    shiftFocus(row, col) {
      update((f) => {
        if (f.row === null || f.col === null) {
          f.row = row;
          f.col = col;
          f.selected = [[row, col, row, col]];
          f.selectedRows = [];
          f.selectedCols = [];
          return f;
        }

        const minR = Math.min(f.row, row);
        const minC = Math.min(f.col, col);
        const maxR = Math.max(f.row, row);
        const maxC = Math.max(f.col, col);
        f.selected[f.selected.length - 1] = [minR, minC, maxR, maxC];
        f.selectedRows = [];
        f.selectedCols = [];

        return f;
      });
    },
    shiftFocusTd(td) {
      if (!td) return;

      const row = parseInt(td.getAttribute("data-row"));
      const col = parseInt(td.getAttribute("data-column"));

      this.shiftFocus(row, col);
    },
    shiftSelect(row, col) {
      update((f) => {
        if (f.row === null || f.col === null) {
          f.row = row;
          f.col = col;
          f.selected = [[row, col, row, col]];
          f.selectedRows = [];
          f.selectedCols = [];
          return f;
        }

        const last = f.selected[f.selected.length - 1];
        const [r1, c1, r2, c2] = last;

        const minR = Math.min(r1, r2, row);
        const minC = Math.min(c1, c2, col);
        const maxR = Math.max(r1, r2, row);
        const maxC = Math.max(c1, c2, col);
        f.row = minR;
        f.col = minC;
        f.selected[f.selected.length - 1] = [minR, minC, maxR, maxC];
        f.selectedRows = [];
        f.selectedCols = [];

        return f;
      });
    },
    metaFocus(row, col) {
      update((f) => {
        f.row = row;
        f.col = col;
        const newSelected = [row, col, row, col];

        if (f.selected.some((s) => isEqual(s, newSelected))) {
          const index = f.selected.findIndex((s) => isEqual(s, newSelected));
          f.selected.splice(index, 1);
        }

        f.selected.push(newSelected);
        f.selectedRows = [];
        f.selectedCols = [];

        return f;
      });
    },
    metaFocusTd(td) {
      if (!td) return;

      const row = parseInt(td.getAttribute("data-row"));
      const col = parseInt(td.getAttribute("data-column"));

      this.metaFocus(row, col);
    },
    blur: () => {
      update((f) => {
        f.row = null;
        f.col = null;
        f.selected = [];
        f.selectedRows = [];
        f.selectedCols = [];
        return f;
      });
    },
    moveFrom: (row, col, dir, shiftKey = false) => {
      let r = row;
      let c = col;

      if (dir === "up") {
        r -= 1;
      } else if (dir === "right") {
        c += 1;
      } else if (dir === "down") {
        r += 1;
      } else if (dir === "left") {
        c -= 1;
      }

      update((f) => {
        if (r >= 0 && r < f.rowCount && c >= 0 && c < f.colCount) {
          if (shiftKey && f.selected.length > 0) {
            const last = f.selected[f.selected.length - 1];
            const [r1, c1, r2, c2] = last;
            const minR = Math.min(r1, r2, r);
            const minC = Math.min(c1, c2, c);
            const maxR = Math.max(r1, r2, r);
            const maxC = Math.max(c1, c2, c);

            f.selected[f.selected.length - 1] = [minR, minC, maxR, maxC];
          } else {
            f.selected = [[r, c, r, c]];
          }

          f.row = r;
          f.col = c;
        }

        return f;
      });
    },
    enter: (shiftKey = false) => {
      update((f) => {
        if (f.selected.length > 0) {
          const last = f.selected[f.selected.length - 1];
          const [r1, c1, r2, c2] = last;

          if (r1 === r2 && c1 === c2) {
            if (shiftKey) {
              f.row = Math.max(f.row - 1, 0);
            } else {
              f.col = f.initialCol;
              f.row = Math.min(f.row + 1, f.rowCount - 1);
            }
            f.selected = [[f.row, f.col, f.row, f.col]];
          } else {
            const minR = Math.min(r1, r2);
            const minC = Math.min(c1, c2);
            const maxR = Math.max(r1, r2);
            const maxC = Math.max(c1, c2);

            if (shiftKey) {
              if (f.row <= minR) {
                f.row = maxR;
                if (f.col <= minC) {
                  f.col = maxC;
                }
              } else {
                f.row -= 1;
              }
            } else {
              if (f.row >= maxR) {
                f.row = minR;
                if (f.col >= maxC) {
                  f.col = minC;
                }
              } else {
                f.row += 1;
              }
            }
          }
        } else {
          if (shiftKey) {
            f.row = Math.max(f.row - 1, 0);
          } else {
            f.col = f.initialCol;
            f.row = Math.min(f.row + 1, f.rowCount - 1);
          }
        }
        return f;
      });
    },
    move: (dir, shiftKey = false) => {
      update((f) => {
        let r = f.row;
        let c = f.col;

        if (dir === "up") {
          r -= 1;
        } else if (dir === "right") {
          c += 1;
        } else if (dir === "down") {
          r += 1;
        } else if (dir === "left") {
          c -= 1;
        }

        if (r >= 0 && r < f.rowCount && c >= 0 && c < f.colCount) {
          if (shiftKey && f.selected.length > 0) {
            const last = f.selected[f.selected.length - 1];
            const [r1, c1, r2, c2] = last;
            const minR = Math.min(r1, r2, r);
            const minC = Math.min(c1, c2, c);
            const maxR = Math.max(r1, r2, r);
            const maxC = Math.max(c1, c2, c);

            f.selected[f.selected.length - 1] = [minR, minC, maxR, maxC];
          } else {
            f.selected = [[r, c, r, c]];
          }

          f.row = r;
          f.col = c;
        }

        return f;
      });
    },
    selectHeader(type, index) {
      update((f) => {
        f.selected = [];

        if (type === "row") {
          f.row = index;
          f.col = null;
          f.selectedRows = [[index, index]];
          f.selectedCols = [];
        } else if (type === "col") {
          f.row = null;
          f.col = index;
          f.selectedCols = [[index, index]];
          f.selectedRows = [];
        }

        return f;
      });
    },
    selectHeaders(type, ranges) {
      update((f) => {
        f.selected = [];

        if (ranges.length === 0) {
          f.row = null;
          f.col = null;
          f.selectedRows = [];
          f.selectedCols = [];
        } else if (type === "row") {
          const last = ranges[ranges.length - 1];
          f.row = last[1];
          f.col = null;
          f.selectedRows = ranges;
          f.selectedCols = [];
        } else {
          const last = ranges[ranges.length - 1];
          f.row = null;
          f.col = last[1];
          f.selectedRows = [];
          f.selectedCols = ranges;
        }

        return f;
      });
    },
    shiftSelectHeaders(type, index, mcIndex = index) {
      update((f) => {
        f.selected = [];

        if (type === "row") {
          if (f.row === null) {
            f.selectedRows = [[index, index]];
          } else {
            const last = f.selectedRows[f.selectedRows.length - 1];
            last[1] = index;
          }

          f.row = index;
          f.col = null;
          f.selectedCols = [];
        } else if (type === "col") {
          if (f.col === null) {
            f.selectedCols = [[index, mcIndex]];
          } else {
            const last = f.selectedCols[f.selectedCols.length - 1];
            if (last[0] < index) {
              last[1] = mcIndex;
            } else if (last[0] > mcIndex) {
              last[1] = index;
            } else {
              last[0] = index;
              last[1] = mcIndex;
            }
          }

          f.row = null;
          f.col = index;
          f.selectedRows = [];
        }

        return f;
      });
    },
    metaSelectHeaders(type, index, mcIndex = index) {
      update((f) => {
        f.selected = [];

        if (type === "row") {
          f.selectedRows.push([index, index]);
          f.row = index;
          f.col = null;
          f.selectedCols = [];
        } else if (type === "col") {
          f.selectedCols.push([index, mcIndex]);
          f.row = null;
          f.col = index;
          f.selectedRows = [];
        }

        return f;
      });
    },
    updateSize: (rowCount, colCount) => {
      update((f) => {
        f.colCount = colCount;
        f.rowCount = rowCount;
        return f;
      });
    },
    shiftSelectedCols: (shifts) => {
      update((f) => {
        if (f.selectedCols.length > 0) {
          const ri = rangeIndices(f.selectedCols);

          shifts.forEach(([a]) => {
            delete ri[a];
          });

          shifts.forEach(([_, b]) => {
            ri[b] = true;
          });

          const riArr = Object.keys(ri)
            .map((i) => parseInt(i))
            .sort((a, b) => a - b);

          const cr = contiguousRanges(riArr);

          f.selectedCols = cr;
        } else if (f.selected.length > 0) {
          let col = f.col;

          const curr = shifts.find(([a]) => f.col === a);
          if (curr) {
            col = curr[1];
          } else {
            shifts.forEach(([a, b]) => {
              if (a > col && b <= col) {
                col += 1;
              } else if (a < col && b >= col) {
                col -= 1;
              }
            });
          }

          f.col = col;
          f.selected = [[f.row, col, f.row, col]];
        }

        return f;
      });
    },
  };
}

export default createFocused;
