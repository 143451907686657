export default async function load(fsLib = null, streamLib = null) {
    const XLSX = await import("xlsx");

        if (fsLib) {
            XLSX.set_fs(fsLib);
        }

        if (streamLib) {
            XLSX.stream.set_readable(streamLib.Readable);
        }

        return XLSX;
}