import { DimText, LinearDisplayFormat } from "dimtext";
import { angleVec, add, scale, rotate, dist } from "vector";
import transformPt from "../utils/transform-pt.js";

const dt = new DimText();
class RadiusDim {
  constructor(center, radius, angle = Math.PI / 4, tier = 1, callback) {
    this.center = center;
    this.radius = radius;
    this.angle = angle;
    this.tier = tier;
    this.callback = callback;
    this.type = "radius_dim";
  }

  get value() {
    return this.radius;
  }

  render(options) {
    const ctx = options.ctx;
    const annoScale = options.annoScale;
    const ex = options.annoExtension * annoScale;
    const hl = options.annoHashLength * annoScale;
    const conversion = options.dimConversion || 1;
    ctx.style("fontSize", 12);
    ctx.style("font", "Menlo");
    ctx.style("textAlign", "left");
    ctx.style("annoScale", annoScale);

    const vec = angleVec(this.angle);
    const offset = options.annoOffset * annoScale * Math.abs(this.tier);
    const dir = vec.x > 0 ? 1 : -1;

    const a = add(this.center, scale(vec, this.radius));
    const b = add(a, scale(vec, offset));
    const c = add(b, { x: ex * dir, y: 0 });

    // arrowhead
    const a1 = add(a, scale(rotate(vec, Math.PI / 8), hl * 2));
    const a2 = add(a, scale(rotate(vec, -Math.PI / 8), hl * 2));

    const ddim = dt.parse((this.radius * conversion).toString());
    const dtext = ddim.ok
      ? "R " +
        ddim.value.format(
          LinearDisplayFormat[options.annoFormat],
          options.annoPrecision,
        )
      : " ";

    const mt = ctx.measureText(dtext.toString());
    const tw = mt.width * annoScale;
    const tha =
      ((mt.actualBoundingBoxAscent - mt.actualBoundingBoxDescent) / 2) *
      annoScale;

    // Draw line
    ctx.beginPath();
    ctx.moveTo(a1.x, a1.y);
    ctx.lineTo(a.x, a.y);
    ctx.lineTo(a2.x, a2.y);
    ctx.moveTo(a.x, a.y);
    ctx.lineTo(b.x, b.y);
    ctx.lineTo(c.x, c.y);

    ctx.style("stroke", options.stroke);
    ctx.style("lineWidth", options.lineWidth * annoScale);
    ctx.stroke();

    // Draw text
    ctx.style("fill", options.textColor);
    ctx.text(dtext, c.x + hl, c.y - tha);
  }

  renderHitbox(options) {
    const ctx = options.ctx;
    const annoScale = options.annoScale;
    const fontSize = options.fontSize * annoScale;
    ctx.style("fontSize", 12);
    ctx.style("font", "Menlo");
    ctx.style("textAlign", "center");
    ctx.style("annoScale", annoScale);

    const a = add(this.center, scale(vec, this.radius));
    const b = add(a, scale(vec, offset));
    const c = add(b, { x: ex * dir, y: 0 });

    const ddim = dt.parse(this.radius.toString());
    const dtext = ddim.ok
      ? "R " +
        ddim.value.format(
          LinearDisplayFormat[options.annoFormat],
          options.annoPrecision,
        )
      : " ";

    const mt = ctx.measureText(dtext.toString());
    const tw = mt.width * annoScale;
    const tha =
      ((mt.actualBoundingBoxAscent - mt.actualBoundingBoxDescent) / 2) *
      annoScale;
    const maskW = tw + fontSize * 0.5;

    const cp = add(c, { x: hl + tw / 2, y: -tha });

    const w = maskW / 2;
    const h = tha / 2;
    const x0 = cp.x - w;
    const x1 = cp.x + w;
    const y0 = cp.y - h;
    const y1 = cp.y + h;

    ctx.beginPath();
    ctx.moveTo(x0, y0);
    ctx.lineTo(x1, y0);
    ctx.lineTo(x1, y1);
    ctx.lineTo(x0, y1);
    ctx.closePath();
    ctx.style("fill", options.fill);
    ctx.style("stroke", options.stroke);
    ctx.style("lineWidth", options.lineWidth * annoScale);
    ctx.fill();
    ctx.stroke();

    return { pt: cp };
  }

  get bbox() {
    return {
      xmin: this.center.x - this.radius,
      xmax: this.center.x + this.radius,
      ymin: this.center.y - this.radius,
      ymax: this.center.y + this.radius,
    };
  }

  transform(matrix) {
    const radiusPt = { x: this.center.x + this.radius, y: this.center.y };
    const c = transformPt(this.center, matrix);
    const r = transformPt(radiusPt, matrix);
    const newRadius = dist(c, r);
    return new RadiusDim(c, newRadius, this.angle, this.tier, this.callback);
  }
}

export default RadiusDim;
