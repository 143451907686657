<script>
  import { createEventDispatcher, tick } from "svelte";
  import set from "lodash/set";
  import cloneDeep from "lodash/cloneDeep";
  import DropGrid from "../DropGrid.svelte";

  export let data;
  export let columns;
  export let rawData;
  export let schema;
  export let schemaMap;
  export let hiddenColumns;

  const dispatch = createEventDispatcher();

  let values = [];

  $: truncatedData = rawData.slice(0, 6);
  $: values = guessValues(columns, rawData);
  $: schemaByProp = Object.fromEntries(schema.map((s) => [s.prop, s]));
  $: fixed = Object.entries(schemaMap)
    .map(([prop, index]) => ({ index, prop, label: schemaByProp[prop].label }))
    .sort((a, b) => a.index - b.index);

  function getCols(data) {
    return data[0].map((c, i) => ({
      label: c || "(empty)",
      value: i,
      prop: c || crypto.randomUUID(),
    }));
  }

  function guessValues(columns, data) {
    const cols = getCols(data);
    const assigned = {};

    return columns.map((col, index) => {
      if (values[index] !== undefined && values[index] !== null) {
        return values[index];
      }

      const colIndex = cols.findIndex((c) => namesAreSimilar(c.label, col.key));
      if (colIndex === -1) return null;
      if (assigned[colIndex]) return null;
      assigned[colIndex] = true;
      return colIndex;
    });
  }

  function namesAreSimilar(a, b) {
    if (typeof a !== "string" || typeof b !== "string") return false;
    if (a.toLowerCase().trim() === b.toLowerCase().trim()) return true;
    if (
      a
        .toLowerCase()
        .split(/[\s_]+/)
        .join()
        .trim() ===
      b
        .toLowerCase()
        .split(/[\s_]+/)
        .join()
        .trim()
    )
      return true;
    return false;
  }

  function next() {
    const mapped_with_template_data = data.map((row, rindex) => {
      const r = cloneDeep(row);
      r.item_template_data = {};

      values.forEach((col, index) => {
        if (col === null || col === undefined) return;
        const rdrow = rawData[rindex + 1];
        const datum = columns[index];
        let val = rdrow[col];

        // We assume all imported values are strings
        // (True in case of CSV, not in case of Excel)
        if (typeof val === "number") val = val.toString();

        // Parse the value if a parser is present
        if (!val) {
          val = datum.default;
        } else if (datum.parser) {
          try {
            val = datum.parser(val);
          } catch (error) {
            val = datum.default;
          }
        }

        set(r, `item_template_data.${datum.template}.${datum.key}`, val);
      });

      return r;
    });

    dispatch("next", { mapped_with_template_data });
  }

  function prev() {
    dispatch("prev");
  }
</script>

<div class="space-y-4 flex flex-col overflow-hidden">
  <div class="w-full">
    <p class="mb-4">Drag the property to its corresponding column.</p>

    <DropGrid
      data={truncatedData}
      options={columns}
      bind:values
      {fixed}
      {columns}
      bind:hiddenColumns
      allowMultiple />

    <p class="text-gray-500">(Data shown here may be truncated)</p>
  </div>

  <div class="flex justify-end items-center space-x-2">
    <button class="btn w-32" on:click={prev}>Previous</button>
    <button class="btn btn-primary w-32" on:click={next} disabled={values.includes(null)}>Next</button>
  </div>
</div>
