/**
 * @param {number} cWidth Content width
 * @param {number} cHeight Content height
 * @param {{ xmax: number; xmin: number; ymax: number; ymin: number; }} bbox
 */
function getZoomscale(
  cWidth,
  cHeight,
  bbox,
  paddingTop = 100,
  paddingRight = paddingTop,
  paddingBottom = paddingTop,
  paddingLeft = paddingTop
) {
  const bwidth = bbox.xmax - bbox.xmin;
  const bheight = bbox.ymax - bbox.ymin;

  const bar = bwidth / bheight;
  const vw = cWidth - paddingRight - paddingLeft;
  const vh = cHeight - paddingTop - paddingBottom;
  const vpar = vw / vh;

  const paddingPct =
    bar > vpar
      ? (paddingLeft + paddingRight) / cWidth
      : (paddingTop + paddingBottom) / cHeight;

  const zoomscale =
    bar > vpar
      ? (cWidth / bwidth) * (1 - paddingPct)
      : (cHeight / bheight) * (1 - paddingPct);

  const width = cWidth / zoomscale;
  const height = cHeight / zoomscale;

  const scaledvw = vw / zoomscale;
  const scaledvh = vh / zoomscale;

  const scaledpl = paddingLeft / zoomscale;
  const scaledpt = paddingTop / zoomscale;

  const center = {
    x: bbox.xmin + bwidth / 2,
    y: bbox.ymin + bheight / 2,
  };

  const x = center.x - scaledvw / 2 - scaledpl;
  const y = center.y + scaledvh / 2 + scaledpt;

  return { zoomscale, x, y, width, height };
}

function setCanvasCtx(ctx, zoomscale, x, y) {
  // zoom
  ctx.transform(zoomscale, 0, 0, zoomscale, 0, 0);

  // translate
  ctx.transform(1, 0, 0, 1, -x, y);
}

export { getZoomscale, setCanvasCtx };
