import { Drawing } from "drawing";
import { slope } from "vector";
import { lt, gt } from "overline";

export default function rake(edge) {
  const s = slope(edge.start, edge.end);
  const w = 20;
  const h = 12;
  const o = 15;
  const lo = 30;
  let center;
  let offset;
  let a;
  let b;
  let c;

  if (gt(s, 0) && s < 0.04) {
    if (edge.end.x > edge.start.x) {
      // Bottom right
      a = { x: -w, y: -h };
      b = { x: w, y: -h };
      c = { x: w, y: 0 };
      center = { x: edge.end.x, y: edge.start.y };
      offset = { x: -lo, y: -o };
    } else {
      // Top left
      a = { x: w, y: h };
      b = { x: -w, y: h };
      c = { x: -w, y: 0 };
      center = { x: edge.end.x, y: edge.start.y };
      offset = { x: lo, y: o };
    }
  } else if (lt(s, 0) && s > -0.04) {
    if (edge.end.x > edge.start.x) {
      // Bottom left
      a = { x: w, y: -h };
      b = { x: -w, y: -h };
      c = { x: -w, y: 0 };
      center = { x: edge.start.x, y: edge.end.y };
      offset = { x: lo, y: -o };
    } else {
      // Top right
      a = { x: -w, y: h };
      b = { x: w, y: h };
      c = { x: w, y: 0 };
      center = { x: edge.start.x, y: edge.end.y };
      offset = { x: -lo, y: o };
    }
  } else if (Math.abs(s) !== Infinity && s < -25) {
    if (edge.end.y > edge.start.y) {
      // Right top
      a = { x: h, y: -w };
      b = { x: h, y: w };
      c = { x: 0, y: w };
      center = { x: edge.start.x, y: edge.end.y };
      offset = { x: o, y: -lo };
    } else {
      // Left bottom
      a = { x: -h, y: w };
      b = { x: -h, y: -w };
      c = { x: 0, y: -w };
      center = { x: edge.start.x, y: edge.end.y };
      offset = { x: -o, y: lo };
    }
  } else if (Math.abs(s) !== Infinity && s > 25) {
    // Right bottom
    if (edge.end.y > edge.start.y) {
      a = { x: h, y: w };
      b = { x: h, y: -w };
      c = { x: 0, y: -w };
      center = { x: edge.end.x, y: edge.start.y };
      offset = { x: o, y: lo };
    } else {
      // Left top
      a = { x: -h, y: -w };
      b = { x: -h, y: w };
      c = { x: 0, y: w };
      center = { x: edge.end.x, y: edge.start.y };
      offset = { x: -o, y: -lo };
    }
  } else {
    return null;
  }

  return new Drawing().symbol(
    [
      {
        type: "polyline",
        options: { lineDash: [2.4, 2.4] },
        vertices: [a, b],
      },
      {
        type: "polyline",
        vertices: [a, c],
      },
    ],
    center,
    { offset },
  );
}
