import type { MaterialRecordCore, SurfaceRecordCore } from "./product.js";

interface MakeupLayer {
  type: "glass" | "interlayer" | "spacer";
  material: MaterialRecordCore
  inboard_surface: SurfaceRecordCore;
  outboard_surface: SurfaceRecordCore;
}


function createNullFields() {
  return {
    id: null,
    application: null,
    category: null,
    classification: null,
    name: null,
    description: null,
    color: null,
    image: null,
    data: null,
    vendor_code: null,
    published: false,
  };
}

function createGlass() {
  return {
    type: "glass",
    material: {
      ...createNullFields(),
      type: "fixed",
      value: {
        ...createNullFields(),
        data: { thickness: null },
        category: "Glass",
        application: "material",
        classification: "Generic.Glass.Monolithic",
      },
      data: { thickness: null },
      category: "Glass",
      application: "material",
      classification: "Generic.Glass.Monolithic",
    },
    inboard_surface: {
      ...createNullFields(),
      type: "text",
      application: "surface",
    },
    outboard_surface: {
      ...createNullFields(),
      type: "text",
      application: "surface",
    },
  };
}

function createSpacer() {
  return {
    type: "spacer",
    material: {
      ...createNullFields(),
      type: "fixed",
      value: {
        ...createNullFields(),
        data: { thickness: null },
        category: "Spacer",
        application: "material",
        classification: "Generic.Spacer",
      },
      data: { thickness: null },
      category: "Spacer",
      application: "material",
      classification: "Generic.Spacer",
    },
  };
}

function createInterlayer() {
  return {
    type: "interlayer",
    material: {
      ...createNullFields(),
      type: "fixed",
      value: {
        ...createNullFields(),
        data: { thickness: null },
        category: "Interlayer",
        application: "material",
        classification: "Generic.Interlayer",
      },
      data: { thickness: null },
      category: "Interlayer",
      application: "material",
      classification: "Generic.Interlayer",
    },
  };
}

function createGenericLayer() {
  return {
    type: "generic",
    material: {
      ...createNullFields(),
      type: "fixed",
      value: {
        ...createNullFields(),
        data: { thickness: null },
        category: "Generic",
        application: "material",
        classification: "Generic.Generic",
      },
      data: { thickness: null },
      category: "Generic",
      application: "material",
      classification: "Generic.Generic",
    }
  }
}

function createMakeup(type = "mono") {
  if (type === "scratch") {
    return {
      ...createNullFields(),
      application: "makeup",
      classification: "Generic.Glass",
      category: "Glass",
      name: "Start from Scratch",
      data: { layers: [] },
    };
  } else if (type === "igu") {
    return {
      ...createNullFields(),
      application: "makeup",
      classification: "Generic.IGU",
      category: "IGU",
      name: "Generic IGU",
      data: {
        layers: [createGlass(), createSpacer(), createGlass()],
      },
    };
  } else if (type === "lami") {
    return {
      ...createNullFields(),
      application: "makeup",
      classification: "Generic.Laminated",
      category: "Laminated",
      name: "Generic Laminated",
      data: {
        layers: [createGlass(), createInterlayer(), createGlass()],
      },
    };
  } else {
    return {
      ...createNullFields(),
      application: "makeup",
      classification: "Generic.Monolithic",
      category: "Monolithic",
      name: "Generic Monolithic",
      data: {
        layers: [createGlass()],
      },
    };
  }
}

function createGenericType(type = "mono") {
  if (type === "igu") {
    return {
      name: "Generic IGU",
      data: {
        layers: [createGlass(), createSpacer(), createGlass()],
      },
    };
  } else if (type === "lami") {
    return {
      name: "Generic Laminated",
      data: {
        layers: [createGlass(), createInterlayer(), createGlass()],
      },
    };
  } else {
    return {
      name: "Generic Monolithic",
      data: {
        layers: [createGlass()],
      },
    };
  }
}

function createSurface() {
  return {
    ...createNullFields(),
    application: "surface",
  };
}

function createMaterial() {
  return {
    ...createNullFields(),
    application: "material",
    data: { thickness: null },
  };
}

function createItemProduct() {
  return {
    ...createNullFields(),
    application: "item",
    data: {},
    item_template: {
      "data": {},
      "depth": {
        "quants": [{ "unit": "inches", "value": 0.01 }]
      },
      "notes": null,
      "shape": {
        "type": "rect"
      },
      "width": null,
      "height": null,
      "drawing": null,
      "description": null,
      "width_offset": {
        "sign": 1,
        "quants": [{ "unit": "inches", "value": 0 }]
      },
      "height_offset": {
        "sign": 1,
        "quants": [{ "unit": "inches", "value": 0 }]
      }
    },
    item_template_mask: {
      "data": false,
      "depth": true,
      "notes": false,
      "shape": true,
      "width": true,
      "height": true,
      "drawing": false,
      "description": false,
      "width_offset": true,
      "height_offset": true
    },
  };
}

export type { MakeupLayer }

export {
  createNullFields,
  createGlass,
  createSpacer,
  createInterlayer,
  createGenericLayer,
  createGenericType,
  createMakeup,
  createSurface,
  createMaterial,
  createItemProduct,
};
