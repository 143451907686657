import { Drawing } from "drawing";

function db(tm, ts, pf, i, dv, s) {
  return ts
    .slice(i)
    .reduce((list, tick) => {
      list.push(...tm.get(tick));
      return list;
    }, [])
    .map(([li, vi]) => ({
      path: `shape.vertices.${li}.${vi}.${s}`,
      value: pf.shape[li][vi][s] + dv,
    }));
}

export function blxDims(tickMap, pf, ds, ex, dx) {
  const ticks = [...tickMap.keys()].sort((a, b) => a - b);

  let dims = [];
  let b = ex.xmin;
  ticks.forEach((t, i) => {
    const bb = b;
    const d = t;
    const callback = (v) => {
      const dv = d > bb ? v - (d - bb) : bb - d - v;
      return db(tickMap, ticks, pf, i, dv, "x");
    };

    const dim = new Drawing()
      .aligned_dim(
        { x: bb, y: dx.ymin },
        { x: d, y: dx.ymin },
        { tier: -1, callback },
      )
      .style(ds)
      .name(`dim_blxtick_${i}`);
    dims.push(dim);
    b = d;
  });
  return dims;
}

export function brxDims(tickMap, pf, ds, ex, dx) {
  const ticks = [...tickMap.keys()].sort((a, b) => b - a);

  let dims = [];
  let b = 0;
  ticks.forEach((t, i) => {
    const bb = b;
    const d = ex.xmax - t;
    const callback = (v) => {
      const dv = d > bb ? -v + (d - bb) : v - (bb - d);
      return db(tickMap, ticks, pf, i, dv, "x");
    };

    const dim = new Drawing()
      .aligned_dim(
        { x: ex.xmax - bb, y: dx.ymin },
        { x: ex.xmax - d, y: dx.ymin },
        { tier: 1, callback },
      )
      .style(ds)
      .name(`dim_brxtick_${i}`);
    dims.push(dim);
    b = d;
  });
  return dims;
}

export function tlxDims(tickMap, pf, ds, ex, dx) {
  const ticks = [...tickMap.keys()].sort((a, b) => a - b);

  let dims = [];
  let b = ex.xmin;
  ticks.forEach((t, i) => {
    const bb = b;
    const d = t;
    const callback = (v) => {
      const dv = d > bb ? v - (d - bb) : bb - d - v;
      return db(tickMap, ticks, pf, i, dv, "x");
    };

    const dim = new Drawing()
      .aligned_dim(
        { x: bb, y: dx.ymax },
        { x: d, y: dx.ymax },
        { tier: 1, callback },
      )
      .style(ds)
      .name(`dim_tlxtick_${i}`);
    dims.push(dim);
    b = d;
  });
  return dims;
}

export function trxDims(tickMap, pf, ds, ex, dx) {
  const ticks = [...tickMap.keys()].sort((a, b) => b - a);

  let dims = [];
  let b = 0;
  ticks.forEach((t, i) => {
    const bb = b;
    const d = ex.xmax - t;
    const callback = (v) => {
      const dv = d > bb ? -v + (d - bb) : v - (bb - d);
      return db(tickMap, ticks, pf, i, dv, "x");
    };

    const dim = new Drawing()
      .aligned_dim(
        { x: ex.xmax - bb, y: dx.ymax },
        { x: ex.xmax - d, y: dx.ymax },
        { tier: -1, callback },
      )
      .style(ds)
      .name(`dim_trxtick_${i}`);
    dims.push(dim);
    b = d;
  });
  return dims;
}

export function blyDims(tickMap, pf, ds, ex, dx) {
  const ticks = [...tickMap.keys()].sort((a, b) => a - b);

  let dims = [];
  let b = ex.ymin;
  ticks.forEach((t, i) => {
    const bb = b;
    const d = t;
    const callback = (v) => {
      const dv = d > bb ? v - (d - bb) : bb - d - v;
      return db(tickMap, ticks, pf, i, dv, "y");
    };

    const dim = new Drawing()
      .aligned_dim(
        { x: dx.xmin, y: bb },
        { x: dx.xmin, y: d },
        { tier: 1, callback },
      )
      .style(ds)
      .name(`dim_blytick_${i}`);
    dims.push(dim);
    b = d;
  });
  return dims;
}

export function bryDims(tickMap, pf, ds, ex, dx) {
  const ticks = [...tickMap.keys()].sort((a, b) => a - b);

  let dims = [];
  let b = ex.ymin;
  ticks.forEach((t, i) => {
    const bb = b;
    const d = t;
    const callback = (v) => {
      const dv = d > bb ? v - (d - bb) : bb - d - v;
      return db(tickMap, ticks, pf, i, dv, "y");
    };

    const dim = new Drawing()
      .aligned_dim(
        { x: dx.xmax, y: bb },
        { x: dx.xmax, y: d },
        { tier: -1, callback },
      )
      .style(ds)
      .name(`dim_brytick_${i}`);
    dims.push(dim);
    b = d;
  });
  return dims;
}

export function tlyDims(tickMap, pf, ds, ex, dx) {
  const ticks = [...tickMap.keys()].sort((a, b) => b - a);

  let dims = [];
  let b = 0;
  ticks.forEach((t, i) => {
    const bb = b;
    const d = ex.ymax - t;
    const callback = (v) => {
      const dv = d > bb ? -v + (d - bb) : v - (bb - d);
      return db(tickMap, ticks, pf, i, dv, "y");
    };

    const dim = new Drawing()
      .aligned_dim(
        { x: dx.xmin, y: ex.ymax - d },
        { x: dx.xmin, y: ex.ymax - bb },
        { tier: 1, callback },
      )
      .style(ds)
      .name(`dim_tlytick_${i}`);
    dims.push(dim);
    b = d;
  });
  return dims;
}

export function tryDims(tickMap, pf, ds, ex, dx) {
  const ticks = [...tickMap.keys()].sort((a, b) => b - a);

  let dims = [];
  let b = 0;
  ticks.forEach((t, i) => {
    const bb = b;
    const d = ex.ymax - t;
    const callback = (v) => {
      const dv = d > bb ? -v + (d - bb) : v - (bb - d);
      return db(tickMap, ticks, pf, i, dv, "y");
    };

    const dim = new Drawing()
      .aligned_dim(
        { x: dx.xmax, y: ex.ymax - bb },
        { x: dx.xmax, y: ex.ymax - d },
        { tier: 1, callback },
      )
      .style(ds)
      .name(`dim_trytick_${i}`);
    dims.push(dim);
    b = d;
  });
  return dims;
}
