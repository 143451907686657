const { isInteger } = Number;
const { abs, trunc, round } = Math;

function gcd(x: { num: number; den: number }): number;
function gcd(a: number, b: number): number;
function gcd(
  ...args: [number, number] | [{ num: number; den: number }]
): number {
  let a, b;

  if (args.length === 1) {
    [{ num: a, den: b }] = args;
  } else {
    [a, b] = args;
  }

  if (!isInteger(a) || !isInteger(b)) {
    throw new TypeError("Expected integer parameters");
  }

  let r;

  while (b !== 0) {
    r = a % b;
    a = b;
    b = r;
  }
  return abs(a);
}

function modf(x: number): [number, number] {
  return [trunc(x), x % 1];
}

function num2frac(x: number, precision: number): [number, number] {
  const den = 2 ** precision;
  const num = round(x * den);
  const d = gcd(num, den);
  return [num / d, den / d];
}

export { gcd, modf, num2frac };
