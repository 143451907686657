<script>
  import { createEventDispatcher, onMount, tick } from "svelte";
  import { api } from "../../api";

  export let defaultValue;
  export let value;
  export let column;
  export let newRow = false;

  const dispatch = createEventDispatcher();

  let text = "";
  let input;
  let results = [];
  let showResults = false;
  let arrowCounter = -1;
  let placeholder = "";

  export const focus = () => {
    input.focus();
  };

  $: findNewResults(text);

  async function findNewResults(t) {
    try {
      const { data, error } = await api
        .from(column.options)
        .select("*")
        .ilike("name", `%${text}%`)
        .range(0, 5);

      if (error) throw error;

      results = data;
    } catch (error) {
      console.log(error);
    }
  }

  function findName(v) {
    if (!v) return "";
    const result = results.find((r) => r.id === v);
    if (!result) return "";
    return result.name;
  }

  async function handleFocus() {
    placeholder = value ? findName(value) : "Input text to search";
    if (!text && value === undefined && defaultValue !== undefined) {
      text = findName(defaultValue);
    } else {
      text = "";
    }

    showResults = true;
    await tick();
    input.select();
  }

  function handleBlur() {
    placeholder = "";
    showResults = false;
    if (value) {
      const result = results.find((r) => r.id === value);
      if (result) text = result.name;
    }
  }

  function handleKeydown(evt) {
    if (evt.key === "ArrowDown") handleDownArrow();
    if (evt.key === "ArrowUp") handleUpArrow();
    if (evt.key === "Enter") handleEnter();
    if (evt.key === "Escape") handleEsc();
  }

  function handleDownArrow() {
    if (arrowCounter < results.length - 1) {
      arrowCounter += 1;
    }
  }

  function handleUpArrow() {
    if (arrowCounter > 0) {
      arrowCounter -= 1;
    }
  }

  function handleEnter() {
    const result = results[arrowCounter];
    if (result) select(result);
    input.blur();
  }

  function handleEsc() {
    input.blur();
  }

  function select(result) {
    value = result.id;
    text = result.name;
    dispatch("update", value);
  }

  onMount(async () => {
    if (value) {
      try {
        const { data, error } = await api
          .from(column.options)
          .select("*")
          .eq("id", value);

        if (error) throw error;

        results = data;
        text = findName(value);
      } catch (error) {
        console.log(error);
      }
    }
  });
</script>

<div class="relative">
  <input
    type="text"
    bind:this={input}
    class="h-8 w-full px-2"
    class:bg-amber-50={newRow}
    style="line-height: 100%;"
    {placeholder}
    bind:value={text}
    on:focus={handleFocus}
    on:blur={handleBlur}
    on:keydown={handleKeydown}
  />
  {#if showResults}
    <ul
      class="absolute bg-gray-100 rounded shadow-lg w-full z-20 overflow-hidden"
    >
      {#if results.length > 0}
        {#each results as result, index}
          <li
            class="flex items-center px-2 py-1"
            class:keyed={index === arrowCounter}
            on:mousedown={() => select(result)}
          >
            {result.name}
          </li>
        {/each}
      {:else}
        <li class="px-2 py-1">No matching results found.</li>
      {/if}
    </ul>
  {/if}
</div>

<style lang="scss">
  li:hover,
  .keyed {
    @apply bg-blue-500 text-white;
  }
</style>
