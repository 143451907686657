import range from "lodash/range";

const f = (a, b) => [].concat(...a.map((d) => b.map((e) => [].concat(d, e))));
const cartesian = (a, b, ...c) => (b ? cartesian(f(a, b), ...c) : a);

function nearColors(r, g, b, distance = 2) {
  const rOptions = range(r - distance, r + distance + 1);
  const gOptions = range(g - distance, g + distance + 1);
  const bOptions = range(b - distance, b + distance + 1);

  return cartesian(rOptions, gOptions, bOptions).map(
    ([r, g, b]) => `rgb(${r},${g},${b})`
  );
}

export default nearColors;
