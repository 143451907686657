<svelte:options strictprops={false} />

<script>
  import { getContext, createEventDispatcher, onDestroy, tick } from "svelte";
  import isEqual from "lodash/isEqual";
  import typeIsCorrect from "../lib/type-is-correct.js";
  import TextRenderer from "./TextRenderer.svelte";
  import { exitEvents, metaKeys, directions } from "./constants.js";

  const dispatch = createEventDispatcher();

  export let row;
  export let column;
  export let value = null;
  export let rowData;
  export let type = undefined;
  export let parser = (v) => v;
  export let validator = (v) => true;
  export let formatter = (v) => v ?? "";
  export let unparser = undefined;
  export let acceptInvalid = false;
  export let readOnly = false;
  export let disabled = false;
  export let rowType;
  export const focus = () => {
    input.select();
  };

  const { editing, focused, emptyRow } = getContext("datagrid");

  let localValue = value;
  let inputValue = unparser ? unparser(value) : formatter(value);
  let input;
  let destroying = false;

  $: update(value);

  function update(value) {
    localValue = value;
    inputValue = unparser ? unparser(value) : formatter(value);
  }

  function validate(v, rowData) {
    try {
      return validator(v, rowData) && typeIsCorrect(v, type);
    } catch (err) {
      return false;
    }
  }

  function handleInput(shift = false) {
    if (readOnly || disabled) return;
    let val = inputValue;

    try {
      val = parser(val);

      if (!acceptInvalid && !validate(val, rowData)) {
        throw new Error("Invalid value");
      }

      if (!isEqual(val, value) && !(val === "" && !value)) {
        localValue = val;
        dispatch("updateValue", { value: localValue, shift });
      }
    } catch (err) {
      console.log(err.message);
    }
  }

  function moveCursorToEnd() {
    const end = input.value.length;
    input.setSelectionRange(end, end);
  }

  function click(e) {
    if ($editing) return;

    if (!(readOnly || disabled)) {
      $editing = true;
      moveCursorToEnd();
    }
  }

  function keydown(e) {
    if ($editing) return edit(e);

    if (exitEvents[e.key]) {
      e.preventDefault();
      focused.move(directions[e.key], e.shiftKey);
    } else if (e.key === "Delete" || e.key === "Backspace") {
      return;
    } else if (!metaKeys[e.key] && !e.metaKey && !e.ctrlKey && !readOnly && !disabled) {
      $editing = true;
      if (e.key === "Enter") {
        moveCursorToEnd();
      }
    }
  }

  async function edit(e) {
    if (e.key === "Enter") {
      const row = $focused.row;
      const col = $focused.col;
      if (row + 1 < $focused.rowCount || emptyRow) {
        destroying = true;
      }
      handleInput(false);
      $editing = false;

      await tick();
      focused.enter(e.shiftKey);
    }
  }

  function blur(e) {
    if (destroying) return;
    destroying = true;

    // Detect whether blur was caused by a "tab" key from
    // the empty row, and therefore the focus position needs
    // to shift. Is there a better way?
    // NB: Chrome/FF behave differently--this blur event never
    // fires in FF when clicking between cells, but it does in
    // Chrome.
    if (rowType === "empty" && e.relatedTarget) {
      handleInput(true);
    } else {
      handleInput();
    }
    $editing = false;
    if ($focused.row === row && $focused.col === column) {
      $focused.row = null;
      $focused.col = null;
    }
  }

  onDestroy(() => {
    if (destroying) return;
    handleInput();
  });
</script>

<input
  bind:this={input}
  bind:value={inputValue}
  on:keydown={keydown}
  on:mousedown|stopPropagation
  on:blur={blur}
  on:click={click}
  class:italic={readOnly}
  class:editing={$editing} />

{#if !$editing}
  <TextRenderer bind:value {type} {formatter} {disabled} {readOnly} />
{/if}

<style lang="scss">
  input {
    min-width: 10px;
    max-width: 100%;
    margin: 0;
    width: 100%;
    height: 100%;
    padding: 0.25rem;
    position: absolute;
    top: 0;
    left: 0;
    background: transparent;
    outline: none;
    opacity: 0;

    &:focus {
      outline: none;
    }

    &.editing {
      opacity: 1;
    }
  }
</style>
