import type { SupabaseClient, SupabaseClientOptions } from "@supabase/supabase-js";
import { createClient as baseCreateClient } from "@supabase/supabase-js";
import { mergeDeepRight } from "ramda";

import type { Database } from "./database.js";

const T0 = new Date(0).toUTCString();
const MAX_AGE = 36524 * 24 * 60 * 60; // Number of days in 21st century
const ACCESS_TOKEN_KEY = "lamina-access-token";
const REFRESH_TOKEN_KEY = "lamina-refresh-token";

function createCookies(domain: string) {
    function setCookie(key: string, value: string, maxAge: number = MAX_AGE) {
        document.cookie = `${key}=${value}; domain=${domain}; path=/; max-age=${maxAge}; SameSite=Lax; secure`;
    }

    function removeCookie(key: string) {
        document.cookie = `${key}=; domain=${domain}; path=/; expires=${T0}; SameSite=Lax; secure`;
    }

    return {
        set(accessToken: string, refreshToken: string) {
            setCookie(ACCESS_TOKEN_KEY, accessToken);
            setCookie(REFRESH_TOKEN_KEY, refreshToken);
        },
        remove() {
            removeCookie(ACCESS_TOKEN_KEY);
            removeCookie(REFRESH_TOKEN_KEY);
        }
    };
}


type Client = SupabaseClient<Database, "public">;
type ClientOptions = SupabaseClientOptions<"public">
type BrowserClientOptions = ClientOptions & {
    cookies: {
        domain: string
    }
};

function createClient(supabaseUrl: string, supabaseKey: string, options: BrowserClientOptions): Client {

    const { cookies: { domain }, ...clientOptions} = options

    const client = baseCreateClient<Database>(
        supabaseUrl,
        supabaseKey,
        clientOptions
    );

    const cookies = createCookies(domain);

    client.auth.onAuthStateChange((event, session) => {
        switch (event) {
            case "SIGNED_IN":
            case "TOKEN_REFRESHED":
                if (session) {
                    cookies.set(session.access_token, session.refresh_token)
                }
                break;

            case "SIGNED_OUT":
                cookies.remove();
                break;
        }
    });

    return client;
}

function createServerClient(supabaseUrl: string, supabaseKey: string, options: ClientOptions = {}): Client {
    const baseOptions: ClientOptions = {
        auth: {
            persistSession: false
        }
    };

    const client = baseCreateClient<Database>(
        supabaseUrl,
        supabaseKey,
        mergeDeepRight(baseOptions, options)
    );

    return client;
}

export { createClient, createServerClient, ACCESS_TOKEN_KEY, REFRESH_TOKEN_KEY }
