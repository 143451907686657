export class DimTextError extends Error {
  cause?: Error;

  constructor(message: string, options: { cause?: Error }) {
    super(message);
    this.cause = options.cause;
  }
}

export class DimTextParseError extends DimTextError {
  input: string;
  offset: number;
  token: any;

  constructor(
    message: string,
    options: {
      cause?: Error;
      input: string;
      offset?: number;
      token?: any; //?
    }
  ) {
    super(message, { ...options });
    this.input = options.input;
    this.offset = options.offset === undefined ? -1 : options.offset;
    this.token = options.token;
  }
}
