<script>
  import { createEventDispatcher } from "svelte";
  import SelectInput from "src/lib/sidebar/SelectInput.svelte";
  import { guessRecords, getPresentRecords } from "src/extensions/guess-records.js";

  export let data;
  export let records;
  export let prop = "type_id";
  export let recname = "types";
  export let allowPlaceholder = true;

  const dispatch = createEventDispatcher();

  let recmap = guessRecords(data, records, prop, allowPlaceholder);

  $: presentRecords = data ? getPresentRecords(data, prop) : [];
  $: recOptions = records.map((r) => ({
    label: prop === "type_id" ? `${r.mark}${r.name && ` - ${r.name}`}` : r.mark,
    value: r.id,
  }));
  $: options = [
    ...(allowPlaceholder ? [{ label: "Create New", value: "placeholder" }] : []),
    { label: "None", value: null },
  ].concat(recOptions);
  $: pc = presentRecords.reduce((o, c) => {
    o[c.value] = crypto.randomUUID();
    return o;
  }, {});

  function setRecmap(prop, recId) {
    recmap[prop] = recId;
  }

  function prev() {
    dispatch("prev");
  }

  function recProp(id) {
    if (!id) {
      id = "Unassigned";
    }

    if (recmap[id] === "placeholder") {
      return pc[id];
    }

    return recmap[id];
  }

  function next() {
    let result = data.map((d) => ({
      ...d,
      [prop]: recProp(d[prop]),
    }));

    const mapped_with_records = result.filter((row) =>
      Object.values(row).some((v) => !["", undefined, null].includes(v))
    );

    const placeholder_records = Object.entries(pc).reduce((res, [prop, id]) => {
      if (recmap[prop] === "placeholder") {
        if (prop === "Unassigned") {
          res[id] = "New Opening";
        } else {
          res[id] = prop;
        }
      }
      return res;
    }, {});

    dispatch("next", {
      [`mapped_with_${recname}`]: mapped_with_records,
      [`placeholder_${recname}`]: placeholder_records,
    });
  }
</script>

<div class="flex flex-col gap-4 overflow-hidden">
  <slot>
    <p>Map {recname}.</p>
  </slot>
  <div class="overflow-y-auto space-y-1">
    {#each presentRecords as rec}
      <SelectInput
        label={rec.label}
        {options}
        labelWidth="6rem"
        value={recmap[rec.value]}
        on:input={(e) => setRecmap(rec.value, e.detail.value)} />
    {/each}
  </div>
  <div class="flex-none flex justify-end items-center space-x-2">
    <button class="btn w-32" on:click={prev}>Previous</button>
    <button class="btn btn-primary w-32" on:click={next}>Next</button>
  </div>
</div>
