class EventBus {
  constructor() {
    this.subscriptions = {};
  }

  on(event, callback) {
    if (!this.subscriptions[event]) {
      this.subscriptions[event] = [];
    }

    this.subscriptions[event].push(callback);
  }

  dispatch(event, payload) {
    if (this.subscriptions[event]) {
      this.subscriptions[event].forEach((callback) => {
        callback(payload);
      });
    }
  }

  unsubscribe(event, callback) {
    const index = this.subscriptions[event].indexOf(callback);
    if (index !== -1) {
      this.subscriptions[event].splice(index, 1);
    }
  }
}

export default EventBus;
