<script>
  import Portal from "./Portal.svelte";

  export let container = document.body;
  export let z = 210;
  export let width = null;
  export let align = "left";
  export let fit = false;

  let el;

  $: bbox = getBbox(container, el);

  function getBbox(container, el) {
    const c = container.getBoundingClientRect();
    const db = document.body.getBoundingClientRect();
    const e = el ? el.getBoundingClientRect() : c;

    const a = c.top - 3;
    const b = db.height - c.top - c.height - 3;

    const w = width || (fit ? c.width : e.width);

    let l;
    if (align === "right") {
      l = w > c.right ? 0 : c.right - w;
    } else {
      l = c.left + w > db.width ? db.width - w : c.left;
    }

    const fitsBelow = e.height <= b;
    const fitsAbove = e.height <= a;

    let t;
    let maxH;
    if (fitsBelow) {
      t = c.top + c.height + 3;
      maxH = b;
    } else if (fitsAbove) {
      t = c.top - e.height - 3;
      maxH = a;
    } else if (b > a) {
      t = c.top + c.height + 3;
      maxH = b;
    } else {
      t = 0;
      maxH = a;
    }

    return { l, t, w, maxH };
  }
</script>

<Portal>
  <div
    style="
      border-radius: 0.25rem;
      filter: drop-shadow(0 10px 8px rgb(0 0 0 / 0.04)) drop-shadow(0 4px 3px rgb(0 0 0 / 0.1));
      background: white;
      border: 1px solid #e2e8f0;
      position: absolute;
      z-index: {z};
      top: {bbox.t}px;
      left: {bbox.l}px;
      width: {fit ? `${bbox.w}px` : 'fit-content'};
      max-height: {bbox.maxH}px;
      overflow-y: auto;
    ">
    <div bind:this={el}>
      <slot />
    </div>
  </div>
</Portal>
