const fillColors = {
  none: "#FFFFFF",
  emphasized: "#FCD34D",
  flagged: "#EF4444",
  approved: "#16A34A",
};

const highlightedFillColors = {
  none: "#E7E5E4",
  // none: "#65a30d",
  // emphasized: "#F59E0B",
  emphasized: "#000000",
  flagged: "#701414",
  approved: "#0A371B",
};

const strokeColors = {
  none: "#000000",
  emphasized: "#000000",
  flagged: "#EF4444",
  approved: "#16A34A",
};

const highlightedStrokeColors = {
  none: "#000000",
  emphasized: "#000000",
  flagged: "#701414",
  approved: "#0A371B",
};

const textColors = {
  none: "#000000",
  emphasized: "#000000",
  flagged: "#FFFFFF",
  approved: "#FFFFFF",
};

const highlightedTextColors = {
  none: "#000000",
  emphasized: "#FFFFFF",
  flagged: "#FFFFFF",
  approved: "#FFFFFF",
};

export default function markColor(
  item,
  { inverted = false, emphasized = false, highlighted = false } = {}
) {
  let status = item?.approval_status || "none";
  if (emphasized && status === "none") {
    status = "emphasized";
  }

  let fill;
  let textColor;
  let stroke;

  if (inverted) {
    fill = "#FFFFFF";
    textColor = "#000000";
    stroke = status === "none" ? "#000000" : fillColors[status];
  } else {
    fill = highlighted ? highlightedFillColors[status] : fillColors[status];
    textColor = highlighted
      ? highlightedTextColors[status]
      : textColors[status];
    stroke = highlighted
      ? highlightedStrokeColors[status]
      : strokeColors[status];
  }

  return {
    textColor,
    stroke,
    fill,
  };
}
