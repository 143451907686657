/**
 * @param {HTMLElement} node
 * @param {Object} options
 * @param {string | null} [options.text]
 */
export function tooltip(node, { text = "Tooltip" }) {
  if (!text) {
    return {
      destroy() {},
    };
  }

  let mousedover = false;
  let tt = null;
  let timeout;

  node.addEventListener("mouseenter", handleMouseEnter);

  function handleMouseEnter() {
    mousedover = true;

    timeout = setTimeout(() => {
      if (tt?.parentNode) {
        tt.parentNode.removeChild(tt);
      }

      if (mousedover && node) {
        tt = document.createElement("div");
        tt.textContent = text;
        tt.classList.add("text-xs");
        tt.classList.add("border");
        tt.classList.add("border-gray-400");
        tt.classList.add("rounded");
        tt.classList.add("py-2");
        tt.classList.add("px-3");
        tt.classList.add("mt-2");
        tt.classList.add("text-gray-200");
        tt.classList.add("bg-black");
        tt.classList.add("auto-tooltip");
        tt.classList.add("shadow-lg");
        tt.style.position = "absolute";
        tt.style.pointerEvents = "none";

        const nbbox = node.getBoundingClientRect();
        let l = nbbox.left;
        let y = nbbox.bottom;
        // let r = nbbox.right;
        let c = l + nbbox.width / 2;

        tt.style.display = "block";
        tt.style.whiteSpace = "nowrap";
        tt.style.visibility = "hidden";
        tt.style.top = `${y}px`;
        tt.style.zIndex = "9999";
        document.body.appendChild(tt);

        const width = tt.getBoundingClientRect().width;
        const rspace = window.innerWidth - c - width / 2;
        const lspace = c - width / 2;

        if (lspace < 0) {
          tt.classList.add("auto-tooltip-left");
          tt.style.left = `${c - 12}px`;
        } else if (rspace < 0) {
          tt.classList.add("auto-tooltip-right");
          tt.style.left = `${c - width + 12}px`;
        } else {
          tt.style.left = `${c - width / 2}px`;
        }

        tt.style.visibility = "visible";
        mousedover = false;
      }
    }, 750);

    node.addEventListener("mouseleave", handleMouseLeave);

    function handleMouseLeave() {
      mousedover = false;
      clearTimeout(timeout);
      if (tt?.parentNode) {
        tt.parentNode.removeChild(tt);
      }
      node.removeEventListener("mouseleave", handleMouseLeave);
    }
  }

  return {
    destroy() {
      clearTimeout(timeout);
      node.removeEventListener("mouseenter", handleMouseEnter);
      if (tt?.parentNode) {
        tt.parentNode.removeChild(tt);
      }
    },
  };
}
