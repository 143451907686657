import { almostEqual } from "overline";
import { angleVec, cwAngleBetween, ccwAngleBetween } from "vector";

const PIx2 = 2 * Math.PI;

function posAngle(angle) {
  const or = angle % PIx2;
  return or < 0 ? or + PIx2 : or;
}

export function sweep(startAngle, endAngle, ccw) {
  const sa = posAngle(startAngle);
  const ea = posAngle(endAngle);

  if (almostEqual(sa, ea)) return 0;
  if (almostEqual(Math.abs(sa - ea), PIx2)) return PIx2;
  if (almostEqual(Math.abs(sa - ea), Math.PI)) return Math.PI;

  const a = angleVec(startAngle);
  const b = angleVec(endAngle);

  return ccw ? ccwAngleBetween(a, b) : cwAngleBetween(a, b);
}
