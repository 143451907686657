<script>
  import { onMount, createEventDispatcher, getContext } from "svelte";
  import { objectify } from "overline";
  import { navigate } from "svelte-routing";
  import Thumbnail from "./Thumbnail.svelte";
  import PlusIcon from "src/assets/icons/plus.svg";
  import { selectedItems, selectedType, itemHt, pathRoot } from "src/stores/ui.js";
  import isInView from "src/extensions/dom/is-in-view.js";
  import typeDrawing from "@local/extensions/drawing/type-drawing.js";
  import liteDrawing from "@local/extensions/drawing/lite-drawing.js";
  import Viewport from "./ViewportResizable.svelte";
  import eb from "src/extensions/event-bus.js";
  import dimSettings from "@local/extensions/utilities/dim-settings.js";
  import imageUrl from "src/extensions/image-url.js";

  export let group;
  export let items;
  export let itemList;
  export let orderedCategory;
  export let groupedItems;
  export let groupBy;
  export let itemComments;
  export let itemAttachments;
  export let typeObj;
  export let container;
  export let disabled;
  export let isCollection = false;
  export let collectionItems = {};

  const dispatch = createEventDispatcher();
  const typeColors = getContext("typeColors");
  const makeupLibrary = getContext("makeupLibrary");
  const supplier = getContext("supplier");

  let vpHeight;
  let isDragging = false;
  let dragging = null;

  $: maxItemHt = Math.max(...items.map((i) => (i.drawing ? 60 : i.cache.height)));
  $: gap = $itemHt / 10;
  $: draggingCat = isDragging && dragging ? dragging.category : null;
  $: ds = dimSettings($group.data.settings);
  $: makeups = $makeupLibrary.map((m) => m.records).flat();
  $: makeupObj = objectify(makeups, "id");

  function scrollTo(id) {
    const el = document.getElementById(`thumbnail-${id}`);
    if (!el) return;

    const i = $group.items[id];
    const ipct = i.cache.height / maxItemHt;
    const maxH = $itemHt - 1 - 50;
    const itemCssHt = maxH * ipct + 1 + 50;
    if (isInView(el, itemCssHt, 0, container)) return;
    el.scrollIntoView({ behavior: "smooth" });
  }

  function zoomIn() {
    itemHt.zoomin();
  }

  function zoomOut() {
    itemHt.zoomout();
  }

  function zoomFit() {
    itemHt.fit(vpHeight);
  }

  function addNewItem(val) {
    if (val) {
      dispatch("newItem", { [groupBy]: val });
    } else {
      dispatch("newItem");
    }
  }

  function selectType(index) {
    $selectedItems = {};
    $selectedType = index;
  }

  function drop(catId) {
    if (disabled) return;
    if (!isDragging || groupBy === "none") return;
    if (catId === dragging.category) return;
    if ($group.project_type === "product") return;

    const ids = Object.keys(dragging.items);
    group.updateMultipleItems(ids, groupBy, catId);
    dragging = null;
    isDragging = false;
  }

  function nav(id) {
    const newpath = `${$pathRoot}types/${id}`;
    navigate(newpath);
  }

  function navCollection(id) {
    const newpath = `${$pathRoot}openings/${id}`;
    navigate(newpath);
  }

  onMount(() => {
    if (!itemHt.hasBeenFit()) itemHt.fit(vpHeight);

    eb.on("zoom-in", zoomIn);
    eb.on("zoom-out", zoomOut);
    eb.on("zoom-to-fit", zoomFit);
    eb.on("scrollto", scrollTo);

    return () => {
      eb.unsubscribe("zoom-in", zoomIn);
      eb.unsubscribe("zoom-out", zoomOut);
      eb.unsubscribe("zoom-to-fit", zoomFit);
      eb.unsubscribe("scrollto", scrollTo);
    };
  });
</script>

<div class="w-full h-full" bind:offsetHeight={vpHeight}>
  {#if groupBy !== "none"}
    {#each orderedCategory as id, catIndex}
      <!-- svelte-ignore a11y-no-static-element-interactions -->
      <div
        class="cat-container w-full pt-14 pb-10 flex items-start space-x-4 border-b"
        class:dragging={isDragging && draggingCat !== id}
        on:mouseup={() => drop(id)}>
        <div class="sticky top-0 flex-none flex flex-col items-center">
          {#if groupBy === "type_id"}
            <div
              class="relative w-24 h-36 md:w-36 md:h-48 ml-2"
              class:ring-4={$selectedType === catIndex}
              on:mousedown|stopPropagation={() => selectType(catIndex)}
              on:click|stopPropagation
              on:keydown={() => selectType(id)}
              on:dblclick={() => nav(id)}>
              <Viewport padding={1} paddingBottom={50} drawing={typeDrawing(typeObj[id])} />
            </div>
          {:else if groupBy === "type.product_id"}
            {@const makeup = makeupObj[id]}
            <div class="relative w-24 h-36 md:w-36 md:h-48 ml-2">
              {#if makeup}
                {#if makeup.image}
                  <div class="p-2">
                    <img
                      src={imageUrl(makeup.image)}
                      alt={makeup.name}
                      class="text-xs w-full h-full object-contain" />
                  </div>
                {:else}
                  <Viewport padding={1} drawing={typeDrawing(makeup)} />
                {/if}
                <div class="text-sm text-center px-2">{makeup.name}</div>
              {:else}
                {id}
              {/if}
            </div>
          {:else if groupBy === "category"}
            {@const category = $supplier?.product_categories[id]}
            <div class="relative w-24 h-36 md:w-36 md:h-48 ml-2">
              {#if category}
                {#if category.image}
                  <div class="p-2">
                    <img
                      src={imageUrl(category.image)}
                      alt={category.name}
                      class="border border-gray-300 text-xs w-full h-full object-contain" />
                  </div>
                {/if}
                <div class="text-sm text-center px-2">{category.name}</div>
              {:else}
                <div class="text-sm text-center px-2">
                  {id}
                </div>
              {/if}
            </div>
          {:else if groupBy === "collection_id"}
            <div
              class="relative w-24 h-36 md:w-36 md:h-48 ml-2"
              class:ring-4={$selectedType === catIndex}
              on:mousedown|stopPropagation={() => selectType(catIndex)}
              on:click|stopPropagation
              on:keydown={() => selectType(catIndex)}
              on:dblclick={() => navCollection(id)}>
              <Viewport
                padding={10}
                paddingBottom={50}
                drawing={liteDrawing($group.items[id], {
                  isCollection: true,
                  typeColor: $typeColors[$group.items[id]?.type_id],
                })} />
            </div>
          {:else}
            <div class="empty-box">
              <div>
                {id}
              </div>
            </div>
          {/if}
        </div>
        <div class="flex-grow flex flex-wrap justify-start items-end gap-y-4" style={`column-gap:${gap}px;`}>
          {#if groupedItems[id]}
            {#each groupedItems[id] as item (item.id)}
              <div class="relative">
                <Thumbnail
                  {group}
                  {item}
                  {itemList}
                  displayUnit={ds.displayUnit}
                  dimFormat={ds.dimFormat}
                  displayPrecision={ds.dimPrecision}
                  comments={itemComments[item.id] && itemComments[item.id].length}
                  attachments={itemAttachments[item.id] && itemAttachments[item.id].length}
                  collectionItems={collectionItems[item.id]?.length}
                  category={id}
                  bind:dragging
                  bind:isDragging
                  maxHeight={maxItemHt}
                  cssHeight={$itemHt}
                  {isCollection} />
              </div>
            {/each}
          {/if}
          {#if !disabled}
            <button
              class="w-12 text-gray-400"
              style={`padding-bottom:50px;`}
              on:click|stopPropagation={() => addNewItem(id)}>
              <div class="new-item-button">
                <PlusIcon />
              </div>
            </button>
          {/if}
        </div>
      </div>
    {/each}
    {#if groupBy === "category"}
      <div class="cat-container w-full pt-14 pb-12 flex items-start space-x-4">
        <div class="sticky top-0 flex-none flex flex-col items-center">
          <div class="empty-box"></div>
        </div>
        <div class="flex-grow flex flex-wrap justify-start items-end gap-y-4" style="column-gap:{gap}px;">
          {#if !disabled}
            <button
              class="text-gray-400"
              style="padding-bottom:50px;"
              on:click|stopPropagation={() => addNewItem()}>
              <div
                class="w-32 h-32 rounded hover:bg-gray-200 border border-dashed border-gray-400 flex flex-col items-center justify-center gap-2">
                <PlusIcon />
                <div class="text-xs">Click to add item</div>
              </div>
            </button>
          {/if}
        </div>
      </div>
    {:else if groupedItems.untyped}
      <!-- svelte-ignore a11y-no-static-element-interactions -->
      <div
        class="cat-container w-full pt-14 pb-12 flex items-start space-x-4"
        class:dragging={isDragging && draggingCat !== null}
        on:mouseup={() => drop(null)}>
        <div class="sticky top-0 flex-none flex flex-col items-center">
          <div class="empty-box">
            <div class="border-dashed">{groupBy === "type_id" ? "No type" : "No group"}</div>
          </div>
        </div>
        <div class="flex-grow flex flex-wrap justify-start items-end gap-y-4" style={`column-gap:${gap}px;`}>
          {#each groupedItems.untyped as item (item.id)}
            <div class="relative">
              <Thumbnail
                {group}
                {item}
                {itemList}
                displayUnit={ds.displayUnit}
                dimFormat={ds.dimFormat}
                displayPrecision={ds.dimPrecision}
                comments={itemComments[item.id] && itemComments[item.id].length}
                attachments={itemAttachments[item.id] && itemAttachments[item.id].length}
                collectionItems={collectionItems[item.id]?.length}
                bind:dragging
                bind:isDragging
                maxHeight={maxItemHt}
                cssHeight={$itemHt}
                {isCollection} />
            </div>
          {/each}
          {#if !disabled}
            <button
              class="w-12 text-gray-400"
              style="padding-bottom:50px;"
              on:click|stopPropagation={() => addNewItem()}>
              <div class="new-item-button">
                <PlusIcon />
              </div>
            </button>
          {/if}
        </div>
      </div>
    {/if}
  {:else}
    <div class="px-16 pt-14">
      <div class="w-full flex flex-wrap justify-start items-end gap-y-4" style={`column-gap:${gap}px;`}>
        {#each items as item (item.id)}
          <div class="relative">
            <Thumbnail
              {group}
              {item}
              {itemList}
              displayUnit={ds.displayUnit}
              dimFormat={ds.dimFormat}
              displayPrecision={ds.dimPrecision}
              comments={itemComments[item.id] && itemComments[item.id].length}
              attachments={itemAttachments[item.id] && itemAttachments[item.id].length}
              collectionItems={collectionItems[item.id]?.length}
              bind:dragging
              bind:isDragging
              maxHeight={maxItemHt}
              cssHeight={$itemHt}
              {isCollection} />
          </div>
        {/each}
        {#if !disabled}
          <button
            class="w-12 text-gray-400"
            style={`padding-bottom:50px;`}
            on:click|stopPropagation={() => addNewItem()}>
            <div class="new-item-button">
              <PlusIcon />
            </div>
          </button>
        {/if}
      </div>
    </div>
  {/if}
</div>

<style lang="scss">
  .cat-container {
    &.dragging:hover {
      @apply bg-blue-100;
    }
  }

  .new-item-button {
    @apply w-full h-12 border border-dashed border-gray-400 flex items-center justify-center;

    &:hover {
      @apply bg-gray-200;
    }
  }

  .empty-box {
    @apply flex items-center justify-center text-gray-400 ml-2 text-sm;
    width: 6rem;
    height: 6rem;

    @media (min-width: 768px) {
      width: 9rem;
      height: 9rem;
    }

    div {
      @apply w-24 h-full border-gray-400 flex items-center justify-center text-center;
    }
  }
</style>
