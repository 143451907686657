/*
A data structure for creating drawings, which can then be plotted to canvas/svg;
*/

import { add } from "vector";
import crypto from "isomorphs/crypto";
import { BufferedImage } from "isomorphs/canvas";
import cloneDeepWith from "lodash/cloneDeepWith.js";
import isObjectLike from "lodash/isObject.js";
import Point from "./entities/point.js";
import Polyline from "./entities/polyline.js";
import Circle from "./entities/circle.js";
import Polygon from "./entities/polygon.js";
import NGon from "./entities/ngon.js";
import Mark from "./entities/mark.js";
import Symbol from "./entities/symbol.js";
import Pin from "./entities/pin.js";
import Mask from "./entities/mask.js";
import Text from "./entities/text.js";
import Img from "./entities/image.js";
import Polyface from "./entities/polyface.js";
import Rectangle from "./entities/rectangle.js";
import AlignedDim from "./entities/aligned-dim.js";
import DiameterDim from "./entities/diameter-dim.js";
import RadiusDim from "./entities/radius-dim.js";
import context from "./contexts/context.js";
import getHashColor from "./utils/random-color.js";
import getRandomColor from "./utils/random-color.js";

const entities = {
  polyline: Polyline,
  circle: Circle,
  point: Point,
  polygon: Polygon,
  ngon: NGon,
  mark: Mark,
  symbol: Symbol,
  pin: Pin,
  text: Text,
  image: Img,
  polyface: Polyface,
  rectangle: Rectangle,
  aligned_dim: AlignedDim,
  diameter_dim: DiameterDim,
  radius_dim: RadiusDim,
};

function* level(root, keepFunction) {
  const queue = [];
  queue.push(root);
  while (queue.length > 0) {
    const dwg = queue.shift();

    yield dwg;
    for (const child of dwg.node.children) {
      if (keepFunction) {
        if (keepFunction(child)) queue.push(child);
      } else {
        queue.push(child);
      }
    }
  }
}

class Drawing {
  constructor(dwg) {
    const node = {
      id: crypto.randomUUID(),
      entity: null,
      mask: null,
      name: null,
      layer: null,
      style: null,
      skipBbox: false,
      shift: null,
      children: [],
    };

    const options = dwg ? (dwg.node ? dwg.node : dwg) : {};

    delete options.id;

    if (options.children) {
      options.children = options.children.map((c) => new Drawing(c));
    }

    this.node = { ...node, ...options };
  }

  skipBbox() {
    this.node.skipBbox = true;
    return this;
  }

  clone() {
    const node = cloneDeepWith(this.node, (value) => {
      if (
        isObjectLike(value) &&
        "clone" in value &&
        typeof value.clone === "function"
      ) {
        return value.clone();
      }
    });

    return new Drawing({ node });
  }

  cloneWithoutChildren() {
    const { children, ...node } = this.node;
    return new Drawing({ node });
  }

  add(...dwgs) {
    const clone = this.clone();

    dwgs.forEach((dwg) => {
      if (dwg instanceof Drawing) {
        clone.node.children.push(dwg.clone());
      } else {
        const childDwg = new Drawing();
        childDwg.node.entity = dwg;
      }
    });

    return clone;
  }

  transform(matrix) {
    const dwg = this.cloneWithoutChildren();
    if (this.node.mask) dwg.node.mask = this.node.mask.transform(matrix);
    if (this.node.entity) dwg.node.entity = this.node.entity.transform(matrix);
    dwg.node.children = this.node.children.map((child) =>
      child.transform(matrix),
    );
    return dwg;
  }

  translate(x, y) {
    return this.transform([1, 0, 0, 1, x, y]);
  }

  rotate(angle) {
    const cos = Math.cos(angle);
    const sin = Math.sin(angle);

    return this.transform([cos, sin, -sin, cos, 0, 0]);
  }

  scale(x, y) {
    return this.transform([x, 0, 0, y, 0, 0]);
  }

  style(props) {
    const dwg = this.clone();
    if (!dwg.node.style) dwg.node.style = {};

    Object.entries(props).forEach(([key, value]) => {
      dwg.node.style[key] = value;
    });

    return dwg;
  }

  name(n) {
    const dwg = this.clone();
    dwg.node.name = n;
    return dwg;
  }

  layer(n) {
    this.node.layer = n;
    return this;
  }

  get bbox() {
    const bboxes = [];

    const tree = level(this, (d) => !d.node.skipBbox);

    for (const d of tree) {
      if (d.node.entity) bboxes.push(d.node.entity.bbox);
    }

    return {
      xmin: Math.min(...bboxes.map((b) => b.xmin)),
      xmax: Math.max(...bboxes.map((b) => b.xmax)),
      ymin: Math.min(...bboxes.map((b) => b.ymin)),
      ymax: Math.max(...bboxes.map((b) => b.ymax)),
    };
  }

  tree() {
    return level(this);
  }

  find(condition) {
    const c =
      typeof condition === "string"
        ? (d) => d.node.id === condition || d.node.name === condition
        : condition;

    for (const dwg of this.tree()) {
      if (c(dwg)) return dwg;
    }

    return null;
  }

  *entityList() {
    for (const d of this.tree()) {
      if (d.node.entity) yield d.node.entity;
    }
  }

  get entities() {
    return [...this.entityList()];
  }

  get entity() {
    const dwg = this.find((d) => d.node.entity);
    if (dwg) return dwg.node.entity;
    return null;
  }

  render(options, root = true) {
    const defaults = {
      fill: "white",
      stroke: "black",
      lineWidth: 1,
      highlightedLineWidth: 3,
      highlightColor: "#3b82f6",
      highlightOffset: 0.5,
      lineDash: null,
      annoScale: 1,
      annoExtension: 5,
      annoHashLength: 5,
      annoOffset: 50,
      annoFormat: "DECIMAL",
      annoPrecision: 2,
      dimConversion: 1,
      fontSize: 12,
      fontWeight: "normal",
      textColor: "black",
      layer: "default",
    };

    options = {
      ...defaults,
      ...options,
      ...this.node.style,
    };

    if (root) {
      options.ctx = context(options.ctx, options.type);
    }

    if (this.node.layer) {
      options.layer = this.node.layer;
    }

    options.ctx.setLayer(options.layer);

    if (this.node.mask) {
      this.node.mask.render(options);
    }

    if (!options.shift) {
      options.shift = this.node.shift;
    } else if (this.node.shift) {
      options.shift = add(options.shift, this.node.shift);
    }

    this.node.children.forEach((child) => {
      child.render(options, false);
    });

    if (this.node.entity) {
      const entity = options.shift
        ? this.node.entity.transform([
            1,
            0,
            0,
            1,
            options.shift.x * options.annoScale,
            options.shift.y * options.annoScale,
          ])
        : this.node.entity;

      entity.render(options);
    }

    if (this.node.mask) {
      options.ctx.closeMask();
    }

    if (root) {
      options.ctx.play();
    }
  }

  renderHitbox(options, root = true) {
    let color;
    let hash;
    let name;
    if (root) {
      hash = {};
      name = this.node.name || "root";
      color = this.node.name ? getHashColor(name) : getRandomColor();
    } else if (this.node.name) {
      hash = options.hash;
      name = this.node.name;
      color = getHashColor(name);
    } else {
      hash = options.hash;
      name = options.name;
      color = options.color;
    }

    const defaults = {
      lineWidth: 12,
      annoScale: 1,
      annoExtension: 5,
      annoHashLength: 5,
      annoOffset: 50,
      annoFormat: "DECIMAL",
      annoPrecision: 2,
      dimConversion: 1,
      fontSize: 12,
    };

    options = {
      ...defaults,
      ...options,
      ...this.node.style,
      lineDash: null,
      color,
      hash,
      name,
      fill: color,
      stroke: color,
      textColor: color,
      ctx: options.ctx,
      type: options.type,
    };

    if (root) {
      options.ctx = context(options.ctx, options.type);
    }

    if (this.node.mask) {
      this.node.mask.render(options);
    }

    this.node.children.forEach((child) => {
      child.renderHitbox(options, false);
    });

    if (this.node.entity) {
      let props;
      if (this.node.entity.renderHitbox) {
        props = this.node.entity.renderHitbox(options);
      } else {
        this.node.entity.render(options);
      }
      hash[color] = { ...hash[color], name, ...props };
    }

    if (this.node.mask) {
      options.ctx.closeMask();
    }

    if (root) {
      options.ctx.play();
    }

    return hash;
  }

  shift({ x = 0, y = 0 }) {
    this.node.shift = this.node.shift
      ? add(this.node.shift, { x, y })
      : { x, y };
    return this;
  }

  mask(...args) {
    this.node.mask = new Mask(...args);
    return this;
  }

  // Drawing features
  polyline(...args) {
    return featurize(this, "polyline", args);
  }

  circle(...args) {
    return featurize(this, "circle", args);
  }

  point(...args) {
    return featurize(this, "point", args);
  }

  polygon(...args) {
    return featurize(this, "polygon", args);
  }

  ngon(...args) {
    return featurize(this, "ngon", args);
  }

  mark(...args) {
    return featurize(this, "mark", args);
  }

  symbol(...args) {
    return featurize(this, "symbol", args);
  }

  pin(...args) {
    return featurize(this, "pin", args);
  }

  text(...args) {
    return featurize(this, "text", args);
  }

  image(...args) {
    return featurize(this, "image", args);
  }

  polyface(...args) {
    return featurize(this, "polyface", args);
  }

  rectangle(...args) {
    return featurize(this, "rectangle", args);
  }

  aligned_dim(...args) {
    return featurize(this, "aligned_dim", args);
  }

  diameter_dim(...args) {
    return featurize(this, "diameter_dim", args);
  }

  radius_dim(...args) {
    return featurize(this, "radius_dim", args);
  }
}

function featurize(t, name, args) {
  const entity = new entities[name](...args);
  const dwg = new Drawing();
  dwg.node.entity = entity;
  return t.add(dwg);
}

export default Drawing;
