<!-- <svelte:options strictprops={false} /> -->

<script>
  import { createEventDispatcher, tick } from "svelte";

  export let defaultValue;
  export let value = undefined;
  export let newRow = false;

  const dispatch = createEventDispatcher();

  let input;

  export const focus = () => {
    input.focus();
  };

  async function handleFocus() {
    if (value === undefined && defaultValue !== undefined) {
      value = defaultValue;
    }
    await tick();
    dispatch("touch");
  }

  function updateValue(e) {
    dispatch("update", e.target.checked);
  }
</script>

<div class="px-2">
  <input
    type="checkbox"
    bind:this={input}
    class="bg-amber-50"
    class:bg-amber-50={newRow}
    style="line-height: 100%;"
    on:focus={handleFocus}
    bind:checked={value}
    on:input={updateValue} />
</div>
