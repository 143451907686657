async function parseCSV(rawData) {
    const Papa = await import("papaparse");
    const enc = new TextDecoder("utf-8");
    const str = enc.decode(rawData);
    const { data, errors } = Papa.parse(str);

    if (data && !errors.length) {
        return data;
    }
    return null;
}

export default parseCSV;