import parseInteger from "./parse-integer.js";

function parsePosInteger(v, strict = false) {
  const result = parseInteger(v);
  if (strict && result <= 0) throw new Error("Value must be positive");
  if (result < 0) throw new Error("Value cannot be negative");
  return result;
}

export default parsePosInteger;
