import { add, scale } from "vector";
import transformPt from "../utils/transform-pt.js";

class Text {
  constructor(
    text,
    p,
    { rotation = 0, offset = { x: 0, y: 0 }, alignment = "center" } = {}
  ) {
    this.text = text;
    this.p = p;
    this.rotation = rotation;
    this.offset = offset;
    this.type = "text";
    this.alignment = alignment;
  }

  render(options) {
    const ctx = options.ctx;
    const annoScale = options.annoScale;
    const ctr = add(this.p, scale(this.offset, annoScale));
    ctx.style("fontSize", options.fontSize);
    ctx.style("font", "Menlo");
    ctx.style("annoScale", annoScale);
    const mt = ctx.measureText(this.text);
    const tha =
      ((mt.actualBoundingBoxAscent - mt.actualBoundingBoxDescent) / 2) *
      annoScale;

    // Draw text
    ctx.style("fill", options.textColor);
    ctx.style("textAlign", this.alignment);
    ctx.style("fontSize", options.fontSize);
    ctx.style("fontWeight", options.fontWeight);
    ctx.text(this.text || "", ctr.x, ctr.y - tha, this.rotation);
  }

  get bbox() {
    return {
      xmin: this.p.x,
      xmax: this.p.x,
      ymin: this.p.y,
      ymax: this.p.y,
    };
  }

  transform(matrix) {
    const ctr = transformPt(this.p, matrix);
    return new Text(this.text, ctr, {
      rotation: this.rotation,
      offset: this.offset,
      alignment: this.alignment,
    });
  }
}

export default Text;
