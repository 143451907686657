import transformPt from "../utils/transform-pt.js";

/**
 * @typedef ImageLike
 * @prop {number} width
 * @prop {number} height
 * @prop {src} string
 */

class Img {
  /**
   * @param {ImageLike} image
   * @param {{ x: number, y: number}} p
   * @param {number} width
   * @param {number} height
   * @param {*} [options]
   * @param {number} options.opacity
   * @param {number} options.format
   */
  constructor(image, p, width, height, { opacity = 1, format } = {}) {
    this.image = image;
    this.p = p;
    this.width = width;
    this.height = height;
    this.type = "image";
    this.opacity = opacity;
    this.format = format;
  }

  clone() {
    return new Img(this.image, this.p, this.width, this.height, {
      opacity: this.opacity,
      format: this.format
    });
  }

  render(options) {
    const ctx = options.ctx;
    ctx.image(
      this.image,
      this.p.x,
      this.p.y,
      this.width,
      this.height,
      this.opacity,
      this.format,
    );
  }

  get bbox() {
    return {
      xmin: this.p.x,
      xmax: this.p.x + (this.width || 0),
      ymin: this.p.y - (this.height || 0),
      ymax: this.p.y,
    };
  }

  transform(matrix) {
    const pt = transformPt(this.p, matrix);
    return new Img(this.image, pt, this.width, this.height, {
      opacity: this.opacity,
      format: this.format,
    });
  }
}

export default Img;
