import { genevaParams } from "@local/extensions/fabrications/geneva";
import { nextFabMark } from "@local/extensions/identifiers/mark.js";
import type { PlainDimension } from "dimtext";

interface FabricationBase {
  id: string;
  mark: string;
  name: string;
  notes: string;
  position: "corner" | "edge";
}

interface StaticFabrication extends FabricationBase {
  type: "static",
  path: object,
};

interface GenericFabrication extends FabricationBase {
  type: "generic",
  drawing?: string,
}

interface FunctionFabrication extends FabricationBase {
  type: "function",
  func: "geneva" | "patch" | "cutout"
  params: Array<PlainDimension>
}

type Fabrication =
  | StaticFabrication
  | GenericFabrication
  | FunctionFabrication;


function createFabrication(fabrications = []) {
  const mark = nextFabMark(fabrications);
  const regex = /([0-9]+)$/;
  let name;
  if (mark.match(regex)) {
    const num = parseInt(mark.match(regex)[1], 10);
    name = `Fabrication ${num}`;
  } else {
    name = `Fabrication ${mark}`;
  }

  return {
    id: crypto.randomUUID(),
    mark,
    name,
    type: "function",
    func: "geneva",
    position: "edge",
    notes: null,
    params: genevaParams.map((p) => p.default),
  };
}

export { createFabrication }
export type { Fabrication }
