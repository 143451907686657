import get from "lodash/get.js";
import partition from "lodash/partition.js";
import { sortFunctions } from "../utilities/sort.js";
import { objectify, hashify } from "overline";

function sortableList(arr, key = "id") {
  const base = {
    indices: {},
    order: [],
    [Symbol.iterator]() {
      return (function* (obj) {
        for (const id of obj.order) {
          yield obj[id];
        }
      })(this);
    },
  };

  return arr.reduce((o, el, index) => {
    o.order.push(el[key]);
    o.indices[el[key]] = index;
    o[el[key]] = el;
    return o;
  }, base);
}

function sortArrayBy(arr = [], order = [], prop = "id") {
  const ohash = hashify(order);
  const unordered = arr.filter((item) => !ohash[item[prop]]);
  const chash = objectify(arr, prop);
  return order
    .map((id) => chash[id])
    .filter((item) => item)
    .concat(unordered);
}

function orderedList(obj) {
  return obj.order.map((id) => obj[id]);
}

function filtered(obj, filter, fields = ["name"]) {
  const str = filter.toLowerCase();
  const filtered = { order: [], indices: {} };
  let fIndex = 0;

  obj?.order.forEach((id) => {
    const record = obj[id];
    const f = fields.map((field) => (get(record, field) || "").toLowerCase());

    if (f.some((field) => field.includes(str))) {
      filtered[id] = obj[id];
      filtered.order.push(id);
      filtered.indices[id] = fIndex;
      fIndex += 1;
    }
  });

  return filtered;
}

function selected(obj, selectedIds = []) {
  const selected = { order: [], indices: {} };
  let sIndex = 0;

  selectedIds.forEach((id) => {
    if (!selected[id]) {
      selected[id] = obj[id];
      selected.order.push(id);
      selected.indices[id] = sIndex;
      sIndex += 1;
    }
  });

  return selected;
}

function addToSortableList(obj, ...items) {
  if (!obj.indices) {
    obj.indices = {};
  }

  items.forEach((item) => {
    let exists = obj[item.id];
    obj[item.id] = item;
    if (!exists) {
      obj.order.push(item.id);
    }
    obj.indices[item.id] = obj.order.length - 1;
  });
}

function removeFromSortableList(obj, ...ids) {
  if (!obj.indices) {
    obj.indices = {};
  }

  const idHash = ids.reduce((h, id) => {
    h[id] = true;
    return h;
  }, {});

  obj.order = obj.order.filter((id) => !idHash[id]);

  ids.forEach((id) => {
    delete obj[id];
    delete obj.indices[id];
  });
}

function sortList(
  obj,
  {
    property = "created_at",
    direction = "asc",
    sortFunction = null,
    nullFirst = false,
    ignore = null,
  } = {},
) {
  let order;
  let unsorted;

  if (ignore) {
    [unsorted, order] = partition(obj.order, (id) => {
      const record = obj[id];
      return record[ignore.prop] === ignore.value;
    });
  } else {
    order = obj.order;
  }

  order.sort((a, b) => {
    const objA = obj[a];
    const objB = obj[b];

    if (typeof sortFunction === "function") return sortFunction(objA, objB);

    const aVal = get(objA, property);
    const bVal = get(objB, property);

    const propComponents = property.split(".");

    let func;
    if (typeof sortFunction === "string") {
      func = sortFunctions[sortFunction];
    } else if (propComponents[1] === "custom_column_data") {
      func = sortFunctions.alphabetic;
    } else {
      func = sortFunctions[property] || sortFunctions.alphabetic;
    }

    if (nullFirst) {
      if (aVal === null && bVal === null) {
        return 0;
      } else if (aVal === null) {
        return direction === "desc" ? 1 : -1;
      } else if (bVal === null) {
        return direction === "desc" ? -1 : 1;
      } else {
        return func(aVal, bVal);
      }
    } else {
      return func(aVal, bVal);
    }
  });

  if (direction === "desc") {
    order.reverse();
  }

  if (ignore) {
    obj.order = [...order, ...unsorted];
  } else {
    obj.order = order;
  }

  obj.indices = obj.order.reduce((h, id, index) => {
    h[id] = index;
    return h;
  }, {});
}

export {
  sortableList,
  sortArrayBy,
  orderedList,
  filtered,
  selected,
  addToSortableList,
  removeFromSortableList,
  sortList,
};
