export const realThreshold = 1e-8;

export function almostEqual(a, b) {
  return a - b < realThreshold && a - b > -realThreshold;
}

export function gt(a, b) {
  return a - b > realThreshold;
}

export function ge(a, b) {
  return a - b > -realThreshold;
}

export function lt(a, b) {
  return a - b < -realThreshold;
}

export function le(a, b) {
  return a - b < realThreshold;
}

export function almostEqualPt(a, b) {
  return almostEqual(a.x, b.x) && almostEqual(a.y, b.y);
}
