<svelte:options strictprops={false} />

<script>
  export let readOnly = false;
  export let value = "";
  export let disabled;
</script>

<div class="p-1 flex items-center justify-center" class:text-gray-500={disabled}>
  <input
    type="checkbox"
    data-clickthrough="clickthrough"
    bind:checked={value}
    disabled={disabled || readOnly} />
</div>
