function contiguousRanges(integers) {
  if (integers.length === 0) return [];

  const sorted = [...integers].sort((a, b) => a - b);
  const first = sorted.shift();
  const ranges = [[first, first]];
  sorted.forEach((integer) => {
    const lastRange = ranges[ranges.length - 1];
    const lastInt = lastRange[1];
    if (integer === lastInt + 1) {
      lastRange[1] = integer;
    } else {
      ranges.push([integer, integer]);
    }
  });

  return ranges;
}

export default contiguousRanges;
