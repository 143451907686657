// transformation matrix following canvas matrix convention
function transformPt(pt, [a, b, c, d, e, f]) {
  const newPt = {
    x: pt.x * a + pt.y * c + e,
    y: pt.x * b + pt.y * d + f,
  };

  // The below only holds for uniform scaling--if we wanted to truly support
  // non-uniform scaling, we'd need to convert circular arcs to elliptical ones.
  if (pt.bulge) {
    let bulge = pt.bulge;
    if (a * d < 0) {
      newPt.bulge = -bulge;
    } else {
      newPt.bulge = bulge;
    }
  }

  return newPt;
}

export default transformPt;
