import { Drawing } from "drawing";
import { DimText } from "dimtext";
import { sagittaBulge } from "../geometry/bulge-sagitta.js";

const dt = new DimText();

const w = dt.parse("6 5/16").value;
const d = dt.parse("1 7/16").value;
const cw = dt.parse("3 5/8").value;
const fr = dt.parse("3 1/8").value;
const sfr = dt.parse("2 3/16").value;
const hho = dt.parse("5 13/16").value;
const hd = dt.parse("1 3/8").value;
const hdiam = dt.parse("13/16").value;

export function patch(width = w, depth = d) {
  const wn = width.toNumber("inches");
  const cwn = cw.toNumber("inches");
  const dn = depth.toNumber("inches");
  const hhon = hho.toNumber("inches");
  const hdn = hd.toNumber("inches");
  const hdiamn = hdiam.toNumber("inches");

  const sagf = -0.25;
  const sagsf = 0.125;

  const a = { x: wn - cwn, y: dn };
  const b = { x: a.x + 2.25, y: (0.5 / 1.4375) * dn };
  const c = { x: wn, y: 0 };
  const bulgef = sagittaBulge(a, b, sagf);
  const bulgesf = sagittaBulge(b, c, sagsf);

  const ha = { x: hhon - hdiamn / 2, y: hdn, bulge: -1 };
  const hb = { x: hhon + hdiamn / 2, y: hdn, bulge: -1 };

  return {
    path: [
      { x: 0, y: dn },
      a,
      { ...b, bulge: bulgef },
      { ...c, bulge: bulgesf },
    ],
    voids: [{ path: [ha, hb] }],
    dims: () => {
      return [
        {
          id: "width",
          dim: new Drawing().aligned_dim(
            { x: 0, y: 0 },
            { x: wn, y: 0 },
            { tier: -1 }
          ),
        },
        {
          id: "depth",
          dim: new Drawing().aligned_dim({ x: 0, y: 0 }, { x: 0, y: dn }),
        },
      ];
    },
  };
}

export const patchParams = [
  {
    label: "Width",
    default: w,
    id: "width",
  },
  {
    label: "Depth",
    default: d,
    id: "depth",
  },
];
