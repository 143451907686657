import { almostEqualPt } from "overline";
import { arcBulge } from "../utils/arc-bulge.js";

const pts = {
  M: (params) => [params[0], params[1]],
  L: (params) => [params[0], params[1]],
  A: (params) => [params[5], params[6]],
  Z: (params, st) => [...st],
};

const draw = {
  M: (shape, pen, cmd) => {
    const loop = shape.loops[pen.loop];
    pen.loopStart = [...cmd.params];
    if (loop.segments.length > 0) {
      pen.loop += 1;
      shape.loops.push({ closed: false, segments: [] });
    }
  },
  L: (shape, pen, cmd) => {
    const loop = shape.loops[pen.loop];

    loop.segments.push({
      start: { x: pen.point[0], y: pen.point[1] },
      end: { x: cmd.params[0], y: cmd.params[1] },
    });
  },
  A: (shape, pen, cmd) => {
    const loop = shape.loops[pen.loop];

    try {
      const [r, , , laf, sf, x, y] = cmd.params;

      const a = { x: pen.point[0], y: pen.point[1] };
      const b = { x, y };
      const bulge = arcBulge(a, b, r, laf, sf);

      loop.segments.push({
        start: { x: pen.point[0], y: pen.point[1] },
        end: { x, y },
        bulge,
      });
    } catch (_) {
      loop.segments.push({
        start: { x: pen.point[0], y: pen.point[1] },
        end: { x: cmd.params[5], y: cmd.params[6] },
      });
    }
  },
  Z: (shape, pen) => {
    const loop = shape.loops[pen.loop];
    const start = { x: pen.point[0], y: pen.point[1] };
    const end = { x: pen.loopStart[0], y: pen.loopStart[1] };

    if (!almostEqualPt(start, end)) {
      loop.segments.push({ start, end });
    }

    loop.closed = true;
    pen.loop += 1;
  },
};

const play = {
  path(ctx, el) {
    ctx.entities.push({ type: "shape", loops: [], layer: el.layer });
    const shape = ctx.entities[ctx.entities.length - 1];

    const pen = {
      loop: 0,
      point: [0, 0],
      loopStart: [0, 0],
    };

    el.commands.forEach((cmd) => {
      if (!shape.loops[pen.loop]) {
        shape.loops.push({ closed: false, segments: [] });
      }

      draw[cmd.command](shape, pen, cmd);
      pen.point = pts[cmd.command](cmd.params, pen.loopStart);
    });
  },

  circle(ctx, el) {
    const { x, y, r } = el;
    ctx.entities.push({
      type: "circle",
      center: { x, y },
      radius: r,
      layer: el.layer,
    });
  },

  text(ctx, el) {},
};

export default {
  measureText(ctx, text, styles) {
    // const fs =

    return {
      width: 1,
      actualBoundingBoxAscent: 1,
      actualBoundingBoxDescent: 1,
    };
  },

  play(ctx, stack) {
    if (!ctx.entities) {
      ctx.entities = [];
    }

    stack.forEach((el) => play[el.type](ctx, el));
  },
};
