<script>
  import { onMount, createEventDispatcher } from "svelte";
  import { DimText } from "dimtext";
  import get from "lodash/get";
  import set from "lodash/set";
  import isEqual from "lodash/isEqual";
  import cloneDeep from "lodash/cloneDeep";
  import { roundRealThreshold } from "@local/extensions/parsers/round-real-threshold.js";
  import Polyface from "@local/extensions/geometry/polyface.js";
  import { createFreeShape } from "@local/lamina-core";
  import isRectangle from "@local/extensions/geometry/is-rectangle.js";

  export let props;
  export let job;
  export let item;
  export let validator = (v) => true;
  export let settings;

  const dispatch = createEventDispatcher();

  let input;
  let inputContents;
  let destroying = false;

  $: dt = new DimText({ defaultUnit: settings.display_unit });
  $: inputPosition = `left:${props.pt.x}px;top:${props.pt.y}px`;
  $: dimType = props.dim && props.dim.callback ? "callback" : "prop";
  $: value =
    dimType === "callback"
      ? props.path
        ? get(item.data, props.path)
        : props.dim.value
      : get(item, props.id);
  $: inputValue = typeof value === "number" ? parseNumber(value) : value.toString();
  $: update(item);
  $: valid = validate(inputContents);

  function update(item) {
    inputContents = inputValue;
  }

  function parseNumber(num) {
    let n = settings.display_unit === "inches" ? num : num * 25.4;
    const str = roundRealThreshold(n).toString();
    const dim = dt.parse(str);
    return dim.value.toString();
  }

  function validate(v) {
    const result = dt.parse(v);

    if (!result.ok) return false;

    try {
      return validator(result.value);
    } catch (err) {
      return false;
    }
  }

  function updateSize(tempItem) {
    const pf = new Polyface(tempItem, $job.data.fabrications);
    const bbox = pf.bbox;
    const w = bbox.width - item.cache.width_offset_in;
    const h = bbox.height - item.cache.height_offset_in;

    const wdu = settings.display_unit === "inches" ? w : w * 25.4;
    const hdu = settings.display_unit === "inches" ? h : h * 25.4;

    tempItem.width = dt.parse(wdu.toString()).value;
    tempItem.height = dt.parse(hdu.toString()).value;

    if (isRectangle(tempItem.shape)) {
      const { xmin, ymin } = pf.bbox;
      tempItem.rectangle_offset = { x: xmin, y: ymin };
      tempItem.shape = { type: "rect" };
    }
  }

  function handleInput() {
    let val = inputContents;

    try {
      const result = dt.parse(val);
      if (!result.ok) throw result.err;

      if (dimType === "callback") {
        // The underlying value is a dimtext
        let updates;
        if (props.type === "dimonly") {
          if (valid && !isEqual(result.value, value)) {
            job.updateItem(item.id, {
              [props.id]: result.value,
            });
          }
        } else if (props.type === "dtdim") {
          if (valid && !isEqual(result.value, value)) updates = props.dim.callback(result.value);
          // Otherwise, it is a number
        } else {
          const converted = result.value.toNumber("inches");
          if (valid && value !== converted) updates = props.dim.callback(converted);
        }

        if (updates) {
          const data = cloneDeep(item.data);
          const shape = createFreeShape(item);
          const rectangle_offset = cloneDeep(item.rectangle_offset);
          const tempItem = { data, shape, rectangle_offset };
          updates.forEach((u) => {
            set(tempItem, u.path, u.value);
          });
          updateSize(tempItem);
          job.updateItem(item.id, {
            rectangle_offset: tempItem.rectangle_offset,
            data: tempItem.data,
            shape: tempItem.shape,
            width: tempItem.width,
            height: tempItem.height,
          });
        }
      } else {
        if (value.toString() !== result.value.toString() && valid) {
          job.updateItem(item.id, props.id, result.value);
        }
      }
    } catch (err) {
      console.log(err);
    }
  }

  function keydown(e) {
    if (e.key === "Enter") {
      destroying = true;
      handleInput();
      dispatch("stopEditing", props);
    }
  }

  async function blur() {
    if (!destroying) {
      handleInput();
    }
    dispatch("stopEditing");
  }

  onMount(() => {
    input.select();
  });
</script>

<div class="absolute rounded bg-white drop-shadow-lg text-xs font-mono" style={inputPosition}>
  <input
    class="text-center"
    bind:this={input}
    bind:value={inputContents}
    size={Math.max(inputContents.length, 2)}
    on:keydown={keydown}
    on:blur={blur} />
</div>

<style lang="scss">
  div {
    transform: translate(-50%, -50%);
  }

  input {
    min-width: 10px;
    max-width: 100%;
    margin: 0;
    padding: 0.25rem;
  }
</style>
