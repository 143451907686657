const numericRegex = /^(.*?)([0-9]+)$/;
const alphabeticRegex = /^[A-Z]+$/;

function incrementMarkCopy(name = "", names = []) {
  const match = name.match(/^(.+)\s*\([0-9]+\)$/);
  const n = match ? match[1].trim() : name.trim();

  const regex = new RegExp("^" + n + "\\s*\\(([0-9]+)\\)\\s*$");
  const numbers = names
    .filter((m) => m && m.match(regex))
    .map((m) => parseInt(m.match(regex)[1]));

  const max = Math.max(...numbers, 1);
  return `${n} (${max + 1})`;
}

function incrementAlphabetical(c) {
  var u = c.toUpperCase();
  if (same(u, "Z")) {
    var txt = "";
    var i = u.length;
    while (i--) {
      txt += "A";
    }
    return txt + "A";
  } else {
    var p = "";
    var q = "";
    if (u.length > 1) {
      p = u.substring(0, u.length - 1);
      q = String.fromCharCode(p.slice(-1).charCodeAt(0));
    }
    var l = u.slice(-1).charCodeAt(0);
    var z = nextLetter(l);
    if (z === "A") {
      return p.slice(0, -1) + nextLetter(q.slice(-1).charCodeAt(0)) + z;
    } else {
      return p + z;
    }
  }
}

function nextLetter(l) {
  if (l < 90) {
    return String.fromCharCode(l + 1);
  } else {
    return "A";
  }
}

function same(str, char) {
  var i = str.length;
  while (i--) {
    if (str[i] !== char) {
      return false;
    }
  }
  return true;
}

function compareMarks(a, b) {
  if (a.length < b.length) return -1;
  if (a.length > b.length) return 1;
  return a.localeCompare(b);
}

function compareNumericMarks(a, b) {
  const amatch = a.match(numericRegex);
  const bmatch = b.match(numericRegex);

  if (!amatch && bmatch) return -1;
  if (amatch && !bmatch) return 1;

  const aprefix = amatch[1];
  const bprefix = bmatch[1];

  if (aprefix !== bprefix) return compareMarks(aprefix, bprefix);

  const anum = parseInt(amatch[2], 10);
  const bnum = parseInt(bmatch[2], 10);

  return anum - bnum;
}

function incrementNumeric(mark) {
  const match = mark.match(numericRegex);
  if (!match) return "1";
  const prefix = match[1];

  // Check whether number is padded with zeros
  const num = parseInt(match[2], 10);
  const paddingLen = match[2].length - (num + 1).toString().length;
  const padding = paddingLen > 0 ? "0".repeat(paddingLen) : "";
  return `${prefix}${padding}${num + 1}`;
}

function incrementMark(mark) {
  if (mark.match(numericRegex)) {
    return incrementNumeric(mark);
  }

  if (mark.match(alphabeticRegex)) {
    return incrementAlphabetical(mark);
  }

  return "A";
}

function nextMark(list) {
  let mark = "";

  // Find most recent item that is either numeric or alphabetical
  let mostRecent;
  list.forEach((item) => {
    if (
      !mostRecent ||
      new Date(item.updated_at) > new Date(mostRecent.updated_at)
    ) {
      if (
        item.mark &&
        (item.mark.match(numericRegex) || item.mark.match(alphabeticRegex))
      ) {
        mostRecent = item;
      }
    }
  });

  const sortStyle = mostRecent?.mark?.match(numericRegex)
    ? "numeric"
    : "alphabetic";

  if (sortStyle === "alphabetic") {
    list.forEach((item) => {
      if (item.mark && item.mark.match(alphabeticRegex)) {
        if (compareMarks(mark, item.mark) < 0) mark = item.mark;
      }
    });
  } else {
    list.forEach((item) => {
      if (item.mark && item.mark.match(numericRegex)) {
        if (compareNumericMarks(mark, item.mark) < 0) mark = item.mark;
      }
    });
  }

  return incrementMark(mark);
}

function nextTypeMark(list) {
  if (
    list.some(
      (type) =>
        type.mark &&
        (type.mark.match(numericRegex) || type.mark.match(alphabeticRegex)),
    )
  ) {
    return nextMark(list);
  }

  return "GL-1";
}

function nextFabMark(list) {
  if (
    list.some(
      (type) =>
        type.mark &&
        (type.mark.match(numericRegex) || type.mark.match(alphabeticRegex)),
    )
  ) {
    return nextMark(list);
  }

  return "F-1";
}

export {
  incrementMark,
  incrementMarkCopy,
  compareMarks,
  nextMark,
  nextTypeMark,
  nextFabMark,
};
