export function stretchYInclusive(dy, shape, my) {
  return shape.reduce(
    (list, loop, l) => [
      ...list,
      ...loop
        .map((v, i) => i)
        .filter((i) => loop[i].y >= my)
        .map((i) => ({
          path: `shape.vertices.${l}.${i}.y`,
          value: loop[i].y + dy,
        })),
    ],
    [],
  );
}

export function stretchY(dy, shape, my) {
  return shape.reduce(
    (list, loop, l) => [
      ...list,
      ...loop
        .map((v, i) => i)
        .filter((i) => loop[i].y > my)
        .map((i) => ({
          path: `shape.vertices.${l}.${i}.y`,
          value: loop[i].y + dy,
        })),
    ],
    [],
  );
}

export function stretchNegY(dy, shape, my) {
  return shape.reduce(
    (list, loop, l) => [
      ...list,
      ...loop
        .map((v, i) => i)
        .filter((i) => loop[i].y < my)
        .map((i) => ({
          path: `shape.vertices.${l}.${i}.y`,
          value: loop[i].y + dy,
        })),
    ],
    [],
  );
}

export function stretchXInclusive(dx, shape, mx) {
  return shape.reduce(
    (list, loop, l) => [
      ...list,
      ...loop
        .map((v, i) => i)
        .filter((i) => loop[i].x >= mx)
        .map((i) => ({
          path: `shape.vertices.${l}.${i}.x`,
          value: loop[i].x + dx,
        })),
    ],
    [],
  );
}

export function stretchX(dx, shape, mx) {
  return shape.reduce(
    (list, loop, l) => [
      ...list,
      ...loop
        .map((v, i) => i)
        .filter((i) => loop[i].x > mx)
        .map((i) => ({
          path: `shape.vertices.${l}.${i}.x`,
          value: loop[i].x + dx,
        })),
    ],
    [],
  );
}

export function stretchNegX(dx, shape, mx) {
  return shape.reduce(
    (list, loop, l) => [
      ...list,
      ...loop
        .map((v, i) => i)
        .filter((i) => loop[i].x < mx)
        .map((i) => ({
          path: `shape.vertices.${l}.${i}.x`,
          value: loop[i].x + dx,
        })),
    ],
    [],
  );
}
