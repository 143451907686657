import MultiMap from "mnemonist/multi-map.js";

/**
 *
 * @param {Array} data
 */
function summarizeEdgeTreatment(data, index) {
  const base = data[0];

  if (data.every((item) => item === base)) {
    return index[base]?.name || " ";
  } else {
    const summary = new MultiMap();
    data.forEach((value, ix) => {
      summary.set(value, ix);
    });

    let result = [];
    for (const assoc of summary.associations()) {
      const [value, indices] = assoc;
      if (value) {
        result.push({
          indicies: indices.map((i) => i++),
          label: index[value]?.name,
        });
      }
    }
    return result;
  }
}

function formatEdgeTreatment(
  data,
  index,
  options = { delimiter: "; ", html: false }
) {
  const { delimiter, html } = options;
  const summary = summarizeEdgeTreatment(data, index);
  if (Array.isArray(summary)) {
    return "See drawing";
    /*
    const entries = summary.map((item) => {
      const indicesString = item.indicies.join(",");
      const infix = html ? "&nbsp;" : " ";
      return `(${indicesString})${infix}${item.label}`;
    });
    return entries.join(delimiter);
    */
  }
  return summary;
}

/**
 *
 * @param {Array} items
 * @returns {boolean}
 */
function hasEdgeTreatments(items) {
  return items.some((item) => {
    /** @type {Array} */
    const edgeTreatment = item?.data?.fabrications?.edge_treatment;
    return (
      edgeTreatment &&
      edgeTreatment.length > 0 &&
      edgeTreatment.some((item) => item !== null)
    );
  });
}

function hasNonUniformEdgeTreatment(data) {
  const [base, ...rest] = data?.fabrications?.edge_treatment ?? [];

  if (rest.some((item) => item !== base)) {
    return true;
  }
  return false;
}

const edgeTreatmentColumn = (index) => ({
  label: "Edge treatment",
  prop: "data.fabrications.edge_treatment",
  type: "string",
  displayFilter: hasEdgeTreatments,
  formatter(data) {
    if (data) {
      return formatEdgeTreatment(data, index);
    }
    return " ";
  },
  formatterHTML(data) {
    if (data) {
      return formatEdgeTreatment(data, index, { delimiter: "; ", html: true });
    }
    return " ";
  },
});

export default formatEdgeTreatment;
export { edgeTreatmentColumn, hasEdgeTreatments, hasNonUniformEdgeTreatment };
