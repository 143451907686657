import { dist } from "vector";
import { almostEqual } from "overline";

export function arcBulge(a, b, r, laf, sf) {
  const len = dist(a, b) / 2;

  const sqrt = r ** 2 - len ** 2;

  let sag;
  if (almostEqual(sqrt, 0)) {
    sag = r;
  } else {
    sag = laf === "1" ? r + Math.sqrt(sqrt) : r - Math.sqrt(sqrt);
  }

  return sf === "1" ? sag / len : -sag / len;
}
