function isRectangle(shape) {
  if (shape.vertices.length !== 1) return false;
  const v = shape.vertices[0];
  if (v.length !== 4) return false;
  if (v.some((vertex) => vertex.bulge || vertex.fillet)) return false;

  const minX = Math.min(...v.map((v) => v.x));
  const minY = Math.min(...v.map((v) => v.y));

  const blIndex = v.findIndex(
    (vertex) => vertex.x === minX && vertex.y === minY
  );

  if (blIndex === -1) return false;

  const a = v[blIndex];
  const b = v[(blIndex + 1) % 4];
  const c = v[(blIndex + 2) % 4];
  const d = v[(blIndex + 3) % 4];

  return a.x === d.x && a.y === b.y && b.x === c.x && c.y === d.y;
}

export default isRectangle;
