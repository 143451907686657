import { dist } from "vector";
import { Drawing } from "drawing";
import { DimText, Dimension, Quantity } from "dimtext";
import { sagittaBulge } from "../geometry/bulge-sagitta.js";

const dt = new DimText();

const w = dt.parse("2 1/2").value;
const d = dt.parse("1 3/4").value;
const cw = dt.parse("2 1/4").value;
const cd = dt.parse("1 1/2").value;
const diam = dt.parse("5/8").value;

export function geneva(
  width = w,
  depth = d,
  centerWidth = cw,
  centerDepth = cd,
  diameter = diam
) {
  const wn = width.toNumber("inches");
  const dn = depth.toNumber("inches");
  const cwn = centerWidth.toNumber("inches");
  const cdn = centerDepth.toNumber("inches");
  const diamn = diameter.toNumber("inches");

  const r = diamn / 2;
  const x = (wn - cwn) / 2;
  const y = dn - cdn;
  const l = Math.sqrt(r * r - x * x);
  const t = Math.sqrt(r * r - y * y);
  const tx = cwn / 2 - t;

  const a = { x: -wn / 2, y: 0 };
  const b = { x: -wn / 2, y: cdn - l };
  const c = { x: -tx, y: dn };
  const d = { x: tx, y: dn };
  const e = { x: wn / 2, y: cdn - l };
  const f = { x: wn / 2, y: 0 };

  const len = dist(b, c) / 2;
  const sag = r + Math.sqrt(r ** 2 - len ** 2);
  const bulge = -sagittaBulge(b, c, sag);

  return {
    path: [a, b, { ...c, bulge }, d, { ...e, bulge }, f],
    voids: [],
    dims: () => {
      return [
        {
          id: "width",
          dim: new Drawing().aligned_dim(a, f, { tier: -2 }),
        },
        {
          id: "depth",
          dim: new Drawing().aligned_dim(a, { x: a.x, y: c.y }, { tier: 2 }),
        },
        {
          id: "ctr-width",
          dim: new Drawing().aligned_dim(
            { x: -cwn / 2, y: 0 },
            { x: cwn / 2, y: 0 },
            { tier: -1 }
          ),
        },
        {
          id: "ctr-depth",
          dim: new Drawing().aligned_dim(a, { x: a.x, y: cdn }),
        },
        {
          id: "diameter",
          dim: new Drawing().diameter_dim({ x: cwn / 2, y: cdn }, diamn),
        },
      ];
    },
  };
}

export const genevaParams = [
  {
    label: "Width",
    id: "width",
    default: w,
    update: (params, val) => {
      const v = val.toNumber("inches");
      const ov = params[0].toNumber("inches");
      const ocw = params[2].toNumber("inches");
      const diff = ov - ocw;
      params[0] = val;
      params[2] = new Dimension(new Quantity(v - diff, "inches"));
    },
  },
  {
    label: "Depth",
    id: "depth",
    default: d,
    update: (params, val) => {
      const v = val.toNumber("inches");
      const ov = params[1].toNumber("inches");
      const ocd = params[3].toNumber("inches");
      const diff = ov - ocd;
      params[1] = val;
      params[3] = new Dimension(new Quantity(v - diff, "inches"));
    },
  },
  { label: "Ctr. width", id: "ctr-width", default: cw },
  { label: "Ctr. depth", id: "ctr-depth", default: cd },
  { label: "Diameter", id: "diameter", default: diam },
];
