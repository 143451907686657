<script>
  /** @typedef {import('../../types/shynet').Shynet} Shynet */

  import { importWithContext } from "script-loaders";
  import { getLogger } from "@diagraphics/logging";

  const log = getLogger(Symbol("download-job-bundle"), "DEBUG", true);

  /** @type {string} */
  export let url;

  /** @type { string | null } */
  export let serviceID = null;

  /** @type { string | null }*/
  export let userID = null;

  export let location;

  /** @type {Shynet} */
  let shynetClient;

  /** @type {string} */
  let href;

  const clientContext = {
    window: {
      // Ensure client never sets an event listener, so we can control the client lifecycle
      addEventListener: () => {
        log.trace("[shynet ctx] Call `window.addEventListener` on window");
      },
      performance: globalThis.performance,
      location: {
        get href() {
          log.trace("[shynet ctx] Propget `window.location`");
          return href;
        },
      },
    },
  };

  function setLocation(location) {
    if (shynetClient && location) {
      href = location.href;
      shynetClient.newPageLoad();
    }
  }

  async function getClient(url, serviceID, userID) {
    try {
      if (url && serviceID) {
        const endpoint = userID ? `${serviceID}/${userID}` : serviceID;
        const src = `${url}/ingress/${endpoint}/script.js`;
        const { Shynet } = await importWithContext(src, ["Shynet"], clientContext);
        return Shynet;
      } else {
        log.info('[shynet] URL and/or serviceID are not set.');
        return null;
      }
    } catch (e) {
      bugsnagNotify(e);
      return null;
    }
  }

  function validClient(client) {
    return client !== null && typeof client === "object" && typeof client.newPageLoad === "function";
  }

  /**
   * @param {Shynet} client
   */
  function startClient(client) {
    if (validClient(client)) {
      client.newPageLoad();
    } else {
      log.info("[shynet] Attempted to start invalid client");
    }
  }

  /**
   * @param {Shynet} client
   */
  function stopClient(client) {
    const { heartbeatTaskId } = client || {};
    if (heartbeatTaskId) {
      clearInterval(heartbeatTaskId);
    }
  }

  function bugsnagNotify(message) {
    const { notify } = globalThis.bugsnag || {};
    if (typeof notify === "function") {
      const err = message instanceof Error ? message : new Error(message);
      notify(err);
    }
  }

  /**
   * @param {Shynet} client
   */
  function switchClient(newClient) {
    if (validClient(newClient)) {
      if (newClient !== shynetClient) {
        stopClient(shynetClient);
        startClient(newClient);
        shynetClient = newClient;
      }
    } else {
      log.info("[shynet] Not switching client. No new client was provided or provided client was not valid.");
    }
  }

  $: setLocation(location);
  $: getClient(url, serviceID, userID).then(switchClient);
</script>
