import { Dimension, Quantity } from "dimtext";
import { subtract, normalize, scale, rotate, add } from "vector";

export function edgeOffset(edge, reference) {
  const end = reference.start === "end" ? "start" : "end";

  const evec = normalize(subtract(edge[end], edge[reference.start]));
  const ovec =
    end === "start" ? rotate(evec, -Math.PI / 2) : rotate(evec, Math.PI / 2);
  const o = scale(ovec, reference.offset.toNumber("inches"));
  const point = add(edge[reference.start], o);

  return { point, evec, ovec };
}

export function edgeOffsetPoint(edge, reference) {
  const end = reference.start === "end" ? "start" : "end";

  const evec = normalize(subtract(edge[end], edge[reference.start]));
  const l = scale(evec, reference.length.toNumber("inches"));
  const ovec =
    end === "start" ? rotate(evec, -Math.PI / 2) : rotate(evec, Math.PI / 2);
  const o = scale(ovec, reference.offset.toNumber("inches"));
  const a = add(edge[reference.start], l);
  const center = add(a, o);

  return { center, evec, ovec };
}

export function edgeOffsetCenter(edge, reference) {
  const end = reference.start === "end" ? "start" : "end";

  const evec = normalize(subtract(edge[end], edge[reference.start]));
  const l = scale(evec, reference.length.toNumber("inches"));
  const ovec =
    end === "start" ? rotate(evec, -Math.PI / 2) : rotate(evec, Math.PI / 2);
  const o = scale(ovec, reference.offset.toNumber("inches"));
  const a = add(edge[reference.start], l);
  const center = add(a, o);

  const ldim = {
    type: "aligned_dim",
    start: edge[reference.start],
    end: a,
    tier: end === "end" ? -0.5 : 0.5,
    path: "reference.length",
    callback: (i) => (v) => [
      {
        path: `${i}.reference.length`,
        value: new Dimension(v),
      },
    ],
  };

  const odim = {
    type: "aligned_dim",
    start: a,
    end: center,
    tier: end === "end" ? -0.5 : 0.5,
    path: "reference.offset",
    callback: (i) => (v) => [
      {
        path: `${i}.reference.offset`,
        value: new Dimension(v),
      },
    ],
  };

  return { center, evec, ovec, dimensions: [ldim, odim] };
}

export function edgePoint(edge, reference) {
  const end = reference.start === "end" ? "start" : "end";

  const evec = normalize(subtract(edge[end], edge[reference.start]));
  const l = scale(evec, reference.length.toNumber("inches"));
  const a = add(edge[reference.start], l);

  return a;
}
