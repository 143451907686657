<script>
  import { createEventDispatcher } from "svelte";
  import removeEmptyDims from "src/extensions/remove-empty-dims.js";
  import SpinnerIcon from "src/assets/icons/spinner.svg";

  const dispatch = createEventDispatcher();

  export let data;

  let selectedSheet;

  let loading = false;

  function prev() {
    dispatch("prev", { multiSheet: false });
  }

  async function next() {
    loading = true;
    const source = await data.sheets[selectedSheet];
    dispatch("next", { source: removeEmptyDims(source) });
  }
</script>

<div class="space-y-4 flex flex-col overflow-hidden">
  <div class="space-y-2">
    <div>
      You have uploaded a spreadsheet with multiple sheets. Please select the sheet you would like to import.
    </div>
    <div class="select-container">
      <select bind:value={selectedSheet} name="select_sheet" size="4" class="w-full overflow-auto">
        {#each data.sheetNames as sheet}
          <option value={sheet}>{sheet}</option>
        {/each}
      </select>
    </div>
  </div>
  <div class="flex justify-end items-center space-x-2">
    <button class="btn w-32" on:click={prev}>Previous</button>
    <button
      class="btn btn-primary w-32 flex gap-2 items-center justify-center"
      on:click={next}
      disabled={loading || !selectedSheet}>
      {#if loading}
        <div class="animate-spin w-4 h-4">
          <SpinnerIcon />
        </div>
      {/if}
      <div>Next</div>
    </button>
  </div>
</div>

<style>
  .select-container {
    @apply border rounded overflow-hidden;
    min-width: 12rem;
    width: max-content;
  }
</style>
