import { Drawing } from "drawing";

const sqrt2 = Math.sqrt(2);
const w = 2;
const r = 0.5;

export function corner() {
  const bulge = 1 - sqrt2;

  const a = { x: 0, y: w };
  const b = { x: w - r, y: w };
  const c = { x: w, y: w - r, bulge };
  const d = { x: w, y: 0 };

  return {
    path: null,
    voids: [],
    dims: () => [],
    annotations: new Drawing()
      .polyline([a, b, c, d])
      .style({ stroke: "#333", fill: "transparent", lineDash: [3, 2] }),
  };
}
