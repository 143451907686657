export default function dimSettings(settings) {
  const displayUnit = settings.display_unit;
  const dimFormat =
    displayUnit === "inches" ? settings.dimension_format : "DECIMAL";
  const sddp = settings.decimal_precision;
  const sdfp = settings.fractional_precision;
  const dimPrecision =
    displayUnit === "inches"
      ? dimFormat === "DECIMAL"
        ? sddp
        : sdfp
      : settings.mm_precision;

  return { displayUnit, dimFormat, dimPrecision };
}
