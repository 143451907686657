// TODO: Add ambient type defs for import.meta.env
// @ts-nocheck

import bool from "normalize-bool";

function downgradeScheme(scheme) {
  if (scheme === "wss") return "ws";
  if (scheme === "https") return "http";
  return scheme;
}

const defaultOptions = {
  scheme: "https",
  base: "onrender.com",
};

/**
 * @param {string} url
 * @param {string} host
 * @param {string} local Port to use in local dev
 * @param {number} fallback Complete fallback URL
 * @param {{ scheme: string, base: string }} [options]
 * @returns {string}
 */
function resolveServiceURL(url, host, local, fallback, options) {
  let { scheme, base } = { ...defaultOptions, ...options };

  if (url) {
    return url;
  }
  if (host) {
    return `${scheme}://${host}.${base}`;
  }
  if (import.meta.env.DEV) {
    if (typeof local === "number") {
      scheme = downgradeScheme(scheme);
      return `${scheme}://localhost:${local}`;
    } else {
      return local;
    }
  }
  if (fallback) {
    return fallback;
  } else {
    console.error(`[env] No valid fallback URL provided for a service`);
  }
}

export const GIT_BRANCH = import.meta.env.VITE_GIT_BRANCH;
export const IS_PULL_REQUEST = import.meta.env.VITE_IS_PULL_REQUEST;
export const DEV = import.meta.env.DEV;

export const COOKIE_DOMAIN = import.meta.env.VITE_COOKIE_DOMAIN ?? ".localhost";
console.log("[env] COOKIE_DOMAIN=%s", COOKIE_DOMAIN);

/* Root Redirect */

export const ROOT_REDIRECT_URL = import.meta.env.VITE_ROOT_REDIRECT_URL;
export const ROOT_REDIRECT_AUTO = bool(import.meta.env.VITE_ROOT_REDIRECT_AUTO);

/* DXF */

export const DXF_URL = resolveServiceURL(
  import.meta.env.VITE_DXF_URL,
  import.meta.env.VITE_DXF_HOST,
  3003,
  "https://dxf.sh",
);

console.log("[env] DXF_URL=%s", DXF_URL);

/** Vandelay */

export const VANDELAY_URL = resolveServiceURL(
  import.meta.env.VITE_VANDELAY_URL,
  import.meta.env.VITE_VANDELAY_HOST,
  5005,
  "https://vandelay.shape.exchange",
);

console.log("[env] VANDELAY_URL=%s", VANDELAY_URL);

/** Corvelay */

export const CORVELAY_PUBLIC_URL = resolveServiceURL(
  import.meta.env.VITE_CORVELAY_URL,
  import.meta.env.VITE_CORVELAY_HOST,
  8686,
  "https://corvelay.shape.exchange",
  {
    base: bool(IS_PULL_REQUEST) ? "preview.diagraphics.dev" : "shape.exchange",
  },
);

console.log("[env] CORVELAY_PUBLIC_URL=%s", CORVELAY_PUBLIC_URL);

const corvelayPrivatePort = import.meta.env.VITE_CORVELAY_PRIVATE_PORT ?? 8685;
const corvelayPrivateHost = import.meta.env.VITE_CORVELAY_HOST ?? "localhost";

export const CORVELAY_PRIVATE_URL = `http://${corvelayPrivateHost}:${corvelayPrivatePort}`;

console.log("[env] CORVELAY_PRIVATE_URL=%s", CORVELAY_PRIVATE_URL);

/** Triops */

export const TRIOPS_URL = resolveServiceURL(
  import.meta.env.VITE_APP_TRIOPS_URL,
  import.meta.env.VITE_APP_TRIOPS_HOST,
  8181,
  "https://triops.onrender.com",
);

export const SHORTENER_URL = resolveServiceURL(
  import.meta.env.VITE_APP_SHORTENER_PREFIX,
  null,
  "http://localhost:3001/short",
  "https://shpx.io",
);

console.log("[env] SHORTENER_URL=%s", SHORTENER_URL);

export const SHYNET_ID = import.meta.env.VITE_SHYNET_ID;
export const SHYNET_URL = resolveServiceURL(
  import.meta.env.VITE_SHYNET_URL,
  null,
  9000,
  "https://analytics.diagraphics.com",
);

console.log("[env] SHYNET_URL=%s", SHYNET_URL);

function resolveWebsocketURL() {
  const suffix = "statusz";
  const prefix = resolveServiceURL(
    import.meta.env.VITE_WEBSOCKET_URL,
    import.meta.env.VITE_API_GATEWAY_HOST,
    3001,
    null,
    { scheme: "wss" },
  );

  const serviceID = import.meta.env.VITE_APP_BACKEND_SERVICE_ID;

  let path = serviceID ? [serviceID] : [];
  path.push(suffix);

  return `${prefix}/${path.join("/")}`;
}

export const WEBSOCKET_URL = resolveWebsocketURL();

console.log("[env] WEBSOCKET_URL=%s", WEBSOCKET_URL);

export const DXF_DEBUG = import.meta.env.DEV;
export const SUPABASE_URL = import.meta.env.VITE_APP_SUPABASE_URL;
export const FRATER_URL = import.meta.env.VITE_APP_FRATER_URL;
export const SUPABASE_ANON_KEY = import.meta.env.VITE_APP_SUPABASE_ANON_KEY;

export const BACKEND_SERVICE_ID = import.meta.env.VITE_APP_BACKEND_SERVICE_ID;
export const BACKEND_PREFIX = BACKEND_SERVICE_ID
  ? `/api/${BACKEND_SERVICE_ID}`
  : `/api`;

export const DXF_USE_R12 = import.meta.env.VITE_DXF_USE_R12 || false;

export const DEFAULT_LOG_LEVEL = DEV || IS_PULL_REQUEST ? "DEBUG" : "SILENT";

export const BUGSNAG_API_KEY = import.meta.env.VITE_APP_BUGSNAG_API_KEY;
export const BUGSNAG_RELEASE_STAGE = import.meta.env
  .VITE_APP_BUGSNAG_RELEASE_STAGE;
export const BUGSNAG_APP_VERSION = import.meta.env.VITE_APP_BUGSNAG_APP_VERSION;
export const MAPBOX_TOKEN = import.meta.env.VITE_APP_MAPBOX_TOKEN;
