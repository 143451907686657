const imports = new Map();

/**
 * @template {string} T
 * @template {T[]} A
 * @param {string} url
 * @param {A} capture
 * @param {object} context
 * @returns {Promise<Record<A[number], any>>}
 */
async function importWithContext(url, capture, context) {
  if (imports.has(url)) {
    return imports.get(url);
  }

  try {
    const res = await fetch(url, {
      headers: {
        "Content-Type": "text/javascript",
      },
    });

    if (res.ok) {
      const code = await res.text();

      const loader = Function(
        "context",
        `
            const { ${Object.keys(context).join(",")} } = context;
            ${code}
            return { ${capture.join(",")} }
        `,
      );

      try {
        const loaded = loader(context);

        imports.set(url, loaded);

        return loaded;
      } catch (cause) {
        throw new Error(`Error executing script fetched from ${url}.`, {
          cause,
        });
      }
    } else {
      throw new Error(
        `Error fetching script from ${url}. Server responded with ${res.status} ${res.statusText}.`,
      );
    }
  } catch (cause) {
    throw new Error(`Error fetching script from ${url}.`, { cause });
  }
}

export { importWithContext };
