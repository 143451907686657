import Polygon from "./polygon.js";

class Rectangle extends Polygon {
  constructor(x, y, width, height, radius = 0) {
    let pts;

    if (radius !== 0) {
      const bulge = 0.414213562373095;
      pts = [
        { x: x + radius, y, bulge },
        { x: x + width - radius, y },
        { x: x + width, y: y + radius, bulge },
        { x: x + width, y: y + height - radius },
        { x: x + width - radius, y: y + height, bulge },
        { x: x + radius, y: y + height },
        { x, y: y + height - radius, bulge },
        { x, y: y + radius },
      ];
    } else {
      pts = [
        { x, y },
        { x: x + width, y },
        { x: x + width, y: y + height },
        { x: x, y: y + height },
      ];
    }

    super(pts);
    this.type = "rectangle";
  }
}

export default Rectangle;
