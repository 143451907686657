import { angle, reverse } from "vector";

function alignmentPt(evec, reference, width, height) {
  const a = angle(reverse(evec));

  const q1 = Math.PI / 4;
  const q2 = (3 * Math.PI) / 4;
  const q3 = (-3 * Math.PI) / 4;
  const q4 = -Math.PI / 4;

  let off = reference.offset.toNumber("inches");
  const len = reference.length.toNumber("inches");

  let x = 0;
  let y = 0;

  // between q4 and q1
  //   + ref offset corresponds w/ bottom edge
  //   + ref length corresponds w/ right edge
  if (q4 <= a && a < q1) {
    x = len >= 0 ? -width : 0;
    if (reference.start === "start") {
      y = off >= 0 ? -height : 0;
    } else {
      y = off >= 0 ? 0 : -height;
    }
  }

  // between q1 and q2
  //   + ref offset corresponds w/ right edge
  //   + ref length corresponds w/ top edge
  else if (q1 <= a && a < q2) {
    if (reference.start === "start") {
      x = off >= 0 ? 0 : -width;
    } else {
      x = off >= 0 ? -width : 0;
    }
    y = len >= 0 ? -height : 0;
  }

  // between q2 and q3
  //   + ref offset corresponds w/ top edge
  //   + ref length corresponds w/ left edge
  else if (q2 <= a || a < q3) {
    x = len >= 0 ? 0 : -width;
    if (reference.start === "start") {
      y = off >= 0 ? 0 : -height;
    } else {
      y = off >= 0 ? -height : 0;
    }
  }

  // between q3 and q4
  //   + ref offset corresponds w/ left edge
  //   + ref length corresponds w/ bottom edge
  else {
    if (reference.start === "start") {
      x = off >= 0 ? -width : 0;
    } else {
      x = off >= 0 ? 0 : -width;
    }
    y = len >= 0 ? 0 : -height;
  }

  return { x, y };
}

export default alignmentPt;
