import { Drawing } from "drawing";

const sqrt2 = Math.sqrt(2);
const w = 3;
const dp = 2;
const r = 0.5;

export function edge() {
  const bulge = 1 - sqrt2;

  const a = { x: -w / 2, y: 0 };
  const b = { x: -w / 2, y: dp - r };
  const c = { x: -w / 2 + r, y: dp, bulge };
  const d = { x: w / 2 - r, y: dp };
  const e = { x: w / 2, y: dp - r, bulge };
  const f = { x: w / 2, y: 0 };

  return {
    path: null,
    voids: [],
    dims: () => [],
    annotations: new Drawing()
      .polyline([a, b, c, d, e, f])
      .style({ stroke: "#333", fill: "transparent", lineDash: [3, 2] }),
  };
}
