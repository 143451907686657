<svelte:options strictprops={false} />

<script>
  import typeIsCorrect from "../lib/type-is-correct.js";

  export let value = "";
  export let rowData;
  export let type = undefined;
  export let formatter = (v) => v ?? "";
  export let validator = (v) => true;
  export let highlighter = (v) => false;
  export let disabled = false;
  export let readOnly = false;
  export let prefix = null;
  export let emptyRow = false;

  $: valid = validate(value, rowData);
  $: formatted = format(formatter, value);

  function validate(v, rowData) {
    try {
      return validator(v, rowData) && typeIsCorrect(v, type);
    } catch (err) {
      return false;
    }
  }

  function format(formatter, v) {
    try {
      const result = formatter(v);
      if (result === undefined || result === null) return "";
      return result;
    } catch (err) {
      console.log("formatter?", v, formatter);
      return v;
    }
  }
</script>

<div
  class="p-1 pointer-events-none truncate"
  class:bg-red-200={(!valid || highlighter(value, rowData)) && !emptyRow}
  class:text-gray-500={disabled}
  class:italic={readOnly}>
  {#if prefix}
    {prefix}
  {/if}
  {#if formatted !== ""}
    {formatted}
  {:else}
    &nbsp;
  {/if}
</div>
