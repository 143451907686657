import { scale, rotate, add } from "vector";
import range from "lodash/range.js";

function ngon(x, y, radius, numPts, rot = 0) {
  const increment = (2 * Math.PI) / numPts;

  const v = range(numPts).map((i) => {
    const rotation = Math.PI / 2 + i * increment + rot;
    return scale(rotate({ x: 1, y: 0 }, rotation), radius);
  });

  const ys = v.map((vertex) => vertex.y);
  const ymax = Math.max(...ys);
  const ymin = Math.min(...ys);

  const mp = (ymax + ymin) / 2;
  const offset = { x: 0, y: -mp };

  return v.map((vertex) => add({ x, y }, vertex, offset));
}

export default ngon;
