import { orientation } from "vector";

export function cornerFlip(prev, point, next) {
  const o = orientation(prev, point, next);

  let flipHorizontal;
  let flipVertical;

  if (next.x > prev.x && next.y < prev.y) {
    flipHorizontal = 1;
    flipVertical = 1;
  } else if (next.x > prev.x) {
    flipHorizontal = -1;
    flipVertical = 1;
  } else if (next.y > prev.y) {
    flipHorizontal = -1;
    flipVertical = -1;
  } else {
    flipHorizontal = 1;
    flipVertical = -1;
  }

  if (o > 0)
    return { flipHorizontal: -flipHorizontal, flipVertical: -flipVertical };

  return { flipHorizontal, flipVertical };
}
