import { DimText } from "dimtext";

const dt = new DimText();

function createBoxRefPlanes() {
  return {
    type: "box",
    left: { name: "Left", value: dt.parse("0").value },
    right: { name: "Right", value: dt.parse("50").value },
    bottom: { name: "Bottom", value: dt.parse("0").value },
    top: { name: "Top", value: dt.parse("50").value },
  };
}

function createCrossRefPlanes() {
  return {
    type: "cross",
    horizontal: { name: "Horizontal", value: dt.parse("50").value },
    vertical: { name: "Vertical", value: dt.parse("50").value },
  };
}

export { createBoxRefPlanes, createCrossRefPlanes }
