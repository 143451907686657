import { LinearDisplayFormat } from "dimtext";
import { sortArrayBy } from "@local/extensions/collections/sortable-list.js";
import get from "lodash/get.js";
import parseDim from "@local/extensions/parsers/parse-dim.js";
import unparseDim from "@local/extensions/parsers/unparse-dim.js";
import dimSettings from "@local/extensions/utilities/dim-settings.js";

/**
 *
 * @param group Group
 * @param t Types
 * @param disabled
 * @param orgColumns Org-level columns
 * @param jobColumns Job-level columns
 * @param sc Standard columnns
 * @returns
 */
function makeCollectionColumns(
  group,
  t,
  disabled: boolean,
  orgColumns,
  jobColumns,
  sc,
  extraColumns = [],
) {
  t = t || [];

  const { displayUnit, dimFormat, dimPrecision } = dimSettings(
    group.data.settings,
  );

  const dimParser = parseDim(displayUnit);

  const dimFormatter = (v) => {
    return v
      ? v.format(LinearDisplayFormat[dimFormat], dimPrecision, { displayUnit })
      : "";
  };

  const standardCols = [
    { label: "Mark", prop: "mark", readOnly: disabled, type: "string" },
    {
      label: "Width",
      type: "multi-column",
      id: "width",
      prop: "width",
      primary: 0,
      result: 2,
      separateHeaders: true,
      hideSubcolumns: group.data.settings.hide_width_offset,
      subcolumns: [
        {
          prop: "width",
          readOnly: disabled,
          parser: dimParser,
          formatter: dimFormatter,
          unparser: unparseDim,
          validator: (v) => v === null || !!v,
          highlighter: (v) => v === null,
          default: null,
        },
        {
          label: "+/-",
          prop: "width_offset",
          readOnly: disabled,
          parser: dimParser,
          formatter: dimFormatter,
          unparser: unparseDim,
          validator: (v) => !!v,
          default: () => dimParser("0"),
        },
        {
          label: "=",
          prop: "cache.width_prime",
          readOnly: true,
          formatter: dimFormatter,
          unparser: unparseDim,
        },
      ],
    },
    {
      label: "Height",
      type: "multi-column",
      id: "height",
      prop: "height",
      primary: 0,
      result: 2,
      separateHeaders: true,
      hideSubcolumns: group.data.settings.hide_height_offset,
      subcolumns: [
        {
          prop: "height",
          readOnly: disabled,
          parser: dimParser,
          formatter: dimFormatter,
          unparser: unparseDim,
          validator: (v) => v === null || !!v,
          highlighter: (v) => v === null,
          default: null,
        },
        {
          label: "+/-",
          prop: "height_offset",
          readOnly: disabled,
          parser: dimParser,
          formatter: dimFormatter,
          unparser: unparseDim,
          validator: (v) => !!v,
          default: () => dimParser("0"),
        },
        {
          label: "=",
          prop: "cache.height_prime",
          readOnly: true,
          formatter: dimFormatter,
          unparser: unparseDim,
        },
      ],
    },
    {
      label: "Description",
      prop: "description",
      type: "string",
      readOnly: disabled,
    },
    {
      label: "Est. Area (sf)",
      prop: "cache.area",
      readOnly: true,
      formatter: (a) => (typeof a === "number" ? a.toFixed(2) : ""),
    },
  ]
    .filter((col) => {
      if (sc[col.prop]) return sc[col.prop].visible;
      return true;
    })
    .map((col) => {
      if (sc[col.prop] && sc[col.prop].override) {
        col.label = sc[col.prop].override;
      }

      return col;
    });

  const cols = [
    ...standardCols,
    ...orgColumns.map((c) => {
      return {
        label: c.name,
        prop: `custom_column_data.${c.id}`,
        readOnly: disabled,
        orgColumn: true,
        customColumn: true,
        id: c.id,
      };
    }),
    ...jobColumns.map((c) => {
      return {
        label: c.name,
        prop: `custom_column_data.${c.id}`,
        readOnly: disabled,
        jobColumn: true,
        customColumn: true,
        id: c.id,
        deletable: !disabled,
        renamable: !disabled,
      };
    }),
    ...extraColumns,
  ];

  const order = get(group.data, "collection_column_order") || [];
  return sortArrayBy(cols, order, "prop");
}

export { makeCollectionColumns };
