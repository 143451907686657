export const exitEvents = {
  ArrowUp: true,
  ArrowRight: true,
  ArrowDown: true,
  ArrowLeft: true,
};

export const metaKeys = {
  Meta: true,
  Alt: true,
  Control: true,
  Shift: true,
};

export const directions = {
  Enter: "down",
  ArrowUp: "up",
  ArrowRight: "right",
  ArrowDown: "down",
  ArrowLeft: "left",
};

export const mod = /Mac|iPod|iPhone|iPad/.test(navigator.platform)
  ? "metaKey"
  : "ctrlKey";
