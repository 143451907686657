<!-- <svelte:options strictprops={false} /> -->

<script>
  import { createEventDispatcher, tick } from "svelte";

  export let defaultValue;
  export let value = undefined;
  export let newRow = false;
  export let size = "sm";

  const dispatch = createEventDispatcher();

  let input;
  let timer;

  $: height = {
    xxs: "1.15rem",
    xs: "1.4rem",
    sm: "2rem",
    base: "2.1rem",
    lg: "2.5rem",
  }[size];

  export const focus = () => {
    input.focus();
  };

  async function handleFocus() {
    if (value === undefined && defaultValue !== undefined) {
      value = defaultValue;
    }
    await tick();
    input.select();
    dispatch("touch");
  }

  function handleKeydown(evt) {
    if (evt.key === "Enter") {
      evt.stopPropagation();
      dispatch("next", { key: "Enter" });
    }
  }

  function handleUpdate() {
    clearTimeout(timer);
    timer = setTimeout(() => {
      dispatch("update", value);
    }, 500);
  }
</script>

<input
  type="text"
  bind:this={input}
  class="w-full px-2 bg-amber-50 hover:ring-2"
  class:bg-amber-50={newRow}
  style:height
  on:focus={handleFocus}
  on:keydown={handleKeydown}
  on:input={handleUpdate}
  bind:value />
