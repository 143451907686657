import scrollableAncestor from "./scrollable-ancestor";

// Checks whether a dom element is in view
function isInView(el, ht = null, topOffset = 0, ancestor = null) {
  const container = ancestor || scrollableAncestor(el);
  if (!container) return true;
  const { height, top } = el.getBoundingClientRect();
  ht = ht || height;
  const bottom = top + ht;
  const containerRect = container.getBoundingClientRect();

  return top >= containerRect.top + topOffset && bottom <= containerRect.bottom;
}

export default isInView;
