import {
  subtract,
  normalize,
  angle,
  angleBetween,
  reverse,
  scale,
  add,
} from "vector";
import { almostEqualPt } from "overline";
const PIx2 = 2 * Math.PI;

export function fillet(prev, v, next, radius) {
  if (almostEqualPt(prev, v)) {
    return {
      v: v,
      a: v,
      b: v,
      saf: "1",
      invalid: true,
    };
  }
  const vec_0 = normalize(subtract(v, prev));
  const vec_1 = normalize(subtract(next, v));
  const angle_0 = angle(vec_0);
  const angle_1 = angle(vec_1);
  const diff = angle_0 - angle_1;
  let turn_angle;
  if (diff < -Math.PI) {
    turn_angle = diff + PIx2;
  } else if (diff > Math.PI) {
    turn_angle = diff - PIx2;
  } else {
    turn_angle = diff;
  }

  const ang = angleBetween(vec_0, reverse(vec_1));
  const ltt = Math.abs(radius / Math.tan(ang / 2));
  const a = subtract(v, scale(vec_0, ltt));
  const b = add(v, scale(vec_1, ltt));

  const saf = turn_angle > 0 ? "0" : "1";

  return { a, b, saf };
}
