import { Dimension } from "dimtext";
import { nextTypeMark } from "@local/extensions/identifiers/mark.js";
import { createGenericType } from "./makeup.js";

import type { PlainDimension } from "dimtext";
import type { MakeupLayer } from "./makeup.js";


interface TypeRecordCore {
  mark: string;
  name: string;
  notes: string;
  productId?: string;
  data: {
    layers: Array<MakeupLayer>;
  };
  desired_thickness: PlainDimension | null;
}

interface TypeRecordHydrated extends TypeRecordCore {
  desired_thickness: Dimension;
  cache: {
    total_thickness: number | null;
    layers: Array<{
      thickness: Dimension | null;
      thickness_in: number | null;
    }>;
  }
}

function createType(recordId, types = [], owner = "group") {
  const mark = nextTypeMark(types);

  return {
    id: crypto.randomUUID(),
    mark,
    seller_reference: null,
    buyer_reference: null,
    ...(owner === "group"
      ? { group_id: recordId }
      : { organization_id: recordId }),
    notes: null,
    ...createGenericType("mono"),
  };
}

function updateTypeCache(type) {
  if (type.data?.layers) {
    const layers =
      type.data?.layers.map((l) => ({
        thickness: l.material?.data?.thickness,
        thickness_in: l.material?.data?.thickness?.toNumber("inches"),
      })) || [];

    let total_thickness;

    if (layers.some((l) => !l.thickness)) {
      total_thickness = null;
    } else {
      total_thickness = layers.reduce((t, l) => t + l.thickness_in, 0);
    }

    type.cache = { total_thickness, layers };
  } else {
    type.cache = {};
  }

  return type;
}

function hydrateType(type: TypeRecordCore): TypeRecordHydrated {
  if (type.data?.layers) {
    type.data.layers.forEach((layer) => {
      layer.material.data.thickness = layer.material.data.thickness
        ? new Dimension(layer.material.data.thickness)
        : null;
    });
  }

  if (type.desired_thickness) {
    type.desired_thickness = new Dimension(type.desired_thickness);
  }

  updateTypeCache(type);
  return type;
}

function typeSurfaces(type) {
  if (!type.data.layers) return [];
  let surfaces = type.data.layers.map((l) => null);

  let surface = 1;
  type.data.layers.forEach((layer, i) => {
    if (layer.type === "glass") {
      surfaces[i] = surface;
      surface += 2;
    }
  });

  return surfaces;
}

export type { TypeRecordCore, TypeRecordHydrated }

export {
  createType,
  updateTypeCache,
  hydrateType,
  typeSurfaces,
}
