import { edgeOffsetPoint } from "./edge-offset-center.js";
import { edgePoint } from "./edge-point-rotation.js";
import { edgeRectanglePoints } from "./edge-rectangle-points.js";
import {
  translate,
  rotate,
  mirrorHorizontal,
  mirrorVertical,
  mirrorBoth,
} from "./transform-points.js";
import { dist, scale, angle } from "vector";
import { segQuadrants } from "./quadrants.js";
import { fabFunctions } from "../fabrications/index.js";
import { cornerFlip } from "./corner-flip.js";
import alignmentPt from "./alignment-pt.js";

function fabPath(f, fabrications) {
  let path;
  let voids;
  let annotations;
  if (f.orphaned) {
    path = f.path;
    voids = f.voids;
  } else {
    const fab = fabrications[f.fab_id];
    if (fab.type === "function") {
      const func = fabFunctions[fab.func];
      const res = func(...fab.params);
      path = res.path;
      voids = res.voids;
    } else if (fab.type === "generic") {
      const func = fabFunctions[fab.position];
      const res = func();
      annotations = res.annotations;
    } else {
      path = fab.path;
      voids = fab.voids;
    }
  }
  return { path, voids, annotations };
}

function fabPoints(f, point, rotation, fabrications) {
  const { path, voids, annotations } = fabPath(f, fabrications);
  const pts = path ? translate(rotate(path, rotation), point.x, point.y) : [];
  return {
    path: pts,
    voids: voids?.map((v) =>
      translate(rotate(v.path, rotation), point.x, point.y),
    ),
    annotations: annotations?.rotate(rotation).translate(point.x, point.y),
  };
}

function cornerFabPoints(f, point, flipHorizontal, flipVertical, fabrications) {
  const { path, voids, annotations } = fabPath(f, fabrications);
  let pts;
  let vpts;
  if (flipHorizontal === -1 && flipVertical === -1) {
    pts = path && mirrorBoth(path);
    vpts = voids?.map((v) => mirrorBoth(v.path));
  } else if (flipHorizontal === -1) {
    pts = path && mirrorHorizontal(path);
    vpts = voids?.map((v) => mirrorHorizontal(v.path));
  } else if (flipVertical === -1) {
    pts = path && mirrorVertical(path);
    vpts = voids?.map((v) => mirrorVertical(v.path));
  } else {
    pts = path;
    vpts = voids?.map((v) => v.path);
  }

  return {
    path: pts ? translate(pts, point.x, point.y) : [point],
    voids: vpts?.map((p) => translate(p, point.x, point.y)),
    annotations: annotations
      ?.scale(flipHorizontal, flipVertical)
      .translate(point.x, point.y),
  };
}
class Polyface {
  constructor(item, fabrications) {
    this.offsets = null;
    if (item.shape.type === "free") {
      this.shape = item.shape.vertices;

      if (item.cache) {
        const { xmin, xmax, ymin, ymax } = this.bbox;
        const xoff = item.cache.width_offset_in / 2;
        const yoff = item.cache.height_offset_in / 2;

        this.offsets = [
          [
            { x: xmin + xoff, y: ymin + yoff },
            { x: xmax - xoff, y: ymin + yoff },
            { x: xmax - xoff, y: ymax - yoff },
            { x: xmin + xoff, y: ymax - yoff },
          ],
        ];
      }
    } else if (!item.width || !item.height) {
      const w = item.width ? item.cache.width_in : 24;
      const h = item.height ? item.cache.height_in : 48;
      const ox = item.rectangle_offset.x || 0;
      const oy = item.rectangle_offset.y || 0;
      this.shape = [
        [
          { x: ox, y: oy },
          { x: w + ox, y: oy },
          { x: w + ox, y: h + oy },
          { x: ox, y: h + oy },
        ],
      ];
    } else {
      const ox = item.rectangle_offset.x || 0;
      const oy = item.rectangle_offset.y || 0;

      this.shape = [
        [
          { x: ox, y: oy },
          { x: ox + item.cache.width_in + item.cache.width_offset_in, y: oy },
          {
            x: ox + item.cache.width_in + item.cache.width_offset_in,
            y: oy + item.cache.height_in + item.cache.height_offset_in,
          },
          { x: ox, y: oy + item.cache.height_in + item.cache.height_offset_in },
        ],
      ];

      if (
        item.cache.width_offset_in !== 0 ||
        item.cache.height_offset_in !== 0
      ) {
        const xoff = item.cache.width_offset_in / 2;
        const yoff = item.cache.height_offset_in / 2;

        this.offsets = [
          [
            { x: ox + xoff, y: oy + yoff },
            { x: ox + item.cache.width_in + xoff, y: oy + yoff },
            {
              x: ox + item.cache.width_in + xoff,
              y: oy + item.cache.height_in + yoff,
            },
            { x: ox + xoff, y: oy + item.cache.height_in + yoff },
          ],
        ];
      }
    }

    this.voids = item.data?.fabrications?.voids
      ? item.data.fabrications.voids
      : [];

    this.edgeFabrications = item.data?.fabrications?.edge
      ? item.data.fabrications.edge
      : [];

    this.cornerFabrications = item.data?.fabrications?.corner
      ? item.data.fabrications.corner
      : [];

    this.surfaceFabrications = item.data?.fabrications?.bug
      ? [{ ...item.data.fabrications.bug, category: "bug" }]
      : [];

    this.fabrications = fabrications; // All job-level fabrications
  }

  get cornerFabs() {
    const corners = this.shape.map((loop) => loop.map((v) => null));
    this.cornerFabrications.forEach((f) => {
      corners[f.reference.loop][f.reference.corner] = f;
    });
    return corners;
  }

  get voidpaths() {
    return this.voids.map((v) => {
      const edge = {
        start: this.shape[0][v.reference.edge],
        end: this.shape[0][(v.reference.edge + 1) % this.shape[0].length],
      };

      const { center, evec, ovec } = edgeOffsetPoint(edge, v.reference);

      if (v.type === "circular-hole") {
        const diam = v.diameter.toNumber("inches");
        return [
          { x: center.x + diam / 2, y: center.y, bulge: -1 },
          { x: center.x - diam / 2, y: center.y, bulge: -1 },
        ];
      } else if (v.type === "rectangular-hole") {
        const width = v.width.toNumber("inches");
        const height = v.height.toNumber("inches");
        const radius = v.radius.toNumber("inches");
        const orientation = v.orientation;
        const alignment = v.alignment;

        // bulge = (2 - 2 * Math.sqrt(1/2)) / Math.sqrt(2)
        const bulge = -0.414213562373095;

        let feat =
          radius > 0
            ? [
                { x: radius, y: 0 },
                { x: 0, y: radius, bulge },
                { x: 0, y: height - radius },
                { x: radius, y: height, bulge },
                { x: width - radius, y: height },
                { x: width, y: height - radius, bulge },
                { x: width, y: radius },
                { x: width - radius, y: 0, bulge },
              ]
            : [
                { x: 0, y: 0 },
                { x: 0, y: height },
                { x: width, y: height },
                { x: width, y: 0 },
              ];

        if (alignment === "center") {
          if (orientation === "edge-aligned") {
            feat = translate(
              rotate(translate(feat, -width / 2, -height / 2), angle(evec)),
              width / 2,
              height / 2,
            );
          }
          feat = translate(feat, center.x - width / 2, center.y - height / 2);
        } else {
          if (orientation === "edge-aligned") {
            feat = rotate(feat, angle(evec));
            if (v.reference.start === "end") {
              const ov = scale(ovec, height);
              feat = translate(feat, ov.x, ov.y);
            }
            feat = translate(feat, center.x, center.y);
          } else {
            const t = alignmentPt(evec, v.reference, width, height);
            feat = translate(feat, center.x + t.x, center.y + t.y);
          }
        }

        return feat;
      } else {
        return [];
      }
    });
  }

  get voidEntities() {
    return this.voids.map((v) => {
      const edge = {
        start: this.shape[0][v.reference.edge],
        end: this.shape[0][(v.reference.edge + 1) % this.shape[0].length],
      };

      const { center, evec, ovec } = edgeOffsetPoint(edge, v.reference);

      if (v.type === "circular-hole") {
        const diam = v.diameter.toNumber("inches");
        return {
          type: "circle",
          center,
          radius: diam / 2,
        };
      } else if (v.type === "rectangular-hole") {
        const width = v.width.toNumber("inches");
        const height = v.height.toNumber("inches");
        const radius = v.radius.toNumber("inches");
        const orientation = v.orientation;
        const alignment = v.alignment;

        // bulge = (2 - 2 * Math.sqrt(1/2)) / Math.sqrt(2)
        const bulge = -0.414213562373095;

        let feat =
          radius > 0
            ? {
                type: "polygon",
                vertices: [
                  { x: radius, y: 0 },
                  { x: 0, y: radius, bulge },
                  { x: 0, y: height - radius },
                  { x: radius, y: height, bulge },
                  { x: width - radius, y: height },
                  { x: width, y: height - radius, bulge },
                  { x: width, y: radius },
                  { x: width - radius, y: 0, bulge },
                ],
              }
            : {
                type: "polygon",
                vertices: [
                  { x: 0, y: 0 },
                  { x: 0, y: height },
                  { x: width, y: height },
                  { x: width, y: 0 },
                ],
              };

        if (alignment === "center") {
          if (orientation === "edge-aligned") {
            feat.vertices = translate(
              rotate(
                translate(feat.vertices, -width / 2, -height / 2),
                angle(evec),
              ),
              width / 2,
              height / 2,
            );
          }
          feat.vertices = translate(
            feat.vertices,
            center.x - width / 2,
            center.y - height / 2,
          );
        } else {
          if (orientation === "edge-aligned") {
            feat.vertices = rotate(feat.vertices, angle(evec));
            if (v.reference.start === "end") {
              const ov = scale(ovec, height);
              feat.vertices = translate(feat.vertices, ov.x, ov.y);
            }
            feat.vertices = translate(feat.vertices, center.x, center.y);
          } else {
            const t = alignmentPt(evec, v.reference, width, height);
            feat.vertices = translate(
              feat.vertices,
              center.x + t.x,
              center.y + t.y,
            );
          }
        }

        return feat;
      } else {
        return [];
      }
    });
  }

  get perimeterFabs() {
    const edges = this.edges;

    const paths = [];
    const voidpaths = [];
    this.edgeFabrications.forEach((f) => {
      const edge = edges[f.reference.loop]?.[f.reference.edge];

      const { point, rotation } = edgePoint(edge, f.reference);
      if (this.fabrications) {
        const { path, voids } = fabPoints(
          f,
          point,
          rotation,
          this.fabrications,
        );
        paths.push(path);
        if (voids) voidpaths.push(...voids);
      }
    });

    this.cornerFabrications.forEach((f) => {
      const l = f.reference.loop;
      const v = f.reference.corner;
      const loop = this.shape[l];
      const prev = loop[(v + loop.length - 1) % loop.length];
      const vertex = loop[v];
      const next = loop[(v + 1) % loop.length];
      const { flipHorizontal, flipVertical } = cornerFlip(prev, vertex, next);
      const { path, voids } = cornerFabPoints(
        f,
        vertex,
        flipHorizontal,
        flipVertical,
        this.fabrications,
      );
      paths.push(path);
      if (voids) voidpaths.push(...voids);
    });

    return { paths, voidpaths };
  }

  get shapeWithFeatures() {
    const annotations = [];
    const voidpaths = this.voidpaths;

    if (
      this.edgeFabrications.length === 0 &&
      this.cornerFabrications.length === 0
    )
      return {
        shape: [...this.shape, ...voidpaths],
        annotations,
      };

    // get edge lengths, so we can sort fabrications by t-value
    const edgeLengths = this.shape.map((loop) => {
      const l = loop.length;
      return loop.map((v, i) => {
        const end = loop[(i + 1) % l];
        return dist(v, end);
      });
    });

    // bucket corner fabrications
    const corners = this.cornerFabs;

    // bucket edge fabrications
    const edgeFabs = this.shape.map((loop) => loop.map((e) => []));
    this.edgeFabrications.forEach((f) => {
      if (edgeFabs[f.reference.loop]?.[f.reference.edge]) {
        edgeFabs[f.reference.loop][f.reference.edge].push(f);
      }
    });

    // sort edge fabs
    Object.values(edgeFabs).forEach((loop) => {
      Object.values(loop).forEach((edge) => {
        edge.sort((a, b) => {
          const al = edgeLengths[a.reference.loop][a.reference.edge];
          const at =
            a.reference.start === "start"
              ? a.reference.length.toNumber("inches")
              : al - a.reference.length.toNumber("inches");
          const bl = edgeLengths[b.reference.loop][b.reference.edge];
          const bt =
            b.reference.start === "start"
              ? b.reference.length.toNumber("inches")
              : bl - b.reference.length.toNumber("inches");

          if (at < bt) return -1;
          if (at > bt) return 1;
          return 0;
        });
      });
    });

    const outline = this.shape.map((loop, l) => {
      const s = [];

      loop.forEach((vertex, v) => {
        if (corners[l][v]) {
          const prev = loop[(v + loop.length - 1) % loop.length];
          const next = loop[(v + 1) % loop.length];
          const { flipHorizontal, flipVertical } = cornerFlip(
            prev,
            vertex,
            next,
          );
          const {
            path,
            voids,
            annotations: a,
          } = cornerFabPoints(
            corners[l][v],
            vertex,
            flipHorizontal,
            flipVertical,
            this.fabrications,
          );
          s.push(...path);
          if (voids) voidpaths.push(...voids);
          if (a) annotations.push(a);
        } else {
          s.push(vertex);
        }

        const edge = {
          start: vertex,
          end: loop[(v + 1) % loop.length],
        };

        edgeFabs[l][v].forEach((f) => {
          const { point, rotation } = edgePoint(edge, f.reference);
          if (this.fabrications) {
            const {
              path,
              voids,
              annotations: a,
            } = fabPoints(f, point, rotation, this.fabrications);
            s.push(...path);
            if (voids) voidpaths.push(...voids);
            if (a) annotations.push(a);
          }
        });
      });

      return s;
    });

    return {
      shape: [...outline, ...voidpaths],
      annotations,
    };
  }

  get surfaceFeatures() {
    const edges = this.edges;
    return this.surfaceFabrications.map((f) => {
      if (f.type === "rectangle") {
        const edge = edges[f.reference.loop]?.[f.reference.edge];
        return edgeRectanglePoints(edge, f);
      }
    });
  }

  get edges() {
    return this.shape.map((loop) =>
      loop.map((vertex, i) => ({
        start: vertex,
        end: loop[(i + 1) % loop.length],
      })),
    );
  }

  get vertices() {
    return this.shape.reduce((v, loop) => {
      return v.concat(loop);
    }, []);
  }

  get perimeter() {
    return this.shape.reduce((p, loop) => {
      return (
        p +
        loop.reduce((l, vertex, i) => {
          const next = loop[(i + 1) % loop.length];
          return l + dist(vertex, next);
        }, 0)
      );
    }, 0);
  }

  get bbox() {
    let xmin = Infinity;
    let xmax = -Infinity;
    let ymin = Infinity;
    let ymax = -Infinity;

    this.shape.forEach((face) => {
      face.forEach((v, i) => {
        const next = face[(i + 1) % face.length];

        if (next.bulge) {
          const av = segQuadrants(v, next, next.bulge);
          const ax = av.map((vec) => vec.x);
          const ay = av.map((vec) => vec.y);

          xmin = Math.min(...ax, v.x, xmin);
          ymin = Math.min(...ay, v.y, ymin);
          xmax = Math.max(...ax, v.x, xmax);
          ymax = Math.max(...ay, v.y, ymax);
        } else {
          xmin = Math.min(v.x, xmin);
          ymin = Math.min(v.y, ymin);
          xmax = Math.max(v.x, xmax);
          ymax = Math.max(v.y, ymax);
        }
      });
    });

    if (xmin === Infinity) xmin = 0;
    if (xmax === -Infinity) xmax = 0;
    if (ymin === Infinity) ymin = 0;
    if (ymax === -Infinity) ymax = 0;

    return {
      xmin,
      xmax,
      ymin,
      ymax,
      width: xmax - xmin,
      height: ymax - ymin,
    };
  }

  get width() {
    const xvals = this.vertices.map((v) => v.x);
    const min = Math.min(...xvals);
    const max = Math.max(...xvals);
    return max - min;
  }

  get height() {
    const yvals = this.vertices.map((v) => v.y);
    const min = Math.min(...yvals);
    const max = Math.max(...yvals);
    return max - min;
  }
}

export default Polyface;
