import { imageDataLoader, imageCanvasLoader, Buffered} from "./shared.js";

const { OffscreenCanvas, Image } = globalThis;

/**
 * @param {string} src
 * @returns {Promise<HTMLImageElement>}
 */
function loadImage(src) {
  return new Promise((resolve, reject) => {
    const image = new Image();
    const cleanup = () => {
      image.onload = null;
      image.onerror = null;
    };

    image.onload = () => {
      cleanup();
      resolve(image);
    };

    image.onerror = (e) => {
      cleanup();
      reject(new Error(`Failed to load image "${src}" `));
    };

    image.src = src;
  });
}

const BufferedImage = Buffered(HTMLImageElement, async (image) => {
  const canvas = new OffscreenCanvas(this.width, this.height);
  canvas.getContext("2d").drawImage(this._image, 0, 0);
  const blob = await canvas.convertToBlob();
  return await blob.arrayBuffer();
});

const loadImageData = imageDataLoader(loadImage, OffscreenCanvas);
const loadImageCanvas = imageCanvasLoader(loadImage, OffscreenCanvas);

export {
  Image,
  BufferedImage,
  loadImage,
  loadImageData,
  loadImageCanvas,
  OffscreenCanvas,
};

export { isImageData, isImage, isCanvas } from "./shared.js";
