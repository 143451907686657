<script>
  import { createEventDispatcher, setContext, tick, onMount } from "svelte";
  import DOMPurify from "dompurify";
  import { writable } from "svelte/store";
  import get from "lodash/get";
  import set from "lodash/set";
  import isEmpty from "lodash/isEmpty";
  import cloneDeep from "lodash/cloneDeep";
  import uniq from "lodash/uniq";
  import { distSq } from "vector";
  import { Modal, draggable, clickOutside } from "svelte-utilities";
  import {
    hashify,
    hashifyRanges,
    copyToClipboard,
    contiguousRanges,
    isInView,
    scrollableAncestor,
  } from "overline";

  import TextRenderer from "./TextRenderer.svelte";
  import OptionRenderer from "./OptionRenderer.svelte";
  import BooleanRenderer from "./BooleanRenderer.svelte";
  import MultistateRenderer from "./MultistateRenderer.svelte";
  import ActiveInput from "./ActiveInput.svelte";
  import FilldownControl from "./FilldownControl.svelte";
  import ColumnRenameInput from "./ColumnRenameInput.svelte";

  import parseHtmlTable from "./parse-html-table.js";
  import createFocused from "./focused.js";
  import { exitEvents, directions, mod } from "./constants.js";

  import isInRange from "../lib/is-in-range.js";
  import rangeIndices from "../lib/range-indices.js";
  import remToPx from "../lib/rem-to-px";

  import WarningIcon from "../assets/warning.svg";
  import TrashIcon from "../assets/trash.svg";
  import CaretDownIcon from "../assets/caret-down.svg";
  import EditIcon from "../assets/edit.svg";
  import ShowIcon from "../assets/show.svg";
  import HideIcon from "../assets/hide.svg";

  export let data = [];
  export let columns = null;
  export let cells = {};
  export let pivot = false;
  export let colHeader = true;
  export let rowHeader = true;
  export let flaggedRows = {};
  export let highlightedRows = [];
  export let warningCells = [];
  export let invalidCells = [];
  export let editable = true;
  export let selectedRow = 1; // for use in radio button selection of a row
  export let rowSelect = false;
  export let rowDelete = false;
  export let emptyRow = false;
  export let columnsPasteable = false;
  export let delegate = false;
  export let reorderable = false;
  export let columnAddable = false;
  export let selectedRows = [];
  export let divisions = [];
  export let darkCols = false;

  const dispatch = createEventDispatcher();
  const focused = createFocused();
  const editing = writable(false);

  setContext("datagrid", { focused, editing, emptyRow });

  const renderers = {
    select: OptionRenderer,
    text: TextRenderer,
    boolean: BooleanRenderer,
    multistate: MultistateRenderer,
  };

  let table;
  let container;
  let containerWidth;
  let confirmModal;
  let selectingHeaders = null;
  let fillRow = null;
  let emptyRowData = makeEmptyRow();
  let updatingSelection = false;
  let clicking = false;
  let cols = [];

  // variables to manage column addition/renaming
  let addingColumn = false;
  let newColumnLabel = "";
  let newColumnInput;
  let editingColumn = null;
  let columnOptions;
  let renamingColumn = false;
  let renameColumnLabel = "";
  let renameColumnInput;
  let columnOptionsFlipped = false;
  let columnToRemove = null;

  // variables to manage column drag/drop
  let columnDragStartPt = null;
  let isDraggingColumn = false;
  let columnsToDrag = null;
  let draggingColumn = null;
  let columnDropTarget = null;

  // Special variable to check for clickthrough of boolean inputs.
  // This is needed because, unlike other input types which only allow
  // interaction AFTER focusing, we want to allow checking a boolean
  // input with a single click.
  let clickingCheckbox = null;

  $: makeCols(columns, pivot);
  $: initialColwidth = computeColwidth(containerWidth, cols, rowSelect, columnAddable, rowDelete);
  $: sortedData = sortData(data, columns, pivot);
  $: updateTableSize(data, cols, emptyRow);
  $: selectedHeadersHash = makeSelectedHeaderHash($focused);
  $: warningHash = updateWarningHash(warningCells);
  $: invalidHash = updateWarningHash(invalidCells);
  $: highlightedHash = hashify(highlightedRows);
  $: fillDown = getFillDown(selection);
  $: fillEdges = getFillEdges(selection, fillRow);
  $: notifySelectionState($focused);
  $: updateSelectedRows(selectedRows);
  $: selection = updateSelection($focused);
  $: divs = hashify(divisions);

  async function updateSelectedRows(selectedRows) {
    if (updatingSelection) return;
    await tick();
    const ranges = contiguousRanges(selectedRows);
    focused.selectHeaders("row", ranges);
  }

  function makeEmptyRow() {
    if (columns) {
      return columns.reduce((d, col) => {
        if (col.type === "multi-column") {
          col.subcolumns.forEach((sc) => {
            const val = typeof sc.default === "function" ? sc.default() : sc.default;
            set(d, sc.prop, val);
          });
        } else {
          const val = typeof col.default === "function" ? col.default() : col.default;
          set(d, col.prop, val);
        }

        return d;
      }, {});
    }

    if (pivot) {
      return data.map((r) => undefined);
    }

    return data[0].map((d) => undefined);
  }

  function makeCols(columns, pivot) {
    // if columns are defined, use them as provided.
    if (columns) {
      cols = columns.reduce((cols, col, colIndex) => {
        const l = cols.length;
        if (col.type === "multi-column") {
          const subcols = col.subcolumns.map((c, i, a) => ({
            label: col.label,
            ...c,
            isChild: true,
            isFirst: i === 0,
            isLast: i === a.length - 1,
            firstIndex: l,
            colspan: a.length,
            subIndex: i,
            parent: col.id,
            parentIndex: colIndex,
            hideSubcolumns: col.hideSubcolumns,
            separateHeaders: col.separateHeaders,
          }));

          if (col.hideSubcolumns) {
            cols.push(subcols[col.primary]);
          } else {
            cols.push(...subcols);
          }
        } else {
          cols.push({ ...col, parentIndex: colIndex });
        }

        return cols;
      }, []);

      // Otherwise we assume data is an array of arrays
    } else if (pivot) {
      cols = data.map((d, index) => ({ label: d[0], prop: index }));
    } else {
      cols = data[0].map((d, index) => ({ label: d, prop: index }));
    }
  }

  function computeColwidth(containerWidth, cols, rowSelect, columnAddable, rowDelete) {
    if (!containerWidth) return 100;

    let fixedRems = 3;
    if (rowSelect) fixedRems += 2;
    if (columnAddable || rowDelete) fixedRems += 2;
    const fixedW = remToPx(fixedRems);
    const numCols = columns ? columns.length : cols.length;
    const colW = (containerWidth - fixedW) / numCols;
    return Math.max(colW, remToPx(6));
  }

  function updateTableSize(data, cols, emptyRow) {
    const rowCount = emptyRow ? data.length + 1 : data.length;
    const colCount = cols.length;
    if (rowCount !== $focused.rowCount || colCount !== $focused.colCount) {
      focused.updateSize(rowCount, colCount);
    }
  }

  function makeSelectedHeaderHash(f) {
    return {
      rows: hashifyRanges(f.selectedRows),
      cols: hashifyRanges(f.selectedCols),
    };
  }

  function updateWarningHash(warningCells) {
    return warningCells.reduce((h, s) => {
      for (let row = s[0]; row <= s[2]; row++) {
        for (let col = s[1]; col <= s[3]; col++) {
          h[`${row}.${col}`] = true;
        }
      }

      return h;
    }, {});
  }

  function updateSelection(f) {
    const selected = {};
    const bottom = {}; // all cells with a bottom border
    const left = {}; // all cells w/ a left border

    if (
      f.selected.length === 1 &&
      f.selected[0][0] === f.selected[0][2] &&
      f.selected[0][1] === f.selected[0][3]
    ) {
      return { selected, bottom, left };
    }

    if (f.selectedRows.length > 0) {
      const cr = contiguousRanges(Object.keys(selectedHeadersHash.rows).map((r) => parseInt(r)));

      cr.forEach((range) => {
        const min = Math.min(...range);
        const max = Math.max(...range);

        // shade cell background
        for (let row = min; row <= max; row++) {
          for (let col = 0; col < cols.length; col++) {
            selected[`${row}.${col}`] = true;
          }

          selected[`${row}.h`] = true;
        }

        for (let col = 0; col < cols.length; col++) {
          bottom[`${min - 1}.${col}`] = true;
          bottom[`${max}.${col}`] = true;
        }

        bottom[`${min - 1}.h`] = true;
        bottom[`${max}.h`] = true;
      });

      return { selected, bottom, left };
    } else if (f.selectedCols.length > 0) {
      const cr = contiguousRanges(Object.keys(selectedHeadersHash.cols).map((r) => parseInt(r)));

      cr.forEach((range) => {
        const min = Math.min(...range);
        const max = Math.max(...range);

        // shade cell background
        for (let col = min; col <= max; col++) {
          for (let row = 0; row < f.rowCount; row++) {
            selected[`${row}.${col}`] = true;
          }

          selected[`h.${col}`] = true;
        }

        for (let row = 0; row < f.rowCount; row++) {
          left[`${row}.${min}`] = true;
          left[`${row}.${max + 1}`] = true;
        }

        left[`h.${min}`] = true;
        left[`h.${max + 1}`] = true;
      });

      return { selected, bottom, left };
    }

    f.selected.forEach((s) => {
      // mark each "selected cell" for a shaded background
      for (let row = s[0]; row <= s[2]; row++) {
        for (let col = s[1]; col <= s[3]; col++) {
          selected[`${row}.${col}`] = true;
        }
      }

      // mark top and bottom edges for border highlight
      for (let col = s[1]; col <= s[3]; col++) {
        bottom[`${s[0] - 1}.${col}`] = true;
        bottom[`${s[2]}.${col}`] = true;
      }

      // mark left and right edges for border highlight
      for (let row = s[0]; row <= s[2]; row++) {
        left[`${row}.${s[1]}`] = true;
        left[`${row}.${s[3] + 1}`] = true;
      }
    });

    return { selected, bottom, left };
  }

  function getFillDown(selection) {
    if ($focused.selected.length < 1 || $focused.row === null || $focused.col === null) return null;

    const region = $focused.selected[$focused.selected.length - 1];

    return [region[2], region[3]];
  }

  function getFillEdges(selection, fillRow) {
    if ($focused.selected.length < 1) return null;
    if (fillRow === null) return null;

    const r = $focused.selected[$focused.selected.length - 1];

    const left = {};
    const bottom = {};

    if (fillRow < r[0]) {
      for (let row = fillRow; row <= r[0]; row++) {
        left[`${row}.${r[1]}`] = true;
        left[`${row}.${r[3] + 1}`] = true;
      }

      for (let col = r[1]; col <= r[3]; col++) {
        bottom[`${fillRow - 1}.${col}`] = true;
      }
    } else if (fillRow > r[2]) {
      for (let row = r[2] + 1; row <= fillRow; row++) {
        left[`${row}.${r[1]}`] = true;
        left[`${row}.${r[3] + 1}`] = true;
      }

      for (let col = r[1]; col <= r[3]; col++) {
        bottom[`${fillRow}.${col}`] = true;
      }
    } else {
      return null;
    }

    return { left, bottom };
  }

  async function notifySelectionState(f) {
    updatingSelection = true;
    const { selected, selectedRows, selectedCols } = f;
    await dispatch("updateSelection", { selected, selectedRows, selectedCols });
    updatingSelection = false;

    // scroll to new selection if necessary
    if (f.row === null || clicking) return;
    const tr = table.querySelector(`tr[data-row="${f.row}"]`);
    if (!tr) return;

    if (isInView(tr, tr.clientHeight, tr.clientHeight, container)) return;

    tr.style.scrollMarginTop = `${tr.clientHeight}px`;
    tr.scrollIntoView({ block: "start" });
  }

  function sortData(data, columns, pivot) {
    if (columns) return data;

    let rows;
    if (pivot) {
      rows = data[0].map((c, i) => {
        return data.map((d) => d[i]);
      });
    } else {
      rows = data;
    }

    if (colHeader) return rows.slice(1);
    return rows;
  }

  function updateValue(row, prop, value) {
    dispatch("updateValues", { updates: [{ row, prop, value }] });

    if (delegate) return;

    set(data[row], prop, value);
    data = data;
  }

  function updateMultipleValues(updates) {
    dispatch("updateValues", { updates });

    if (delegate) return;

    updates.forEach(({ row, prop, value }) => {
      set(data[row], prop, value);
    });
    data = data;
  }

  function addNewRow(rowData, shift) {
    Object.entries(rowData).forEach(([prop, value]) => {
      set(emptyRowData, prop, value);
    });

    dispatch("addRow", emptyRowData);

    if (!delegate) {
      data.push(emptyRowData);
      data = data;
    }

    emptyRowData = makeEmptyRow();

    if (shift) {
      focused.move("right");
    }
  }

  function updateEmptyRowValue(prop, value, shift) {
    set(emptyRowData, prop, value);
    dispatch("addRow", emptyRowData);

    if (!delegate) {
      data.push(emptyRowData);
      data = data;
    }

    emptyRowData = makeEmptyRow();

    if (shift) {
      focused.move("right");
    }
  }

  function deleteRow(row) {
    if (!editable) return;

    dispatch("deleteRow", { row });

    if (delegate) return;

    data.splice(row, 1);
    data = data;
  }

  function getEventTd(e) {
    try {
      const td = e.target.closest("td");
      if (!td) return null;
      if (!table.contains(td)) return null;
      return td;
    } catch {
      return null;
    }
  }

  function getEventTh(e) {
    try {
      const th = e.target.closest("th");
      if (!th) return null;
      if (!table.contains(th)) return null;
      return th;
    } catch {
      return null;
    }
  }

  function focusin(e) {
    const td = getEventTd(e);
    if (td) {
      const row = parseInt(td.getAttribute("data-row"));
      const col = parseInt(td.getAttribute("data-column"));
      if (row !== $focused.row || col !== $focused.col) {
        focused.focus(row, col);
      }
    }
  }

  function focusout(e) {
    if ($focused.row !== null || $focused.col !== null) {
      focused.blur();
    }
  }

  function dragstart(e) {
    clicking = true;
    const td = getEventTd(e.detail.event);
    if (!td) {
      const th = getEventTh(e.detail.event);
      if (th) {
        const row = parseInt(th.getAttribute("data-row"));
        const col = parseInt(th.getAttribute("data-column"));
        $focused.initialCol = col;

        if (!isNaN(row)) {
          selectingHeaders = "row";

          if (e.detail.event.shiftKey) {
            focused.shiftSelectHeaders("row", row);
          } else if (e.detail.event.metaKey) {
            focused.metaSelectHeaders("row", row);
          } else {
            focused.selectHeader("row", row);
          }
        } else if (!isNaN(col)) {
          const column = cols[col];
          if (e.detail.event.shiftKey) {
            selectingHeaders = "col";
            if (column.isChild && !column.hideSubcolumns) {
              focused.shiftSelectHeaders("col", column.firstIndex, column.firstIndex + column.colspan - 1);
            } else {
              focused.shiftSelectHeaders("col", col);
            }
          } else if (e.detail.event.metaKey) {
            selectingHeaders = "col";
            if (column.isChild && !column.hideSubcolumns) {
              focused.metaSelectHeaders("col", column.firstIndex, column.firstIndex + column.colspan - 1);
            } else {
              focused.metaSelectHeaders("col", col);
            }
          } else if (!reorderable) {
            selectingHeaders = "col";
            if (column.isChild && !column.hideSubcolumns) {
              focused.selectHeaders("col", [[column.firstIndex, column.firstIndex + column.colspan - 1]]);
            } else {
              focused.selectHeader("col", col);
            }
          } else {
            // We may be dragging to reorder a column. Set start point to detect
            // if drag should be initiated.
            selectingHeaders = "colonclick";
            columnDragStartPt = { x: e.detail.x, y: e.detail.y };
            draggingColumn = col;
            if (isInRange(col, $focused.selectedCols)) {
              columnsToDrag = rangeIndices($focused.selectedCols);
            } else {
              columnsToDrag = { [col]: true };
            }
          }
        }

        return;
      } else {
        return;
      }
    }

    $editing = false;

    const clickthrough = e.detail.event.target.getAttribute("data-clickthrough");
    const row = parseInt(td.getAttribute("data-row"));
    const col = parseInt(td.getAttribute("data-column"));
    $focused.initialCol = col;

    if (e.detail.event.shiftKey) {
      focused.shiftFocusTd(td);
    } else if (e.detail.event.metaKey) {
      focused.metaFocusTd(td);
    } else if (clickthrough === "clickthrough") {
      const column = cols[col];
      const isFocused = row === $focused.row && col === $focused.col;
      if (column && !isFocused && !column.disabled && !column.readOnly) {
        clickingCheckbox = { row, col };
      }

      focused.focusTd(td);
    } else {
      focused.focusTd(td);
    }
  }

  function addHeaderSelection(e) {
    const cell = getEventTd(e.detail.event) || getEventTh(e.detail.event);
    if (!cell) return;

    if (selectingHeaders === "row") {
      const row = parseInt(cell.getAttribute("data-row"));
      const last = $focused.selectedRows[$focused.selectedRows.length - 1];
      if (!isNaN(row) && last && row !== last[1]) {
        focused.shiftSelectHeaders("row", row);
      }
    } else if (selectingHeaders === "col") {
      const col = parseInt(cell.getAttribute("data-column"));
      const last = $focused.selectedCols[$focused.selectedCols.length - 1];
      if (!isNaN(col) && last && col !== last[1]) {
        const column = cols[col];
        if (column.isChild && !column.hideSubcolumns) {
          focused.shiftSelectHeaders("col", column.firstIndex, column.firstIndex + column.colspan - 1);
        } else {
          focused.shiftSelectHeaders("col", col);
        }
      }
    }
  }

  function isLastSelection(row, col) {
    const last = $focused.selected[$focused.selected.length - 1];
    if (!last) return false;

    return row === last[2] && col === last[3];
  }

  function getColumnDropTarget(event, x) {
    const cell = getEventTd(event) || getEventTh(event);
    if (!cell) return;
    const rect = cell.getBoundingClientRect();
    const col = parseInt(cell.getAttribute("data-column"));
    const l = Math.abs(x - rect.left);
    const r = Math.abs(rect.right - x);

    if (l <= r) {
      return col;
    } else {
      return col + 1;
    }
  }

  function drag(e) {
    if (selectingHeaders === "colonclick") {
      const p = e.detail;
      const d = columnDragStartPt ? distSq(p, columnDragStartPt) : 0;

      if (d > 100) {
        let cdt = getColumnDropTarget(e.detail.event, e.detail.x);
        columnDropTarget = cdt !== draggingColumn && cdt !== draggingColumn + 1 ? cdt : null;
        isDraggingColumn = true;
      }
    }

    if (selectingHeaders) {
      return addHeaderSelection(e);
    }

    const td = getEventTd(e.detail.event);
    if (!td) return;

    const row = parseInt(td.getAttribute("data-row"));
    const col = parseInt(td.getAttribute("data-column"));

    if (isNaN(row) || isNaN(col)) return;
    if (isLastSelection(row, col)) return;

    focused.shiftFocus(row, col);
  }

  function stopDraggingColumn() {
    selectingHeaders = null;

    columnDragStartPt = null;
    isDraggingColumn = false;
    columnsToDrag = null;
    draggingColumn = null;
    columnDropTarget = null;
  }

  async function dragend(e) {
    clicking = false;
    addingColumn = false;
    editingColumn = null;

    if (clickingCheckbox) {
      const cell = getEventTd(e.detail.event);
      const row = parseInt(cell.getAttribute("data-row"));
      const col = parseInt(cell.getAttribute("data-column"));
      const clickthrough = e.detail.event.target.getAttribute("data-clickthrough");

      if (row === clickingCheckbox.row && col === clickingCheckbox.col && clickthrough === "clickthrough") {
        const prop = cols[col].prop;
        const val = get(data[row], prop);
        if (cols[col].type === "boolean") {
          updateValue(row, prop, !val);
        } else if (cols[col].type === "multistate") {
          const current = cols[col].options.indexOf(val);
          const options = cols[col].options;
          const next = options[(current + 1) % options.length];
          updateValue(row, prop, next);
        }
      }

      clickingCheckbox = null;
    } else if (selectingHeaders === "colonclick") {
      const cell = getEventTd(e.detail.event) || getEventTh(e.detail.event);
      if (!cell) return stopDraggingColumn();

      const col = parseInt(cell.getAttribute("data-column"));
      if (isNaN(col)) return stopDraggingColumn();

      if (isDraggingColumn) {
        columnDropTarget = getColumnDropTarget(e.detail.event, e.detail.x);
        if (
          columnDropTarget === undefined ||
          columnDropTarget === null ||
          draggingColumn === null ||
          columnDropTarget === draggingColumn ||
          columnDropTarget === draggingColumn + 1 ||
          isEmpty(columnsToDrag)
        ) {
          return stopDraggingColumn();
        }

        // get current selection state
        const colArray = Object.keys(columnsToDrag)
          .map((k) => parseInt(k))
          .sort((a, b) => a - b);

        const parentCols = uniq(colArray.map((i) => cols[i].parentIndex));
        const colTarget =
          columnDropTarget >= cols.length ? columns.length : cols[columnDropTarget].parentIndex;

        await dispatch("moveColumns", {
          columns: parentCols,
          target: colTarget,
        });

        // update selection state
        const l = colArray.findIndex((c) => c > columnDropTarget);
        const st = l === -1 ? colArray.length : l;
        const ss = colArray.map((c, i) => [c, columnDropTarget + i - st]);
        focused.shiftSelectedCols(ss);
      } else {
        const column = cols[col];
        if (column.isChild && !column.hideSubcolumns) {
          focused.selectHeaders("col", [[column.firstIndex, column.firstIndex + column.colspan - 1]]);
        } else {
          focused.selectHeader("col", col);
        }
        table.focus();
      }

      return stopDraggingColumn();
    } else if (selectingHeaders) {
      addHeaderSelection(e);
      table.focus();
      selectingHeaders = null;
      return;
    }

    const td = getEventTd(e.detail.event);
    if (!td) return;

    const row = parseInt(td.getAttribute("data-row"));
    const col = parseInt(td.getAttribute("data-column"));

    if (isNaN(row) || isNaN(col)) return;
    if (isLastSelection(row, col)) return;

    focused.shiftFocus(row, col);
  }

  function fillDragstart(e) {
    e.detail.event.stopPropagation();

    const td = getEventTd(e.detail.event);
    if (!td) return;
    const row = parseInt(td.getAttribute("data-row"));
    if (row === undefined) return;
    fillRow = row;
  }

  function fillDrag(e) {
    e.detail.event.stopPropagation();

    const td = getEventTd(e.detail.event);
    if (!td) return;
    const row = parseInt(td.getAttribute("data-row"));
    if (row === undefined) return;
    fillRow = row;
  }

  function fillDragend(e) {
    const td = getEventTd(e.detail.event);
    if (!td) return;
    const lastRow = parseInt(td.getAttribute("data-row"));
    if (lastRow === undefined) return;

    const r = $focused.selected[$focused.selected.length - 1];

    // get source data from selected region
    const source = [];
    for (let row = r[0]; row <= r[2]; row++) {
      source.push([]);

      for (let col = r[1]; col <= r[3]; col++) {
        const prop = cols[col].prop;
        const val = get(data[row], prop);
        source[source.length - 1].push(val);
      }
    }

    let sc = 0;
    let sr = 0;

    // fill up
    if (lastRow < r[0]) {
      source.reverse();
      const updates = [];
      for (let row = r[0] - 1; row >= lastRow; row--) {
        sc = 0;
        for (let col = r[1]; col <= r[3]; col++) {
          const value = cloneDeep(source[sr % source.length][sc]);
          const prop = cols[col].prop;
          const cellDisabled = get(cells, [`${row}.${cols[col].prop}`, "disabled"]);

          if (!cols[col].readOnly && !cellDisabled) {
            updates.push({ row, prop, value });
            if (!delegate) set(data[row], prop, value);
          }
          sc += 1;
        }
        sr += 1;
      }
      dispatch("updateValues", { updates });
      // fill down
    } else if (lastRow > r[2]) {
      const updates = [];
      for (let row = r[2] + 1; row <= lastRow; row++) {
        sc = 0;
        for (let col = r[1]; col <= r[3]; col++) {
          const value = cloneDeep(source[sr % source.length][sc]);
          const prop = cols[col].prop;
          const cellDisabled = get(cells, [`${row}.${cols[col].prop}`, "disabled"]);

          if (!cols[col].readOnly && !cellDisabled) {
            updates.push({ row, prop, value });
            if (!delegate) set(data[row], prop, value);
          }
          sc += 1;
        }
        sr += 1;
      }
      dispatch("updateValues", { updates });
    } else {
      fillRow = null;
      return;
    }

    if (!delegate) data = data;
    focused.shiftSelect(fillRow, r[3]);
    fillRow = null;
  }

  async function keydown(e) {
    if ($editing) return;

    if (e.key === "c" && e[mod]) return copy(e);

    if (e.key === "x" && e[mod]) {
      await copy(e);
      deleteCells(e);
    }

    if (e.key === "Delete" || e.key === "Backspace") return deleteCells(e);

    const td = getEventTd(e);
    if (!td) return;
    const row = parseInt(td.getAttribute("data-row"));
    const col = parseInt(td.getAttribute("data-column"));

    if (exitEvents[e.key]) {
      focused.moveFrom(row, col, directions[e.key], e.shiftKey);
    }
  }

  function deleteCells(e) {
    if (!editable) return;
    if ($focused.row === null || $focused.col === null) return;

    e.preventDefault();

    const updates = [];
    $focused.selected.forEach((sel) => {
      for (let r = sel[0]; r <= sel[2]; r++) {
        for (let c = sel[1]; c <= sel[3]; c++) {
          const col = cols[c];
          const cellDisabled = get(cells, [`${r}.${col.prop}`, "disabled"]);
          if (!col.readOnly && !col.disabled && !cellDisabled) {
            let value = "";
            if (col.parser) {
              try {
                const parsed = col.parser("");
                value = parsed;
              } catch (e) {}
            }
            if (!col.validator || col.validator(value)) {
              updates.push({ row: r, prop: col.prop, value });
            }
          }
        }
      }
    });
    updateMultipleValues(updates);
  }

  async function copy(e) {
    if (
      ($focused.row === null || $focused.col === null) &&
      $focused.selectedCols.length < 1 &&
      $focused.selectedRows.length < 1
    )
      return;

    e.preventDefault();

    let sel;
    if ($focused.selectedCols.length > 0) {
      const last = $focused.selectedCols[$focused.selectedCols.length - 1];
      sel = [0, last[0], data.length - 1, last[1]];
    } else if ($focused.selectedRows.length > 0) {
      const last = $focused.selectedRows[$focused.selectedRows.length - 1];
      sel = [last[0], 0, last[1], cols.length - 1];
    } else {
      sel = $focused.selected[$focused.selected.length - 1];
    }

    const result = [];

    for (let r = sel[0]; r <= sel[2]; r++) {
      let row = [];
      for (let c = sel[1]; c <= sel[3]; c++) {
        const col = cols[c];
        let val = get(data[r], col.prop);

        if (col.type === "select") {
          val = col.optionMap[val];
        } else if (col.formatter) {
          val = col.formatter(val);
        }

        row.push(val);
      }
      result.push(row);
    }

    const dest = result.map((row) => row.join("\t")).join("\n");
    const f = cloneDeep($focused);
    copyToClipboard(dest);
    await tick();
    focused.set(f);
  }

  async function paste(e) {
    if (!editable) return;

    if (!e.clipboardData || !e.clipboardData.getData) return;
    if ($focused.row === null || $focused.col === null) return;

    e.preventDefault();

    let source;
    const textHTML = DOMPurify.sanitize(e.clipboardData.getData("text/html"), {
      ADD_TAGS: ["meta"],
      ADD_ATTR: ["content"],
      FORCE_BODY: true,
    });

    if (textHTML && /(<table)|(<TABLE)/g.test(textHTML)) {
      source = parseHtmlTable(textHTML);
    } else {
      source = e.clipboardData.getData("text/plain");
    }

    if (!source) return;

    if (typeof source === "string") {
      source = source.split(/\r?\n/).map((r) => r.split("\t"));
    }

    if (columnsPasteable && source[0]?.length > cols.length) {
      const newColsCt = source[0].length - cols.length;
      const newCols = Array.from({ length: newColsCt }, (_, i) => i + cols.length).map((i) => ({
        label: `Column ${i + 1}`,
        prop: i,
      }));
      if (newCols.length > 0) {
        cols.push(...newCols);
      }
    }

    const sel = $focused.selected[$focused.selected.length - 1];
    const top = sel[0]; // the top row that will be pasted into
    const left = sel[1]; // the left-most column that will be pasted into
    const bottom = Math.max(sel[2], top + source.length - 1);
    const right = Math.max(sel[3], left + source[0].length - 1);

    // blur to keep focus state from interfering w/ state updates
    const f = cloneDeep($focused);
    focused.blur();

    // Update data values
    let rI = 0;
    const updates = [];
    for (let r = top; r <= bottom; r++) {
      const sourceRow = source[rI % source.length];

      if (data[top + rI] === undefined) {
        const rData = {};
        let cI = 0;
        for (let c = left; c <= right; c++) {
          const col = cols[c];
          const cell = sourceRow[cI % sourceRow.length];

          try {
            let val;
            if (col.parser) {
              val = col.parser(cell);
            } else {
              val = cell;
            }

            if (col.validator && !col.validator(val)) {
              if (!col.acceptInvalid) throw new Error();
            }

            if (col.prop !== undefined && !col.readOnly) {
              rData[col.prop] = val;
            }
          } catch {}

          cI++;
        }

        addNewRow(rData);
      } else {
        let cI = 0;
        for (let c = left; c <= right; c++) {
          const col = cols[c];
          const cell = sourceRow[cI % sourceRow.length];

          try {
            let val;
            if (col.parser) {
              val = col.parser(cell);
            } else {
              val = cell;
            }

            if (col.validator && !col.validator(val)) {
              if (!col.acceptInvalid) throw new Error();
            }

            const cellDisabled = get(cells, [`${top + rI}.${col.prop}`, "disabled"]);
            if (col.prop !== undefined && !col.readOnly && !col.disabled && !cellDisabled) {
              updates.push({ row: top + rI, prop: col.prop, value: val });
            }
          } catch {}

          cI++;
        }
      }

      rI++;
    }

    if (updates.length > 0) {
      updateMultipleValues(updates);
    }

    // Set selection state
    await tick();
    const maxRow = Math.min(bottom, data.length - 1);
    const maxCol = Math.min(right, cols.length - 1);

    focused.set(f);
    focused.shiftSelect(maxRow, maxCol);
  }

  async function beginAddingColumn() {
    if (!editable) return;
    addingColumn = true;
    newColumnLabel = "";
    await tick();
    newColumnInput.focus();
  }

  async function showColumnOptions(colIndex) {
    editingColumn = colIndex;
    renamingColumn = false;
    columnOptionsFlipped = false;
    await tick;

    // Check whether options input needs to be flipped
    const c = columnOptions;
    const a = scrollableAncestor(c);

    const crect = c.getBoundingClientRect();
    const arect = a.getBoundingClientRect();

    if (crect.right > arect.right) {
      columnOptionsFlipped = true;
    } else {
      columnOptionsFlipped = false;
    }
  }

  async function beginRenamingColumn(colIndex) {
    renameColumnLabel = cols[colIndex].label;
    renamingColumn = true;
    await tick;
    renameColumnInput.focus();
  }

  function beginEditingColumnOptions(colIndex) {
    dispatch("editColumnOptions", { index: colIndex, node: columnOptions.parentNode });
    editingColumn = null;
  }

  function toggleSubcolumnVisibility(colIndex) {
    const column = columns[colIndex];
    dispatch("toggleSubcolVis", {
      index: colIndex,
      id: column.id || column.prop,
      value: !columns[colIndex].hideSubcolumns,
    });
    editingColumn = null;
  }

  function handleNewColumnKeyDown(e) {
    if (e.key === "Enter" && newColumnLabel !== "") {
      dispatch("addColumn", newColumnLabel);

      addingColumn = false;
      if (delegate) return;
    }
  }

  function updateColumnLabel(name, index) {
    const column = cols[index];
    dispatch("renameColumn", { name, index: column.parentIndex });

    editingColumn = null;
    if (delegate) return;
  }

  function confirmRemoveColumn(index) {
    columnToRemove = index;
    confirmModal.open();

    editingColumn = null;
  }

  function deleteColumn() {
    const column = cols[columnToRemove];
    dispatch("deleteColumn", column.parentIndex);

    columnToRemove = null;
    if (delegate) return;
  }

  onMount(async () => {
    await tick();
    containerWidth = container.clientWidth;
  });
</script>

<!-- svelte-ignore a11y-no-noninteractive-element-interactions -->
<!-- svelte-ignore a11y-no-noninteractive-tabindex -->
<!-- svelte-ignore a11y-no-static-element-interactions -->
<!-- svelte-ignore a11y-click-events-have-key-events -->
<div
  bind:this={container}
  bind:clientWidth={containerWidth}
  class="relative h-full overflow-y-auto overflow-x-auto">
  <table
    class="w-full table-fixed border-collapse text-xs outline-none"
    tabindex="0"
    bind:this={table}
    on:keydown={keydown}
    use:draggable
    on:focusin={focusin}
    on:dragstart={dragstart}
    on:drag={drag}
    on:dragend={dragend}
    on:paste={paste}>
    <colgroup>
      {#if rowSelect}
        <col class="text-center w-8" />
      {/if}
      <col class="bg-gray-100 w-12" />
      {#each cols as column (column)}
        <col class={column.class} style="min-width:3rem;" />
      {/each}
      {#if columnAddable || rowDelete}
        <col class="w-8" />
      {/if}
    </colgroup>
    {#if colHeader}
      <tr>
        {#if rowSelect}
          <th class="col-header" />
        {/if}
        <th class="col-header"><div class="p-1">&nbsp;</div></th>
        {#each cols as column, colIndex}
          {@const isChild = column.isChild && !column.isFirst}
          {@const multiCol = column.isChild && column.separateHeaders && !column.hideSubcolumns}
          {@const width = multiCol ? initialColwidth / column.colspan : initialColwidth}
          {#if !isChild || multiCol}
            <th
              class="col-header"
              style="width:{width}px;min-width:3rem;"
              class:cursor-grab={reorderable}
              colspan={column.isChild && !column.separateHeaders ? column.colspan : 1}
              data-column={colIndex}>
              <div
                class="dg-header-cell flex items-center justify-between p-1"
                class:dark-cols={darkCols}
                class:child-col={isChild}
                class:selected={selection.selected[`h.${colIndex}`]}
                class:is-dragging={isDraggingColumn && columnsToDrag[colIndex]}
                class:left-selection={selection.left[`h.${colIndex}`]}
                class:drop-target={isDraggingColumn && columnDropTarget === colIndex && !isChild}>
                {#if renamingColumn && editingColumn === colIndex}
                  <div class="grow">
                    <ColumnRenameInput
                      bind:this={renameColumnInput}
                      bind:value={renameColumnLabel}
                      on:update={(e) => updateColumnLabel(e.detail, colIndex)} />
                  </div>
                {:else}
                  <div class="w-full h-full truncate">
                    {column.label}
                  </div>
                {/if}
                {#if editable && (multiCol ? column.isLast : true) && (column.editable || column.deletable || column.renamable || column.isChild)}
                  <div
                    class="cursor-pointer"
                    on:click={() => showColumnOptions(colIndex)}
                    on:mousedown|stopPropagation
                    on:mousemove|stopPropagation
                    on:mouseup|stopPropagation>
                    <CaretDownIcon />
                  </div>
                {/if}
              </div>
              {#if editable && editingColumn === colIndex && !renamingColumn}
                <div
                  bind:this={columnOptions}
                  class="popout absolute top-0 mt-7 shadow-lg border z-40 bg-white p-4 rounded text-left space-y-2 cursor-auto w-44"
                  class:right-0={!columnOptionsFlipped}
                  class:left-0={columnOptionsFlipped}
                  use:clickOutside
                  on:outclick={() => (editingColumn = null)}
                  on:mousedown|stopPropagation
                  on:mousemove|stopPropagation
                  on:mouseup|stopPropagation>
                  {#if column.editable}
                    <div
                      class="cursor-pointer hover:text-black flex items-center gap-2"
                      on:click={() => beginEditingColumnOptions(colIndex)}>
                      <div><EditIcon /></div>
                      <div>Edit Options</div>
                    </div>
                  {/if}
                  {#if column.isChild}
                    <div
                      class="cursor-pointer hover:text-black flex items-center gap-2"
                      on:click={() => toggleSubcolumnVisibility(column.parentIndex)}>
                      {#if column.hideSubcolumns}
                        <div><ShowIcon /></div>
                        <div>Show Subcolumns</div>
                      {:else}
                        <div><HideIcon /></div>
                        <div>Hide Subcolumns</div>
                      {/if}
                    </div>
                  {/if}
                  {#if column.renamable}
                    <div
                      class="cursor-pointer hover:text-black flex items-center gap-2"
                      on:click={() => beginRenamingColumn(colIndex)}>
                      <div>
                        <EditIcon />
                      </div>
                      <div>Rename Column</div>
                    </div>
                  {/if}
                  {#if column.deletable}
                    <div
                      class="cursor-pointer hover:text-black flex items-center gap-2"
                      on:click={() => confirmRemoveColumn(colIndex)}>
                      <div>
                        <TrashIcon />
                      </div>
                      <div>Delete Column</div>
                    </div>
                  {/if}
                </div>
              {/if}
            </th>
          {/if}
        {/each}
        {#if columnAddable}
          <th
            class="col-header w-8 cursor-pointer"
            on:click={beginAddingColumn}
            on:mousedown|stopPropagation
            on:mousemove|stopPropagation
            on:mouseup|stopPropagation>
            <div class="p-1 flex justify-center text-black">+</div>
            {#if addingColumn}
              <div
                class="popout absolute right-0 top-0 mt-7 shadow-lg border z-40 bg-white p-4 rounded text-left space-y-2 cursor-auto"
                use:clickOutside
                on:outclick={() => (addingColumn = false)}>
                <div>Add Column</div>
                <input
                  bind:this={newColumnInput}
                  class="p-2 border rounded"
                  type="text"
                  placeholder="Column name"
                  on:keydown={handleNewColumnKeyDown}
                  on:click|stopPropagation
                  bind:value={newColumnLabel} />
              </div>
            {/if}
          </th>
        {/if}
        {#if rowDelete && !columnAddable}
          <th class="col-header"><div class="p-1">&nbsp;</div></th>
        {/if}
      </tr>
    {/if}
    {#each sortedData as row, rowIndex (row)}
      {@const flag = flaggedRows[rowIndex]}
      <tr data-row={rowIndex}>
        {#if rowSelect}
          <td class="text-center" class:border-b-gray-500={divs[rowIndex + 1]}>
            <input type="radio" bind:group={selectedRow} value={rowIndex + 1} />
          </td>
        {/if}
        {#if rowHeader}
          <th
            class="row-header"
            class:bg-blue-100={selection.selected[`${rowIndex}.h`]}
            class:border-b-gray-500={divs[rowIndex + 1]}
            class:border-b-blue-400={selection.bottom[`${rowIndex}.h`]}
            data-row={rowIndex}
            >{rowIndex + 1}
            {#if flag}
              <button class="warning-button">
                <WarningIcon />
                <div class="message">
                  <div class="message-text">
                    {flag.message}
                  </div>
                </div>
              </button>
            {/if}
          </th>
        {/if}
        {#each cols as column, colIndex (column.prop)}
          {@const val = get(row, column.prop)}
          {@const cellCoords = `${rowIndex}.${colIndex}`}
          {@const cellDisabled = get(cells, [`${rowIndex}.${column.prop}`, "disabled"])}
          {@const isChild =
            column.isChild && !column.isFirst && !selection.left[cellCoords] && !fillEdges?.left[cellCoords]}
          <td
            class="dg-cell"
            class:dark-cols={darkCols}
            class:child-col={isChild}
            class:top-row={!colHeader && rowIndex === 0}
            class:warning={warningHash[cellCoords]}
            class:invalid={invalidHash[cellCoords]}
            class:selected-row={highlightedHash[rowIndex + 1]}
            class:selected={selection.selected[cellCoords]}
            class:last-in-div={divs[rowIndex + 1]}
            class:bottom-selection={selection.bottom[cellCoords]}
            class:left-selection={selection.left[cellCoords]}
            class:drop-target={isDraggingColumn && columnDropTarget === colIndex && !isChild}
            class:bottom-filling={fillEdges?.bottom[cellCoords]}
            class:left-filling={fillEdges?.left[cellCoords]}
            class:is-dragging={isDraggingColumn && columnsToDrag[colIndex]}
            data-row={rowIndex}
            data-column={colIndex}
            tabindex="0">
            {#if editable && $focused.row === rowIndex && $focused.col === colIndex}
              <ActiveInput
                value={val}
                rowData={row}
                row={rowIndex}
                column={colIndex}
                props={column}
                type={column.type}
                disabled={cellDisabled}
                rowType="data"
                on:updateValue={(e) => updateValue(rowIndex, column.prop, e.detail.value)} />
            {:else}
              <svelte:component
                this={renderers[column.type] || TextRenderer}
                value={val}
                rowData={row}
                disabled={cellDisabled || column.disabled}
                readOnly={column.readOnly}
                {...column} />
            {/if}
            {#if editable && fillDown && fillDown[0] === rowIndex && fillDown[1] === colIndex}
              <FilldownControl on:dragstart={fillDragstart} on:drag={fillDrag} on:dragend={fillDragend} />
            {/if}
          </td>
        {/each}
        {#if rowDelete}
          <td class="border-l border-b text-gray-400">
            <div class="px-2 trash-icon cursor-pointer" on:click={() => deleteRow(rowIndex)}>
              <TrashIcon />
            </div>
          </td>
        {:else if columnAddable}
          <td />
        {/if}
      </tr>
    {/each}
    {#if emptyRow}
      <tr data-row={sortedData.length}>
        {#if rowSelect}
          <td class="text-center" />
        {/if}
        {#if rowHeader}
          <th class="row-header">{sortedData.length + 1}</th>
        {/if}
        {#each cols as column, colIndex (column.prop)}
          {@const val = get(emptyRowData, column.prop)}
          {@const cellCoords = `${sortedData.length}.${colIndex}`}
          {@const isChild =
            column.isChild && !column.isFirst && !selection.left[cellCoords] && !fillEdges?.left[cellCoords]}
          <td
            class="dg-cell"
            class:dark-cols={darkCols}
            class:child-col={isChild}
            class:selected-row={highlightedHash[sortedData.length]}
            class:selected={selection.selected[cellCoords]}
            class:bottom-selection={selection.bottom[cellCoords]}
            class:left-selection={selection.left[cellCoords]}
            class:drop-target={isDraggingColumn && columnDropTarget === colIndex && !isChild}
            class:bottom-filling={fillEdges?.bottom[cellCoords]}
            class:left-filling={fillEdges?.left[cellCoords]}
            class:is-dragging={isDraggingColumn && columnsToDrag[colIndex]}
            data-row={sortedData.length}
            data-column={colIndex}
            tabindex="0">
            {#if editable && $focused.row === sortedData.length && $focused.col === colIndex}
              <ActiveInput
                value={val}
                row={sortedData.length}
                column={colIndex}
                props={column}
                type={column.type}
                rowType="empty"
                on:updateValue={(e) => addNewRow({ [column.prop]: e.detail.value }, e.detail.shift)} />
            {:else if val !== undefined}
              <div class="text-gray-400">
                <svelte:component
                  this={renderers[column.type] || TextRenderer}
                  value={val}
                  {...column}
                  emptyRow />
              </div>
            {/if}
          </td>
        {/each}
        {#if rowDelete || columnAddable}
          <td class="border-b" />
        {/if}
      </tr>
    {/if}
  </table>
</div>

<Modal
  bind:this={confirmModal}
  on:confirm={deleteColumn}
  buttons={[
    { label: "Cancel", type: "cancel" },
    { label: "Delete", type: "confirm", style: "danger" },
  ]}
  closeOnOutclick>
  <div slot="title">Delete Column</div>
  <div slot="content">
    <div>Are you sure you want to delete this column?</div>
    <div class="my-2 flex items-center space-x-2">
      <div>Name:</div>
      <div class="font-bold">{cols[columnToRemove].label}</div>
    </div>
  </div>
</Modal>

<style lang="scss">
  table {
    border-collapse: separate;
    border-spacing: 0;
  }

  th,
  td {
    padding: 0;
  }

  th {
    font-weight: normal;
    @apply text-gray-500;
  }

  th.col-header {
    @apply z-30 bg-gray-100 sticky top-0 border-b text-left text-xs relative;
  }

  th.col-header > div:not(.popout) {
    @apply border-t w-full h-full border-l;
  }

  th.col-header:last-of-type {
    @apply border-r;
  }

  th.row-header {
    @apply p-1 bg-gray-100 text-center border-b border-l cursor-default relative;

    .warning-button {
      @apply absolute top-1 left-0 text-red-500;

      .message {
        @apply absolute text-left left-5 top-0 text-black z-30 w-64;
        visibility: hidden;

        .message-text {
          @apply py-1 px-2 bg-red-50 text-red-500;
          width: fit-content;
        }
      }

      &:hover {
        .message {
          visibility: visible;
        }
      }
    }
  }

  td.dg-cell,
  .dg-header-cell {
    @apply border-l border-b relative;

    &.dark-cols {
      @apply border-l-gray-400;
    }

    &.top-row {
      @apply border-t;
    }

    &.child-col {
      @apply border-l-transparent;
    }

    &.selected {
      @apply bg-blue-100;
    }

    &.last-in-div {
      @apply border-b-gray-500;
    }

    &.bottom-selection {
      @apply border-b-blue-400;
    }

    &.left-selection {
      @apply border-l-blue-400;
    }

    &.drop-target {
      @apply border-l-gray-800;
    }

    &.bottom-filling {
      @apply border-b-red-400;
    }

    &.left-filling {
      @apply border-l-red-400;
    }

    &.is-dragging {
      @apply opacity-30;
    }
  }

  td:last-of-type {
    @apply border-l border-r;
  }

  tr {
    .trash-icon {
      visibility: hidden;
    }
  }

  tr:hover {
    .trash-icon {
      visibility: visible;
    }
  }

  td {
    outline: none;

    &:focus {
      outline: none;
    }
  }

  td.selected-row {
    @apply bg-gray-100;
  }

  td.warning {
    @apply bg-amber-200;
  }

  td.invalid {
    @apply bg-red-200;
  }
</style>
