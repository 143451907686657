function drawingSrcGetter(supabasePrefix: string, iiifPrefix: string) {
    const drawingsPrefix = `${supabasePrefix}/storage/v1/render/image/public/drawings`;
    const iiifDrawingsPrefix = `${iiifPrefix}/iiif/2/drawings`;


    return function drawingSrc(drawing, { width = 400, height = 400, full = false } = {}) {
        if (!drawing) return "";
        if (drawing.type === "iiif") {
            const path = encodeURIComponent(drawing.path);
            let clip = "full";
            if (drawing.clip) {
                const { x, y, width: w, height: h } = drawing.clip;
                clip = `${x},${y},${w},${h}`;
            }

            return full
                ? `${iiifDrawingsPrefix}/${path}/${clip}/full/0/default.jpg`
                : `${iiifDrawingsPrefix}/${path}/${clip}/${width},/0/default.jpg`;

        } else {
            const path = encodeURIComponent(drawing.preview_object_id);

            return full
                ? `${drawingsPrefix}/${path}`
                : `${drawingsPrefix}/${path}?width=${width}&height=${height}&resize=contain`;
        }
    };
}

export { drawingSrcGetter }
