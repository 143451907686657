<script>
  import { draggable } from "svelte-utilities";
</script>

<div class="container" use:draggable on:drag on:dragstart on:dragend>
  <div class="inner" />
</div>

<style>
  div.container {
    position: absolute;
    z-index: 20;
    right: -5px;
    bottom: -5px;
    width: 8px;
    height: 8px;
    padding: 1px;
    cursor: crosshair;
    @apply bg-white;
  }

  div.inner {
    width: 100%;
    height: 100%;
    @apply bg-blue-500;
  }
</style>
