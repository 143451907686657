import Polyline from "./polyline.js";
import transformPt from "../utils/transform-pt.js";
import { sagittaArc } from "../utils/sagitta-arc.js";
import { sweep } from "../utils/sweep.js";
import { fillet } from "../utils/fillet.js";

class Polygon extends Polyline {
  constructor(pts) {
    super(pts);
    this.type = "polygon";
  }

  render(options) {
    const ctx = options.ctx;
    const annoScale = options.annoScale;

    const [start, ...rest] = this.vertices;
    ctx.beginPath();
    ctx.moveTo(start.x, start.y);
    rest.forEach((v, i) => {
      if (v.fillet) {
        const { a, b, saf, invalid } = fillet(
          this.vertices[i],
          v,
          this.vertices[(i + 2) % this.vertices.length],
          v.fillet,
        );

        if (invalid) {
          ctx.lineTo(v.x, v.y);
        } else {
          ctx.lineTo(a.x, a.y);
          ctx.arcTo(b.x, b.y, v.fillet, "0", saf);
        }
      } else if (v.bulge) {
        const a = this.vertices[i];
        const { r, sa, ea, ccw } = sagittaArc(a, v, v.bulge);
        const s = sweep(sa, ea, ccw);
        const laf = s > Math.PI ? "1" : "0";
        const sf = ccw ? "1" : "0";
        ctx.arcTo(v.x, v.y, r, laf, sf);
      } else {
        ctx.lineTo(v.x, v.y);
      }
    });

    if (start.bulge) {
      const a = rest[rest.length - 1];
      const { r, sa, ea, ccw } = sagittaArc(a, start, start.bulge);
      const s = sweep(sa, ea, ccw);
      const laf = s > Math.PI ? "1" : "0";
      const sf = ccw ? "1" : "0";
      ctx.arcTo(start.x, start.y, r, laf, sf);
    }

    ctx.closePath();

    ctx.style("stroke", options.stroke);
    ctx.style("fill", options.fill);
    ctx.style("lineWidth", options.lineWidth * annoScale);
    if (options.lineDash) {
      ctx.style(
        "lineDash",
        options.lineDash ? options.lineDash.map((l) => l * annoScale) : [],
      );
    }
    ctx.fill();
    ctx.stroke();
    if (options.lineDash) {
      ctx.style("lineDash", []);
    }
  }

  transform(matrix) {
    const vertices = this.vertices.map((pt) => ({
      ...pt,
      ...transformPt(pt, matrix),
    }));
    return new Polygon(vertices);
  }
}

export default Polygon;
