export type Result<T, E extends Error> = Ok<T> | Err<E>;

export type Ok<T> = {
  ok: true;
  value: T;
};

export type Err<E extends Error> = {
  ok: false;
  err: E;
};

export const ok = <T>(value: T): Ok<T> => ({ ok: true, value });

export const err = <E extends Error>(err: E): Err<E> => ({ ok: false, err });

export function isOk<T, E extends Error>(value: Result<T, E>): value is Ok<T> {
  return typeof value === "object"
    && value !== null
    && value?.ok === true
    && value?.value !== undefined;
}

export const unwrap = <T>(obj: Ok<T> | T): T => {
  if (typeof obj === "object" && obj !== null) {
    return "ok" in obj ? obj.value : obj;
  } else {
    return obj;
  }
};

export const panic = <E extends Error>(e: Err<E>): never => {
  throw e.err;
};
