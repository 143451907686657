const pi2 = Math.PI / 2;
const q = [pi2, Math.PI, 3 * pi2, 0];

function quadrant(angle) {
  if (angle >= 0) {
    if (angle > pi2) return 1;
    return 0;
  }

  if (angle < -pi2) return 2;
  return 3;
}

export function quadrants(sa, ea, ccw) {
  const sq = quadrant(sa);
  const eq = quadrant(ea);

  if (ccw) {
    const quads = (eq + 4 - sq) % 4;
    return Array.apply(null, Array(quads)).map((_, i) => q[(sq + i) % 4]);
  }

  const quads = (sq + 4 - eq) % 4;
  return Array.apply(null, Array(quads)).map((_, i) => q[(sq + 7 - i) % 4]);
}
