const PIx2 = 2 * Math.PI;

function posAngle(angle) {
  const or = angle % PIx2;
  return or < 0 ? or + PIx2 : or;
}

export function distX(a, b) {
  return b.x - a.x;
}

export function distY(a, b) {
  return b.y - a.y;
}

export function distSq(a, b) {
  const dx = distX(a, b);
  const dy = distY(a, b);

  return dx * dx + dy * dy;
}

export function dist(a, b) {
  return Math.sqrt(distSq(a, b));
}

export function slope(a, b) {
  return (b.y - a.y) / (b.x - a.x);
}

export function equal(v1, v2) {
  return v1.x === v2.x && v1.y === v2.y;
}

function apply(func, vectors) {
  const a = vectors.splice(0, 1)[0];

  return vectors.reduce(
    (v, vec) => {
      return func(v, vec);
    },
    { ...a }
  );
}

export function add(...vectors) {
  return apply((a, b) => ({ x: a.x + b.x, y: a.y + b.y }), vectors);
}

export function subtract(...vectors) {
  return apply((a, b) => ({ x: a.x - b.x, y: a.y - b.y }), vectors);
}

export function rotate(a, angle) {
  const x = a.x * Math.cos(angle) - a.y * Math.sin(angle);
  const y = a.x * Math.sin(angle) + a.y * Math.cos(angle);

  return { x, y };
}

export function scale(a, mag) {
  return { x: a.x * mag, y: a.y * mag };
}

export function lengthSq(a) {
  return a.x * a.x + a.y * a.y;
}

export function length(a) {
  return Math.sqrt(lengthSq(a));
}

export function dot(a, b) {
  return a.x * b.x + a.y * b.y;
}

export function cross(a, b) {
  return a.x * b.y - a.y * b.x;
}

export function angle(a) {
  return Math.atan2(a.y, a.x);
}

export function angleVec(angle) {
  return {
    x: Math.cos(angle),
    y: Math.sin(angle),
  };
}

export function angleBetween(a, b) {
  return Math.acos(dot(a, b) / (length(a) * length(b)));
}

export function ccwAngleBetween(a, b) {
  return posAngle(Math.atan2(cross(a, b), dot(a, b)));
}

export function cwAngleBetween(a, b) {
  const ccw = ccwAngleBetween(a, b);
  return posAngle(PIx2 - ccw);
}

export function midpoint(a, b) {
  return scale(add(a, b), 0.5);
}

export function multiply(...vectors) {
  return apply((a, b) => ({ x: a.x * b.x, y: a.y * b.y }), vectors);
}

export function divide(...vectors) {
  return apply((a, b) => ({ x: a.x / b.x, y: a.y / b.y }), vectors);
}

export function normalize(a) {
  const l = length(a);

  if (l === 0) return { x: 1, y: 0 };

  return divide(a, { x: l, y: l });
}

export function reverse(a) {
  return { x: -a.x, y: -a.y };
}

export function orientation(a, b, c) {
  /*

  orientation of 3 points:
  returns:
    0  =>  colinear
  > 0  =>  clockwise
  < 0  =>  anti-clockwise

  */
  return (b.y - a.y) * (c.x - b.x) - (b.x - a.x) * (c.y - b.y);
}
