import { add, scale, angleVec } from "vector";
import { almostEqualPt } from "overline";
import transformPt from "../utils/transform-pt.js";
import { sagittaArc } from "../utils/sagitta-arc.js";
import { quadrants } from "../utils/quadrants.js";
import { sweep } from "../utils/sweep.js";
import { fillet } from "../utils/fillet.js";

class Polyface {
  constructor(faces) {
    this.faces = faces;
    this.type = "polyface";
  }

  render(options) {
    // Rendering on canvas, required option: "ctx"
    const ctx = options.ctx;
    const annoScale = options.annoScale;

    ctx.beginPath();
    this.faces.forEach((face) => {
      const [start, ...rest] = face;

      if (start.fillet) {
        const { a, b, saf, invalid } = fillet(
          rest[rest.length - 1],
          start,
          rest[0],
          start.fillet,
        );

        if (invalid) {
          ctx.moveTo(start.x, start.y);
        } else {
          ctx.moveTo(a.x, a.y);
          ctx.arcTo(b.x, b.y, start.fillet, "0", saf);
        }
      } else {
        ctx.moveTo(start.x, start.y);
      }

      rest.forEach((v, i) => {
        if (v.fillet) {
          const { a, b, saf, invalid } = fillet(
            face[i],
            v,
            face[(i + 2) % face.length],
            v.fillet,
          );
          if (invalid) {
            ctx.lineTo(v.x, v.y);
          } else {
            ctx.lineTo(a.x, a.y);
            ctx.arcTo(b.x, b.y, v.fillet, "0", saf);
          }
        } else if (v.bulge) {
          const a = face[i];
          const { r, sa, ea, ccw } = sagittaArc(a, v, v.bulge);
          const s = sweep(sa, ea, ccw);
          const laf = s > Math.PI ? "1" : "0";
          const sf = ccw ? "1" : "0";
          ctx.arcTo(v.x, v.y, r, laf, sf);
        } else {
          ctx.lineTo(v.x, v.y);
        }
      });

      if (start.bulge) {
        const a = rest[rest.length - 1];
        const { r, sa, ea, ccw } = sagittaArc(a, start, start.bulge);
        const s = sweep(sa, ea, ccw);
        const laf = s > Math.PI ? "1" : "0";
        const sf = ccw ? "1" : "0";
        ctx.arcTo(start.x, start.y, r, laf, sf);
      }

      ctx.closePath();
    });

    ctx.style("stroke", options.stroke);
    ctx.style("fill", options.fill);
    ctx.style("lineWidth", options.lineWidth * annoScale);
    ctx.style(
      "lineDash",
      options.lineDash ? options.lineDash.map((l) => l * annoScale) : [],
    );
    ctx.fill();
    ctx.stroke();
    ctx.style("lineDash", []);
  }

  get bbox() {
    let xmin = Infinity;
    let xmax = -Infinity;
    let ymin = Infinity;
    let ymax = -Infinity;

    this.faces.forEach((face) => {
      face.forEach((v, i) => {
        const next = face[(i + 1) % face.length];

        if (next.bulge) {
          const { c, r, sa, ea, ccw } = sagittaArc(v, next, next.bulge);
          const angles = quadrants(sa, ea, ccw);
          const av = angles.map((a) => add(c, scale(angleVec(a), r)));
          const ax = av.map((vec) => vec.x);
          const ay = av.map((vec) => vec.y);

          xmin = Math.min(...ax, v.x, xmin);
          ymin = Math.min(...ay, v.y, ymin);
          xmax = Math.max(...ax, v.x, xmax);
          ymax = Math.max(...ay, v.y, ymax);
        } else {
          xmin = Math.min(v.x, xmin);
          ymin = Math.min(v.y, ymin);
          xmax = Math.max(v.x, xmax);
          ymax = Math.max(v.y, ymax);
        }
      });
    });

    if (xmin === Infinity) xmin = 0;
    if (xmax === -Infinity) xmax = 0;
    if (ymin === Infinity) ymin = 0;
    if (ymax === -Infinity) ymax = 0;

    return { xmin, xmax, ymin, ymax };
  }

  transform(matrix) {
    const faces = this.faces.map((face) =>
      face.map((pt) => ({ ...pt, ...transformPt(pt, matrix) })),
    );

    return new Polyface(faces);
  }
}

export default Polyface;
