import { Drawing } from "drawing";

export function dwgShape() {
  return [
    [
      { x: -7, y: -7 },
      { x: 7, y: -7 },
      { x: 7, y: 7 },
      { x: -7, y: 7 },
    ],
    [
      { x: -7, y: -3 },
      { x: -3, y: 1 },
      { x: 1, y: -3 },
      { x: 3, y: -1 },
      { x: 7, y: -5 },
    ],
    [
      { x: -0.5, y: 3, bulge: 1 },
      { x: 2.5, y: 3, bulge: 1 },
      { x: -0.5, y: 3, bulge: 1 },
    ],
  ];
}

export function drawingSymbol(locId, pos, highlighted) {
  const shapes = dwgShape();
  const [outline, ...interior] = shapes;

  const stroke = highlighted ? "white" : "black";
  const fill = highlighted ? "black" : "white";

  return new Drawing()
    .symbol(
      [
        { type: "polyface", faces: [outline] },
        ...interior.map((s) => ({ type: "polyline", vertices: s })),
      ],
      pos,
      { offset: { x: 0, y: -15 } },
    )
    .style({ stroke, fill })
    .name(`dwgbtn_${locId}`);
}

export default drawingSymbol;
