import { add, scale, angleVec } from "vector";
import transformPt from "../utils/transform-pt.js";
import { sagittaArc } from "../utils/sagitta-arc.js";
import { quadrants } from "../utils/quadrants.js";
import { sweep } from "../utils/sweep.js";
import { fillet } from "../utils/fillet.js";

class Polyline {
  constructor(pts) {
    this.vertices = pts;
    this.type = "polyline";
  }

  render(options) {
    // Rendering on canvas, required option: "ctx"
    const ctx = options.ctx;
    const annoScale = options.annoScale;

    const [start, ...rest] = this.vertices;
    ctx.beginPath();
    ctx.moveTo(start.x, start.y);
    rest.forEach((v, i) => {
      const next = this.vertices[i + 2];
      if (v.fillet && next) {
        const { a, b, saf, invalid } = fillet(
          this.vertices[i],
          v,
          next,
          v.fillet,
        );

        if (invalid) {
          ctx.lineTo(v.x, v.y);
        } else {
          ctx.lineTo(a.x, a.y);
          ctx.arcTo(b.x, b.y, v.fillet, "0", saf);
        }
      } else if (v.bulge) {
        const a = this.vertices[i];
        const { r, sa, ea, ccw } = sagittaArc(a, v, v.bulge);
        const s = sweep(sa, ea, ccw);
        const laf = s > Math.PI ? "1" : "0";
        const sf = ccw ? "1" : "0";
        ctx.arcTo(v.x, v.y, r, laf, sf);
      } else {
        ctx.lineTo(v.x, v.y);
      }
    });

    ctx.style("stroke", options.stroke);
    ctx.style("lineWidth", options.lineWidth * annoScale);
    if (options.lineDash) {
      ctx.style(
        "lineDash",
        options.lineDash ? options.lineDash.map((l) => l * annoScale) : [],
      );
    }
    ctx.stroke();
    if (options.lineDash) {
      ctx.style("lineDash", []);
    }
  }

  get bbox() {
    let xmin = Infinity;
    let xmax = -Infinity;
    let ymin = Infinity;
    let ymax = -Infinity;

    const len = this.vertices.length;
    this.vertices.forEach((v, i) => {
      const next = this.vertices[(i + 1) % len];

      if (next.bulge) {
        const { c, r, sa, ea, ccw } = sagittaArc(v, next, next.bulge);
        const angles = quadrants(sa, ea, ccw);
        const av = angles.map((a) => add(c, scale(angleVec(a), r)));
        const ax = av.map((vec) => vec.x);
        const ay = av.map((vec) => vec.y);

        xmin = Math.min(...ax, v.x, xmin);
        ymin = Math.min(...ay, v.y, ymin);
        xmax = Math.max(...ax, v.x, xmax);
        ymax = Math.max(...ay, v.y, ymax);
      } else {
        xmin = Math.min(v.x, xmin);
        ymin = Math.min(v.y, ymin);
        xmax = Math.max(v.x, xmax);
        ymax = Math.max(v.y, ymax);
      }
    });

    if (xmin === Infinity) xmin = 0;
    if (xmax === -Infinity) xmax = 0;
    if (ymin === Infinity) ymin = 0;
    if (ymax === -Infinity) ymax = 0;

    return { xmin, xmax, ymin, ymax };
  }

  transform(matrix) {
    const vertices = this.vertices.map((pt) => ({
      ...pt,
      ...transformPt(pt, matrix),
    }));
    return new Polyline(vertices);
  }
}

export default Polyline;
