<svelte:options strictprops={false} />

<script>
  import { getContext, createEventDispatcher } from "svelte";
  import { exitEvents, directions } from "./constants.js";

  export let value;
  export let readOnly = false;
  export let disabled = false;
  export let options;
  export let optionMap = {};

  export const focus = () => {
    container.focus();
  };

  $: current = options.indexOf(value);
  $: option = optionMap[value];
  $: console.log(option && option.borderColor);

  const dispatch = createEventDispatcher();

  const { focused } = getContext("datagrid");

  let container;

  async function keydown(e) {
    if (readOnly || disabled) return;
    if (e.key === "Enter" || e.key === " ") {
      const next = options[(current + 1) % options.length];
      dispatch("updateValue", { value: next });
    } else if (exitEvents[e.key]) {
      focused.move(directions[e.key], e.shiftKey);
    }
  }

  function update() {
    if (readOnly || disabled) return;
    const next = options[(current + 1) % options.length];
    dispatch("updateValue", { value: next });
  }
</script>

<div
  bind:this={container}
  class="p-1 w-full flex items-center space-x-1 outline-none"
  class:text-gray-500={disabled}
  on:keydown={keydown}
  on:click={update}
  tabindex="0">
  {#if option}
    <div
      data-clickthrough="clickthrough"
      style={`background-color:${option.color || "blue"};border-color:${
        option.borderColor || option.color || "blue"
      };`}
      class="w-3 h-3 rounded-lg border" />
    <div>{option.label}</div>
  {:else}
    <div class="w-3 h-3 border border-gray-500 rounded-lg" data-clickthrough="clickthrough" />
  {/if}
</div>
