// import type { NoInfer } from "./utility-types";

// https://www.typescriptlang.org/play?#code/C4TwDgpgBAcg9gSQHYDMICcA8AVAfFAXigG1sBdUqCAD2AiQBMBnKAQyRCgH4oAGKAFxQkEAG4YyAbgBQ0lAFckAY2ABLOEirUAFq3lNgOBAHMkcdNCIjx6XAAoIQ+MjRZsJsxdwBKIdYxQAN7SUFDA2uhwAO5Q2OAQAKLokeh23jIAvrKgkFAAYnBwAEKs6CUAXoRQAER5APJ11VAAPjVFAIIASk2t1R0AWtUycooq6ppK2hBKANZ2IEIFxaUV3kEhoVBMUarAk-NrwZvHUEqsTNC1DdUCGycnShpMcAA2EAB0L3DGdld1AITVdJ3e6bABGFlYMxkoNO50uHW6t1hx0eSGeb0+31+iMBwJR4Mh0JBqPhbXag2RBNOT1eHy+Pz6FLxMOpEIgUNZ9wYEBQehewCpBJoun0wAOXKgWSyI2Uag0pymswATKx5otCiUyqxyocQdtdvsQHrYWcLjV6o0hSi0Rj6di-iySaD2ZznaEeXz5ALrbCRXoDJgmd17MbJFAAPQRqAAZW0cG9DCoyXMIOlsgUcvGiumM2VSnV+U1Kx1Js2Br22gO61NZL+N3dD1pmIZv0tTupUFdxNhnv5gsbx39YqDiJ65MGofSkejdQA0mnpBkgA
export function exhaust(e: never, msg?: string): never
// export function exhaust<TIgnore = never>(e: NoInfer<TIgnore>, msg?: string)
export function exhaust(_: any, msg: string = "Unexpected input"){
  throw TypeError(msg);
}

type Guard<T> = (x: any) => x is T;

/* To support using instantiation expressions */
type Garrison<T> = () => Guard<T>;

export function insist<T>(e: T, guard: Guard<T>, msg?: string): asserts e is T;
export function insist<T>(e: any): asserts e is T;
export function insist(e, guard?, msg = "Unexpected input") {
  if (guard && !guard(e)) {
    throw TypeError(msg);
  }
}
// https://www.typescriptlang.org/play?#code/GYVwdgxgLglg9mABAUwB4AsCGIDOUAUyAXImMgG7IBOAlCWZVYgN4BQiHiU6VcA7ogAqATwAOyAKJVeVfDQDcrAL6tWUMckQBxEJioATADyCAfIgC8ifKhKYwwmhbOpEMHEMWtQkWAkT7ke0MJABohE0ISUMQAc10DEh09I1M6REwcHGood003RAlUCAAbEADgsNMWdk4YYCs45MIaRzZOdq4efiENKRk5RXaVFVV1cUQAMTg4ACE9OYAvC0QAIgmAeXWVxAAfVZmAQQAlbb2Vw4AtFc9vaHgkNznZG0Q8KhgwGLSXfPPj7banCoyCgICoSBc5ih+3+ihGt18DxwT3WVEW1hIbw+XxIP3cfxOuxhV2q7WBoPBiEh0IJpz21MsfyucNUCPurhwB3s62AhgOKFQUEC+ncR2QmH0CGKwj50kwMrswhMyvweioJAOaSSBj5AG0wCAALYAI2oAF0zICOOSwUg2QgMa8oO9Pt8OYgDvqjaaqGbSR1rSDbelpAA6D4lMrIHDWBQ1DhKFmsCAIPCIQ5HAD6l2WutpYSZKz9GUQKbAeE8ZbTomB+mWbi5wh5+Az2YOFzjydTUEQNemdcsDe5wHweY2WwLAEUAKoADVnRfS7irUE7XnAdz8EHQyAgAGt8MISFNZvNMAtWvHXnwYFBt4fLwGOBAMpo1psVkQr0-S6m4MVkFDYo4BifB33WABCFY4x-dpjWBTA90GJ8XyyGETi-WDOCrf9AOA0CCSgmCsMQeDxSQ79sNfYlP0ogMcIAoCQLAy4iOQrCyMQ9iOgCYBsGKKBMJItAsFwAgHG44ZWQ3RFSx3fcACZMEPY9pieRZH04HAbzvdAH39ejqPA2iSN-ctcKYgjxzYuiOk4iif14-jBNs9oROwPAH3kRAAHofMQABldA4BAYo62oGQrykrtyx7Vt1izHNLDzDMVgLS5FxLFdPDGTQ+EwdBllyuB6nixL2y9E1zU8dcfHZbddz3BTjRUyY1LPC8DOvW97wcLr2lQt9xxMkiGLw5jwJs0z7O49onNClzTICexDzCR49BoVzOHcsSvN8-ygpCsKUGkOAqCi5RpLqrd5KaiBWpPdTz00jhtJ6vS+qtDpBtWYahKwsbLLA6zoNmp8Zts+aBP+2DluEVaOUbZtW0uFoto4HbPIk-bEHWABpC6VA4IA
export function deny<E, T>(e: E, guard: Guard<T> | Garrison<T>, msg?: string): asserts e is Exclude<E, T>;
export function deny<E, T>(e: E): asserts e is Exclude<E, T>;
export function deny<T>(e, guard?: Guard<T> | Garrison<T>, msg = "Unexpected input") {
  if (guard) {
    let res: boolean;
    const cond = guard(e);
    if (typeof cond === "function") {
      res = cond(e);
    } else if (typeof cond == "boolean" ) {
      res = cond;
    } else {
      console.warn("Invalid guard");
      res = false;
    }

    if (res) {
      throw TypeError(msg);
    }
  }
}

/**
 * Create a guard that is never true.
 * Intended to be used only with @see {deny}
 *
 * Can be used with Instantiation expressions
 * https://devblogs.microsoft.com/typescript/announcing-typescript-4-7-beta/#instantiation-expressions
 *
 */
export function isEver<T>(): Guard<T> {
  return function guard(x: any): x is T {
    return false;
  }
}
/**
 * Create a guard that is always true
 */
export function isAlways<T>(): Guard<T> {
  return function guard(x: any): x is T {
    return true;
  }
}

/* export function isAnyOf<A extends Readonly<Array<any>>>(arr: A): Guard<A[number]> {
  return function(x: string): x is A[number] {
      return arr.includes(x);
  };
} */

// https://www.typescriptlang.org/play?#code/FAegVABAZgrgdgYwC4EsD2cICcCmArHZAHgBUA+ACgEoIBvYCR7HJGLTWRVDIgVUoAeALgi8a9JpOat2EARACGAZwgBRAQgA2MACY4+AGgjkA3AyYBfYBYhgQwYJ2TpMuAsRJGSAJXyEkgiKezHhBvu5I4uaMuDKY8spqGtp6pF7h-mRmVo7wzhgh-gBMaRDeEDgCSDhwOirUEAC8ZIpwAJ6BxkZuIt5RkrFs8Yoq6lq6+sG+cSRtAA763mRZ1g5O3JjVk5T9TIOytDaJJNkOCBhKSBAA7goAFk0QW0QARABCAILeLxAAPhDvD4ALReK1yXBcEDgCiwWDQ1xwWFIOzo0WkQ2geQ2UJhcOufE6YhEyiUiKQKnkKFGyQmhmMLUOp1AIExEIK0Nh8NK-AowlEVGJSlJWHJcggVKS41SvC8DIsZnB+UwlTuChglwoOBEcBwADdEQKoXrEajJEg7njjPMcKpOVhqEykNaIAAxNBoN4wz0AL0eLxdAHkAz9-oDvn8AZ8QQr1pCEHdCABrChtERuj1ehTe3aMJTXFBIeMpnNSBDKHAAwPBoRoqRMc5wJRoTQ4AB0mjQAHMKP6gwBCF5UMx1usAI1wCkTw5HZdJka+LxrI9LF2bbY73bDA6HtZH45wk+nddnFcBIKXy-rq5b7a7Paj26Pl-3h93jD0UDVmiQF8vFQEqrqkgxZPlYOSxgU8ZJkUCgpmm7qelgPolnmBZFm0JaSCelZBoub4ro2a63puVaPvhkgvlO+Efl+P7kUwKpqhqGEmBAIAsgAyncaAwJoOgVHaaJgWsWJxgmCCJkUo5wa6CGZr6oYAIq8AAGipg6mkwqGFncxaace5Y4dW9GMA2TY3huPakYOT7LpRtlMDRvF0X+pkXFcvqNIUyBFCmRjPGGIbziCZA7q5-6ARq2asexEABgA0kJ1hAA
/* export function typ<T>(): T {
  return {} as T;
}

export function reject<T, R extends () => any>(x: T, rej: R) {
  return x as Exclude<T, ReturnType<R>>;
}

export function narrow<T, R extends () => any>(x: T, rej: R): asserts x is Exclude<T, ReturnType<R>> {}
*/

/*
https://www.typescriptlang.org/play?#code/GYVwdgxgLglg9mABAUwB4AsCGIDOUAUyAXImMgG7IBOAlCWZVYgN4BQiHiU6VcA7ogAqATwAOyAKJVeVfDQDcrAL6tWUMckQBxEJioATADyCAfIgC8ifKhKYwwmhbOpEMHEMWtQkWAkT7ke0MJABohE0ISUMQAc10DEh09I1M6REwcHGood003RAlUCAAbEADgsNMWdk4YYCs45MIaRzZOdq4efiENKRk5RXaVFVV1cUQAMTg4ACE9OYAvC0QAIgmAeXWVxAAfVZmAQQAlbb2Vw4AtFc9vaHgkNznZG0Q8KhgwGLSXfPPj7banCoyCgICoSBc5ih+3+ihGt18DxwT3WVEW1hIbw+XxIP3cfxOuxhV2q7WBoPBiEh0IJpz21MsfyucNUCPurhwB3s62AhgOKFQUEC+ncR2QmH0CGKwj50kwMrswhMyvweioJAOaSSBj5AG0wCAALYAI2oAF0zICOOSwUg2QgMa8oO9Pt8OYgDvqjaaqGbSR1rSDbelpAA6D4lMrIHDWBQ1DhKFmsCAIPCIQ5HAD6l2WutpYSZKz9GUQKbAeE8ZbTomB+mWbi5wh5+Az2YOFzjydTUEQNemdcsDe5wHweY2WwLAEUAKoADVnRfS7irUE7XnAdz8EHQyAgAGt8MISFNZvNMAtWvHXnwYFBt4fLwGOBAMpo1psVkQr0-S6m4MVkFDYo4BifB33WABCFY4x-dpjWBTA90GJ8XyyGETi-WDOCrf9AOA0CCSgmCsMQeDxSQ79sNfYlP0ogMcIAoCQLAy4iOQrCyMQ9iOgCYBsGKKBMJItAsFwAgHG44ZWQ3RFSx3fcACZMEPY9pieRZH04HAbzvdAH39ejqPA2iSN-ctcKYgjxzYuiOk4iif14-jBNs9oROwPAH3kRAAHofMQABldA4BAYo62oGQrykrtyx7Vt1izHNLDzDMVgLS5FxLFdPDGTQ+EwdBllyuB6nixL2y9E1zU8dcfHZbddz3BTjRUyY1LPC8DOvW97wcLr2lQt9xxMkiGLw5jwJs0z7O49onNClzTICexDzCR49BoVzOHcsSvN8-ygpCsKUGkOAqCi5RpLqrd5KaiBWpPdTzyJFYZ3naCuu0nq9L6q0OkG1ZhqErCxsssDrOg2anxm2z5oE4HYOW4RVo5J5NtMnbPIk-bAuC0LwtO86hku2rNyQBrFPuo82tPNFz00jgvt0-S-oGoyga258-0Y-Dwc2KaSJhxzkD4haEeFlbhDWzlhxbY42w7dHhIwDzxIUHH1gAaQulQOCAA
https://www.typescriptlang.org/play?#code/FAegVABAZgrgdgYwC4EsD2cICcCmArHZAHgBUA+ACgEoIBvYCR7HJGLTWRVDIgVUoAeALgi8a9JpOat2EARACGAZwgBRAQgA2MACY4+AGgjkA3AyYBfYBYhgQwYJ2TpMuAsRJGSAJXyEkgiKezHhBvu5I4uaMuDKY8spqGtp6pF7h-mRmVo7wzhgh-gBMaRDeEDgCSDhwOirUEAC8ZIpwAJ6BxkZuIt5RkrFs8Yoq6lq6+sG+cSRtAA763mRZ1g5O3JhwClhYaADupeWV1bX1NM2tHRTCXSG9VCLKSjhYSCryKKPJE4csQ7MLIhLFq0HKcDYQLY7NCHShUNZ5CHVSZwujRaRDOg2RIkbIOBAYJRICB7BQACyaEGRRAARAAhACC3hpEAAPhB6QyAFo0la5LguSHbXZ7F6kVESJiDWTrQVQkV8TpiR5KZ6vd4QT5JcapXheEEWPGgEDQRFy4X7Ur8a4iZUjNVvOSar46-R64wGsz8-KYSpkhQwIkUHAiOA4ABuLwekIjLzRkiQZJFxnmOFU0Kw1CNSFTEAAYmg0HTtsWAF6Uml5gDyVZZ7M5zLZHMZPK9soKCDJhAA1hQ2iIC0WSwpS-0mEo9igkJ2+2OpBAEMocBzq7WhOj54wCXAlGhNDgAHSaNAAcwolZrAEIaVQzJupAAjXAKbt3zeL57Npk09f3yTb3d9yPU9z0Zbxr1vDdNyfHAXzfecP2XTkeV-P8t0JPdD2PM9kIg+C-xguCoMYPQoADTQkFQtC-QDIM2kgyQrDBM0Oy7BBuyKBQ+wHQtiywMs5wgCcpxnej43fJcVxrH9iKkADMOAnDVzw2TJEI19ZNI8jKNUpgaMDJBZxMCAQBNABlMk0BgTQdAqDN0SYhEBVYnsigfbj8144dy3rABFXgAA0ApvcTGGE6cyVnUK5Mki81109CdwU7Dz2Um98PvdSMqYLTrJ0tDGHlfY+yMakGzrL8eTIBiCv0ujbxMk0qwAaQc6wgA
https://www.typescriptlang.org/play?ts=4.9.3#code/FAFwngDgpgBAYgewQIQIYCc0C8YF4YBEcA8sQTAD6HICCASuVQbQFoEDcoksAygK4AjEOlQBjEAB4AKgBoYAVQB8eGFJgAyGAFEAHqIA2fACZRpcpcC7QYADRX8hI8RMQoM2OUVIFFV2AE0VXQNjU1c0TFQsOXD3KN9gAHoAKhgAMz4AO3EASwRMmByAc0yEdBzMooAmaQBJErKoRQAKAEoYAG9gGB6YdCgQPnQCjOyQPILi0vLKgGZpFqgALlVWldQAZw2odBANmFgc-eDDEzNVeummzoBfYDvgUdz8woaZorq3qDkpLX0oAC2UEyIEWK1+-yBILWME2212+0Oxz0p1MEMBwJAP0ujWUHQeT3GLymZQqRXmUhx-Ra7S6vT6AyGIyyz0yC2ay1WKxOoXOlK+eO69N6-UGwwOsORITOsguAs49LuBJZRMmbzJADZPlcDjoQMCjPsaOgRGAJKhMmBFD8-hiQS0cuCqd8DuDbVCQDCeTL3ZjsV8ANqZPgAgQ7AC6gvpoqZEs22hRvPRHv9VyDIbD6EjnBuMGSiUshImr2mZO1jR+ADUMA6nV85JypNX0F7EzLm6ncZ0hT0Y+LYPHvWiO3Krooc48VcWSe8avyrlWa81HTA2nhlPOK67VM2YXCdnsJUcE9LhxhO9TboWpy9wNAjOyYWo6SLGeL8ZLVBPgKJ8hsQDAd5QDwwhkioQEPv+7zjpYv6ZP+MA8LUADitQADL2GAoYIPozQEEhqFoQQrScEBiHFDk+jgdwCBpIhKHoZwP5-gBGzFCoa64MoBGMZYZEAHIILUmRpDsCwqAGUjhpJur6pkhqwpaMAAPwwAADDAKyZFAABuEZMSkMAACIIFA+ylABADuZQANb5n4MCCcJomRA+UjKPgaiaB0AYANKFAUNlQGAtGqOG4J+eGNwGakRYvFAOgABaoHw-4clpuk7DC2l6egnBxQUCXJalIDpTAOU7HIAIbEUKxQWS2WZXlk5jMWRUpf+5b9CoFXoGCjlCSJYmbtSjW5ZwiSJAVurFZ1I1NGV81yDOnKcRuzpVTVdWgZUY07PlN6FUlHWlQAdOdGBFBsKwBuGe3oN29IgIl6AIJZzQAORpEgH0kfc9ktayM0nV1sD4L1-VOUN6Cg4o92Pb0z2ve9X0-X9DyGSZZnlQgVm2XmBbTe1JVzs6PVNTakKYio839cmmLwy+PRI29n3fQgv3foZZE8AgQLPWSxDoPxTXiZ5skGvsOkIDkRgqeVTWaV+fHcAo9h8wMiWC8LosEJZqCJT4TFE8dJUUmTCVyQp0uy3TFt6pLMA23Lqm9XWC6FO7jQqB+zuMz2gEvazqMc+jBPXq18Wm-+sxlb190HZHR2zSA5sCotG2e6OjQJ0kU2HcDJWwxn9Ylo0Kxrdn-SbbVMD1btGXjYDqqFzHzTnadl3XTAt3+09Qco+znP3BHQP9AAVlAzjuWVFpgLSAd9oVn5DhIQGhS67nfqPLeiIlU82c0YArLEkRYAv9IbJZOQgHvR8X8KPSiJssBeGQSwB4-T9-jhUCnfoCAih4RIMQAAhMRBUX96QCH6KgGykDH7P22NQegBAP5QPpHBDYv9-6ALwrQOg4C-oYN6DAqAcCEHCiQa-VgaDP5fywTggBQDmA0BYEQyhUCyEUPoT0EwaQUr6BAOgkhrdU730oUqHexY94HyqEfE+SAIjYAfj0K+N877zwRogl+hAQF0NETARh-xcEsJARw3hwpuHwMsfwwRwjLH0mJm3ee7AYCTTrolBAfB9Byx2K9dAAcpHNxkfvUQNlY7H3gEouI59tF12vrfRK994mYN0W-AxojjF-2YcA0gFjDHWM4b0OxPiHGGOceIsAcgPzxgycQ3oSogA
https://www.typescriptlang.org/play?#code/GYVwdgxgLglg9mABAUwB4AsCGIDOUAUyAXImMgG7IBOAlCWZVYgN4BQiHiU6VcA7ogAqATwAOyAKJVeVfDQDcrAL6tWUMckQBxEJioATADyCAfIgC8ifKhKYwwmhbOpEMHEMWtQkWAkT7ke0MJABohE0ISUMQAc10DEh09I1M6REwcHGood003RAlUCAAbEADgsNMWdk4YYCs45MIaRzZOdq4efiENKRk5RXaVFVV1cUQAMTg4ACE9OYAvC0QAIgmAeXWVxAAfVZmAQQAlbb2Vw4AtFc9vaHgkNznZG0Q8KhgwGLSXfPPj7banCoyCgICoSBc5ih+3+ihGt18DxwT3WVEW1hIbw+XxIP3cfxOuxhV2q7WBoPBiEh0IJpz21MsfyucNUCPurhwB3s62AhgOKFQUEC+ncR2QmH0CGKwj50kwMrswhMyvweioJAOaSSBj5AG0wCAALYAI2oAF0zICOOSwUg2QgMa8oO9Pt8OYgDvqjaaqGbSR1rSDbelpAA6D4lMrIHDWBQ1DhKFmsCAIPCIQ5HAD6l2WutpYSZKz9GUQKbAeE8ZbTomB+mWbi5wh5+Az2YOFzjydTUEQNemdcsDe5wHweY2WwLAEUAKoADVnRfS7irUE7XnAdz8EHQyAgAGt8MISFNZvNMAtWvHXnwYFBt4fLwGOBAMpo1psVkQr0-S6m4MVkFDYo4BifB33WABCFY4x-dpjWBTA90GJ8XyyGETi-WDOCrf9AOA0CCSgmCsMQeDxSQ79sNfYlP0ogMcIAoCQLAy4iOQrCyMQ9iOgCYBsGKKBMJItAsFwAgHG44ZWQ3RFSx3fcACZMEPY9pieRZH04HAbzvdAH39ejqPA2iSN-ctcKYgjxzYuiOk4iif14-jBNs9oROwPAH3kRAAHofMQABldA4BAYo62oGQrykrtyx7Vt1izHNLDzDMVgLS5FxLFdPDGTQ+EwdBllyuB6nixL2y9E1zU8dcfHZbddz3BTjRUyY1LPC8DOvW97wcLr2lQt9xxMkiGLw5jwJs0z7O49onNClzTICexDzCR49BoVzOHcsSvN8-ygpCsKUGkOAqCi5RpLqrd5KaiBWpPdTz00jhtJ6vS+qtDpBtWYahKwsbLLA6zoNmp8Zts+aBP+2DluEVaOUbZtW0uFoto4HbPIk-bEHWABpC6VA4IA
https://www.typescriptlang.org/play?#code/C4TwDgpgBAcg9gSQHYDMICcA8AVAfFAXigG1sBdUqCAD2AiQBMBnKAQyRCgH4oAGKAFxQkEAG4YyAbgBQ0lAFckAY2ABLOEirUAFq3lNgOBAHMkcdNCIjx6XAAoIQ+MjRZsJsxdwBKIdYxQAN7SUFDA2uhwAO5Q2OAQAKLokeh23jIAvrIKymoaULr6hu6m5hD2jmwcADRQqsZCJZ4QvsJiAcGhoeGRMXGQSSlpmbKgkFAAYnBwAEKs6HMAXoRQAEQTAPIbq1AAPmszAIIASjv7q0cAWqsycooq6ppK2hBKANZ2IEJTs-NL3kEQl0mFFVMBnp8AZ0ujClKwmNB1ltVgIgTD0VAlBomHAADYQAB0uLgxjsSI2AEJVuk0RiugAjCysN4yOmY+GIo6nVFs2HYvGE4mki4nKk03kMpks2mwjkHQ7XHkSzH8-FEklkq5i1nKxkQZk6jEMCAoPS44BKiU0QoGSGGqBZLJ3XKPTEvd4AJlYn2+0zmC1YiyhtJBYIhIGDbLhCLWm22lt5WKQOLVQrJce1MrpeoNWdCxtN8nNCbZ1r0BkwItO9gjkigAHp61AAMraOBFhhUZLmWmO7L3PJPd1vD1KH2TP1-QOR4Gg8HaSGAqNy8kovPopMpwUa8mZ5VQHPStkFs0W9cwstFStcs7y6419INpsbADSvekGSAA
*/
