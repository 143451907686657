import distance from "@turf/distance";
import { bucketArray } from "overline";
import type { OrderedMap } from "../ordered-map.js";
import type { TablesGenerated } from "../database.js";
import type { ItemRecordCore } from "../entities/item.js";

interface Job {
  location_coordinates: {
    longitude: number;
    latitude: number;
  } | null;
  [key: string]: any;
}

interface Supplier {
  address_coordinates: {
    longitude: number;
    latitude: number;
  } | null;
  [key: string]: any;
}

async function delay(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

async function shippingTotal(job: Job, supplier: Supplier, weight: number) {
  const jl = job?.location_coordinates;
  const sl = supplier?.address_coordinates;
  if (!jl || !sl) return null;

  const jlPt = [jl.longitude, jl.latitude];
  const slPt = [sl.longitude, sl.latitude];

  const d = distance(slPt, jlPt);

  // TODO: Change this hardcoded dummy function to a real one
  const price = d * 0.1 + 50 + weight * 0.1;
  await delay(5000);
  return price;
}

interface Group {
  items: OrderedMap<string, ItemRecordCore>;
  types: OrderedMap<string, Type>;
  [key: string]: any;
}

type Type = TablesGenerated<"types">;
type ProductList = TablesGenerated<"product_lists">;
type PriceEntry = TablesGenerated<"price_entries">;

interface PriceLibrary {
  [key: string]: OrderedMap<string, PriceEntry>;
}

function orderProductLists(productLists: Array<ProductList>) {
  const assignments = bucketArray(productLists, "parent_id");
  const unparented = productLists.filter((pl) => !pl.parent_id);
  return unparented.reduce((ordered, pl) => {
    ordered.push(pl);

    if (assignments[pl.id]) {
      ordered.push(...assignments[pl.id]);
    }

    return ordered;
  }, []);
}

function makePriceLibrary(priceEntries: Array<PriceEntry>) {
  return priceEntries.reduce((acc, e) => {
    if (!acc[e.product_id]) {
      acc[e.product_id] = {};
    }
    acc[e.product_id][e.list_id] = e;

    return acc;
  }, {});
}

function itemArea(item: ItemRecordCore) {
  return item.width && item.height ? (item.cache.width / 12) * (item.cache.height / 12) * item.quantity : 0;
}

function getPrice(entry: PriceEntry, item: ItemRecordCore) {
  if (!entry.unit_price || !entry.unit) return 0;

  if (entry.unit === "item") {
    return entry.unit_price;
  } else if (entry.unit === "sqft") {
    return entry.unit_price * itemArea(item);
  } else if (entry.unit === "sqin") {
    return entry.unit_price * itemArea(item) * 144;
  } else if (entry.unit === "m2") {
    return entry.unit_price * itemArea(item) * 0.092903;
  } else {
    return 0;
  }
}

function itemPrice(
  type: Type,
  item: ItemRecordCore,
  productList: ProductList,
  autoProductList: ProductList,
  priceEntries: PriceLibrary
) {
  if (!type || !item || !productList?.id) return 0;

  if (item.price_override != null) return item.price_override / 100;

  const priceEntry = priceEntries[type.product_id]?.[productList.id];
  const autoEntry = priceEntries[type.product_id]?.[autoProductList?.id];

  if (!priceEntry) return 0;

  if (autoEntry && (!priceEntry.unit || priceEntry.unit_price == null)) {
    return getPrice(autoEntry, item);
  }

  return getPrice(priceEntry, item);
}

function findChildProductList(children: Array<ProductList>, area: number) {
  return children.find((c) => {
    if (c.minimum_area != null && area < c.minimum_area) return false;
    if (c.maximum_area != null && area > c.maximum_area) return false;
    return true;
  });
}

function priceSubtotal(
  group: Group,
  items: Array<ItemRecordCore>,
  productList: ProductList,
  autoProductList: ProductList,
  priceEntries: PriceLibrary
) {
  return items.reduce((acc, item) => {
    const type = group.types[item.type_id];
    if (!type || !priceEntries || !productList) return acc;
    const price = itemPrice(type, item, productList, autoProductList, priceEntries) * item.quantity;
    return acc + price;
  }, 0);
}

export type { ProductList, PriceEntry, PriceLibrary };
export {
  shippingTotal,
  getPrice,
  makePriceLibrary,
  orderProductLists,
  itemPrice,
  findChildProductList,
  priceSubtotal,
};
