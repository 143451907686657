import { Drawing, darken } from "drawing";
import glassLayer from "./glass-layer";

function materialDrawing(material) {
  const thickness = material.cache.thickness;

  const height = 3.5;
  const xOffset = 1;
  const yOffset = 2;

  const lt = Number.isFinite(thickness) ? thickness : 0.375;

  const cutColor = material.color || "#BED9F5";
  const topColor = darken(0.4, cutColor);
  const shapeColor = darken(0.6, cutColor);

  const shape = glassLayer(
    0,
    lt,
    height,
    xOffset,
    yOffset,
    cutColor,
    shapeColor,
    topColor,
    "#333333",
    false
  );

  return new Drawing().add(shape);
}

export default materialDrawing;
