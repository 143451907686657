import { add, scale } from "vector";
import transformPt from "../utils/transform-pt.js";
import ngon from "../utils/ngon.js";

class Mark {
  constructor(
    center,
    label,
    { shape = "circle", offset = { x: 0, y: 0 }, highlighted = false } = {} // options
  ) {
    this.center = center;
    this.label = label;
    this.shape = shape;
    this.offset = offset;
    this.type = "mark";
    this.highlighted = highlighted;
  }

  render(options) {
    const ctx = options.ctx;
    const annoScale = options.annoScale;
    const fontSize = options.fontSize * annoScale;
    const ctr = add(this.center, scale(this.offset, annoScale));
    ctx.style("fontSize", options.fontSize);
    ctx.style("font", "Menlo");
    ctx.style("textAlign", "center");
    ctx.style("annoScale", annoScale);
    const mt = this.label ? ctx.measureText(this.label) : ctx.measureText("");
    const tw = mt.width * annoScale;
    const tha =
      ((mt.actualBoundingBoxAscent - mt.actualBoundingBoxDescent) / 2) *
      annoScale;

    // Draw text container
    if (this.shape === "circle") {
      if (tw > fontSize * 1.8) {
        const segLn = tw - fontSize;

        ctx.beginPath();
        ctx.moveTo(ctr.x + segLn / 2, ctr.y - fontSize);
        ctx.arcTo(ctr.x + segLn / 2, ctr.y + fontSize, fontSize, "1", "1");
        ctx.lineTo(ctr.x - segLn / 2, ctr.y + fontSize);
        ctx.arcTo(ctr.x - segLn / 2, ctr.y - fontSize, fontSize, "1", "1");
        ctx.closePath();

        if (options.fill) {
          ctx.style("fill", options.fill);
          ctx.fill();
        }

        if (options.stroke && options.lineWidth) {
          ctx.style("stroke", options.stroke);
          ctx.style("lineWidth", options.lineWidth * annoScale);
          ctx.stroke();
        }

        if (this.highlighted) {
          const o =
            options.lineWidth * annoScale * (1 + options.highlightOffset);

          ctx.beginPath();
          ctx.moveTo(ctr.x + segLn / 2, ctr.y - fontSize - o);
          ctx.arcTo(
            ctr.x + segLn / 2,
            ctr.y + fontSize + o,
            fontSize + o,
            "1",
            "1"
          );
          ctx.lineTo(ctr.x - segLn / 2, ctr.y + fontSize + o);
          ctx.arcTo(
            ctr.x - segLn / 2,
            ctr.y - fontSize - o,
            fontSize + o,
            "1",
            "1"
          );
          ctx.closePath();

          ctx.style("fill", "none");
          ctx.style("stroke", options.highlightColor);
          ctx.style("lineWidth", options.highlightedLineWidth * annoScale);
          ctx.stroke();
        }
      } else {
        ctx.circle(ctr.x, ctr.y, fontSize);

        if (options.fill) {
          ctx.style("fill", options.fill);
          ctx.fill();
        }

        if (options.stroke && options.lineWidth) {
          ctx.style("stroke", options.stroke);
          ctx.style("lineWidth", options.lineWidth * annoScale);
          ctx.stroke();
        }

        if (this.highlighted) {
          const o =
            options.lineWidth * annoScale * (1 + options.highlightOffset);

          ctx.circle(ctr.x, ctr.y, fontSize + o);

          ctx.style("fill", "none");
          ctx.style("stroke", options.highlightColor);
          ctx.style("lineWidth", options.highlightedLineWidth * annoScale);
          ctx.stroke();
        }
      }
    } else if (this.shape === "rectangle") {
      const ext = Math.max(tw / 2 + fontSize / 2, fontSize);

      ctx.beginPath();
      ctx.moveTo(ctr.x - ext, ctr.y - fontSize);
      ctx.lineTo(ctr.x + ext, ctr.y - fontSize);
      ctx.lineTo(ctr.x + ext, ctr.y + fontSize);
      ctx.lineTo(ctr.x - ext, ctr.y + fontSize);
      ctx.closePath();

      if (options.fill) {
        ctx.style("fill", options.fill);
        ctx.fill();
      }

      if (options.stroke && options.lineWidth) {
        ctx.style("stroke", options.stroke);
        ctx.style("lineWidth", options.lineWidth * annoScale);
        ctx.stroke();
      }

      if (this.highlighted) {
        const o = options.lineWidth * annoScale * (1 + options.highlightOffset);

        ctx.beginPath();
        ctx.moveTo(ctr.x - ext - o, ctr.y - fontSize - o);
        ctx.lineTo(ctr.x + ext + o, ctr.y - fontSize - o);
        ctx.lineTo(ctr.x + ext + o, ctr.y + fontSize + o);
        ctx.lineTo(ctr.x - ext - o, ctr.y + fontSize + o);
        ctx.closePath();

        ctx.style("fill", "none");
        ctx.style("stroke", options.highlightColor);
        ctx.style("lineWidth", options.highlightedLineWidth * annoScale);
        ctx.stroke();
      }
    } else if (this.shape === "hexagon") {
      const vertices = ngon(ctr.x, ctr.y, fontSize, 6, Math.PI / 6);

      if (tw > fontSize * 1.8) {
        const inc = (tw - fontSize) / 2;

        vertices[0] = add(vertices[0], { x: -inc, y: 0 });
        vertices[1] = add(vertices[1], { x: -inc, y: 0 });
        vertices[2] = add(vertices[2], { x: -inc, y: 0 });
        vertices[3] = add(vertices[3], { x: inc, y: 0 });
        vertices[4] = add(vertices[4], { x: inc, y: 0 });
        vertices[5] = add(vertices[5], { x: inc, y: 0 });
      }

      const [start, ...rest] = vertices;
      ctx.beginPath();
      ctx.moveTo(start.x, start.y);
      rest.forEach((v) => {
        ctx.lineTo(v.x, v.y);
      });
      ctx.closePath();

      if (options.fill) {
        ctx.style("fill", options.fill);
        ctx.fill();
      }

      if (options.stroke && options.lineWidth) {
        ctx.style("stroke", options.stroke);
        ctx.style("lineWidth", options.lineWidth * annoScale);
        ctx.stroke();
      }

      if (this.highlighted) {
        const o = options.lineWidth * annoScale * (1 + options.highlightOffset);

        const oVertices = ngon(ctr.x, ctr.y, fontSize + o, 6, Math.PI / 6);
        if (tw > fontSize * 1.8) {
          const inc = (tw - fontSize) / 2;

          oVertices[0] = add(oVertices[0], { x: -inc, y: 0 });
          oVertices[1] = add(oVertices[1], { x: -inc, y: 0 });
          oVertices[2] = add(oVertices[2], { x: -inc, y: 0 });
          oVertices[3] = add(oVertices[3], { x: inc, y: 0 });
          oVertices[4] = add(oVertices[4], { x: inc, y: 0 });
          oVertices[5] = add(oVertices[5], { x: inc, y: 0 });
        }

        const [oStart, ...oRest] = oVertices;

        ctx.beginPath();
        ctx.moveTo(oStart.x, oStart.y);
        oRest.forEach((v) => {
          ctx.lineTo(v.x, v.y);
        });
        ctx.closePath();

        ctx.style("fill", "none");
        ctx.style("stroke", options.highlightColor);
        ctx.style("lineWidth", options.highlightedLineWidth * annoScale);
        ctx.stroke();
      }
    } else if (this.shape === "pentagon") {
      const vertices = ngon(ctr.x, ctr.y, fontSize, 5);
      const [start, ...rest] = vertices;
      ctx.beginPath();
      ctx.moveTo(start.x, start.y);
      rest.forEach((v) => {
        ctx.lineTo(v.x, v.y);
      });
      ctx.closePath();

      if (options.fill) {
        ctx.style("fill", options.fill);
        ctx.fill();
      }

      if (options.stroke && options.lineWidth) {
        ctx.style("stroke", options.stroke);
        ctx.style("lineWidth", options.lineWidth * annoScale);
        ctx.stroke();
      }
    }

    // Draw text
    ctx.style("fill", options.textColor);
    ctx.style("fontSize", options.fontSize);
    ctx.text(this.label || "", ctr.x, ctr.y - tha);
  }

  renderHitbox(options) {
    this.render(options);
    const annoScale = options.annoScale;
    const ctr = add(this.center, scale(this.offset, annoScale));

    return { pt: ctr };
  }

  get bbox() {
    return {
      xmin: this.center.x,
      xmax: this.center.x,
      ymin: this.center.y,
      ymax: this.center.y,
    };
  }

  transform(matrix) {
    const ctr = transformPt(this.center, matrix);
    return new Mark(ctr, this.label, {
      shape: this.shape,
      offset: this.offset,
      highlighted: this.highlighted,
    });
  }
}

export default Mark;
