<svelte:options strictprops={false} />

<script>
  export let readOnly = false;
  export let value = "";
  export let disabled;
  export let optionMap = {};

  $: option = optionMap[value];
</script>

<div class="p-1 w-full flex items-center space-x-1" class:opacity-50={disabled} class:italic={readOnly}>
  {#if option}
    <div
      style={`background-color:${option.color || "blue"};border-color:${
        option.borderColor || option.color || "blue"
      };`}
      data-clickthrough="clickthrough"
      class="w-3 h-3 rounded-lg border" />
    <div>{option.label}</div>
  {:else}
    <div class="w-3 h-3 border border-gray-500 rounded-lg" data-clickthrough="clickthrough" />
  {/if}
</div>
