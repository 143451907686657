import { Drawing, darken } from "drawing";

function glassLayer(
  x,
  thickness,
  height,
  xOffset,
  yOffset,
  cutColor,
  shapeColor,
  topColor,
  strokeColor,
  highlighted = false,
  flipped = false,
) {
  const t = "#82C3F7";
  const t2 = "#4E98DC";
  const t3 = "#70A7DA";

  const sShape = flipped
    ? [
        { x, y: height + yOffset },
        { x: x + thickness, y: height + yOffset },
        { x: x + thickness + xOffset, y: height },
        { x: x + thickness + xOffset, y: 0 },
        { x: x + xOffset, y: 0 },
        { x: x, y: yOffset },
      ]
    : [
        { x, y: height },
        { x, y: 0 },
        { x: x + thickness, y: 0 },
        { x: x + thickness + xOffset, y: yOffset },
        { x: x + thickness + xOffset, y: height + yOffset },
        { x: x + xOffset, y: height + yOffset },
      ];

  const shape = new Drawing().polygon(sShape).style({
    stroke: highlighted ? "black" : "#888888",
    fill: highlighted ? darken(-0.5, shapeColor, t) : shapeColor,
  });

  const tShape = flipped
    ? [
        { x, y: height + yOffset },
        { x: x + thickness, y: height + yOffset },
        { x: x + thickness + xOffset, y: height },
        { x: x + xOffset, y: height },
      ]
    : [
        { x, y: height },
        { x: x + thickness, y: height },
        { x: x + thickness + xOffset, y: height + yOffset },
        { x: x + xOffset, y: height + yOffset },
      ];

  const top = new Drawing().polygon(tShape).style({
    stroke: highlighted ? "black" : "#888888",
    fill: highlighted ? darken(-0.5, topColor, t3) : topColor,
  });

  const lShape = flipped
    ? [
        { x: x + thickness, y: height + yOffset },
        { x: x + thickness + xOffset, y: height },
      ]
    : [
        { x: x + thickness, y: height },
        { x: x + thickness + xOffset, y: height + yOffset },
      ];

  const line = new Drawing()
    .polyline(lShape)
    .style({ stroke: highlighted ? "black" : "#888888" });

  const face = new Drawing()
    .rectangle(flipped ? x + xOffset : x, 0, thickness, height)
    .style({
      stroke: "#333333",
      fill: highlighted ? darken(-0.5, cutColor, t2) : cutColor,
    });

  return new Drawing().add(shape, top, line, face);
}

export default glassLayer;
