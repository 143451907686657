type CharXForm = (n: number | string) => number;
type CharLookup = Record<string | number, number>;

const numZero = 0x2070;

let numChars: CharLookup = {
  0: numZero,
  // Exceptions to the pattern:
  1: 0x00b9,
  2: 0x00b2,
  3: 0x00b3,
};

const denZero = 0x2080;

let denChars: CharLookup = {
  0: denZero,
};

const getDenChar: CharXForm = (n) => (denChars[n] ||= +n + denZero);
const getNumChar: CharXForm = (n) => (numChars[n] ||= +n + numZero);

function transformChars(str: string, xf: CharXForm) {
  const digits = [...str];
  const transformed = digits.map(xf);
  return String.fromCharCode(...transformed);
}

interface FormatFractionOptions {
  unicodeNumerals?: boolean;
  unicodeSlash?: boolean;
  fractionSeparator?: "dash" | "underscore" | "space" | false;
}

const fractionSeparators = {
  dash: "-",
  underscore: "_",
  space: " ",
};

function formatFraction(
  num: number,
  den: number,
  options: FormatFractionOptions = {}
) {
  let numStr = num.toString();
  let denStr = den.toString();
  const slash = options.unicodeSlash ? "\u2044" : "/";
  const separator = options.fractionSeparator
    ? fractionSeparators[options.fractionSeparator]
    : "";

  if (options.unicodeNumerals) {
    numStr = transformChars(numStr, getNumChar);
    denStr = transformChars(denStr, getDenChar);
  }

  return `${separator}${numStr}${slash}${denStr}`;
}

export { formatFraction };

export type { FormatFractionOptions };
