import { subtract, length } from "vector";

export function bulgeSagitta(a, b) {
  if (!b.bulge) return 0;
  const d = length(subtract(b, a)) * 0.5;
  return b.bulge * d;
}

export function sagittaBulge(a, b, s) {
  if (!s) return 0;
  const d = length(subtract(b, a)) * 0.5;
  return s / d;
}
