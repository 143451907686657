import {
  hasNonRectShapes,
  hasCutFabrication,
  hasNonRectShape,
} from "@local/lamina-core";

const shapeIdentColumn = {
  label: "Shape",
  prop: "data",
  type: "string",
  displayFilter: hasNonRectShapes,
  formatter(_, item) {
    if (hasCutFabrication(item) || hasNonRectShape(item)) {
      return "See drawing";
    } else {
      return "";
    }
  },
};

export { shapeIdentColumn };
