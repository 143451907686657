<svelte:options strictprops={false} />

<script>
  import { getContext, createEventDispatcher } from "svelte";
  import { exitEvents, directions } from "./constants.js";

  export let value;
  export let readOnly = false;
  export let disabled = false;
  export const focus = () => {
    container.focus();
  };

  const dispatch = createEventDispatcher();

  const { focused } = getContext("datagrid");

  let container;

  async function keydown(e) {
    if (readOnly || disabled) return;
    if (e.key === "Enter" || e.key === " ") {
      dispatch("updateValue", { value: !value });
    } else if (exitEvents[e.key]) {
      focused.move(directions[e.key], e.shiftKey);
    }
  }

  function update(e) {
    dispatch("updateValue", { value: e.target.checked });
  }
</script>

<div
  bind:this={container}
  class="p-1 w-full flex items-center justify-center outline-none"
  class:text-gray-500={disabled}
  on:keydown={keydown}
  tabindex="0">
  <input
    type="checkbox"
    data-clickthrough="clickthrough"
    bind:checked={value}
    disabled={disabled || readOnly}
    tabindex="-1"
    on:input={update} />
</div>

<style lang="scss">
  input {
    outline: none;
  }
</style>
