function getPresentRecords(data, field = "type_id") {
  const o = data.reduce((obj, row) => {
    if (row[field]) {
      obj[row[field]] = true;
    } else {
      obj["Unassigned"] = true;
    }

    return obj;
  }, {});

  return Object.keys(o).map((k) => ({ label: k, value: k }));
}

function guessRecords(
  data,
  records,
  field = "type_id",
  allowPlaceholder = true
) {
  const assigned = {};

  return data.reduce((obj, row) => {
    if (!row[field]) {
      obj["Unassigned"] = allowPlaceholder ? "placeholder" : null;
    }

    const index = records.findIndex(
      (r) =>
        typeof row[field] === "string" &&
        typeof r.mark === "string" &&
        row[field].toLowerCase() === r.mark.toLowerCase()
    );

    if (index === -1 && row[field]) {
      obj[row[field]] = allowPlaceholder ? "placeholder" : null;
    } else if (!assigned[index] && row[field]) {
      assigned[index] = true;
      obj[row[field]] = records[index].id;
    }

    return obj;
  }, {});
}

export { getPresentRecords, guessRecords };
