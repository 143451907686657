function copyToClipboard(text) {
  const tempInput = document.createElement("textarea");
  tempInput.innerHTML = text;
  document.body.appendChild(tempInput);
  tempInput.select();
  document.execCommand("copy");
  document.body.removeChild(tempInput);
}

export default copyToClipboard;
