import { Dimension, Quantity } from "dimtext";
import { subtract, normalize, scale, add, angle, reverse } from "vector";

export function edgePointRotation(edge, reference) {
  const end = reference.start === "end" ? "start" : "end";

  const evec = normalize(subtract(edge[end], edge[reference.start]));
  const l = scale(evec, reference.length.toNumber("inches"));
  const a = add(edge[reference.start], l);
  const rotation = angle(reference.start === "end" ? reverse(evec) : evec);

  const ldim = {
    type: "aligned_dim",
    start: edge[reference.start],
    end: a,
    tier: end === "end" ? -0.5 : 0.5,
    path: "reference.length",
    callback: (i) => (v) => [
      {
        path: `data.fabrications.edge.${i}.reference.length`,
        value: new Dimension(v),
      },
    ],
  };

  return { point: a, rotation, dimensions: [ldim] };
}

export function edgePoint(edge, reference) {
  const end = reference.start === "end" ? "start" : "end";

  const evec = normalize(subtract(edge[end], edge[reference.start]));
  const l = scale(evec, reference.length.toNumber("inches"));
  const a = add(edge[reference.start], l);
  const rotation = angle(reference.start === "end" ? reverse(evec) : evec);

  return { point: a, rotation, evec };
}
