<script>
  import uniq from "lodash/uniq";
  import { createEventDispatcher } from "svelte";
  import cloneDeep from "lodash/cloneDeep";
  import DuplicateIcon from "src/assets/icons/duplicate.svg";
  import { Datagrid } from "datagrid";
  import TrashIcon from "src/assets/icons/trash.svg";
  import typeIsCorrect from "@local/extensions/validators/type-is-correct.js";
  import { incrementMarkCopy } from "@local/extensions/identifiers/mark.js";
  import { hashify } from "overline";

  export let data;
  export let schema;
  export let items;
  export let columns_used;
  export let template_columns = [];
  export let flagged;

  const dispatch = createEventDispatcher();

  let verified = cloneDeep(data);
  let updateDuplicates = true;
  let showAllColumns = false;
  let invalidRows = [];

  $: usedColMap = hashify(columns_used);
  $: existingDuplicates = checkDuplicateMarks(items);
  $: newDuplicates = newDuplicateIndices(verified);
  $: duplicateMarks = checkMarks(verified, items);
  $: hasDuplicateMarks = duplicateMarks.length > 0;
  $: allMarksAreDuplicates = duplicateMarks.length === verified.length && duplicateMarks.length > 0;
  $: allColumns = schema.reduce((cols, col) => {
    if (col.type === "multi-column") {
      cols.push(...col.subcolumns);
    } else {
      cols.push(col);
    }
    return cols;
  }, []);
  $: filteredColumns = allColumns.filter((c) => usedColMap[c.prop]);
  $: filteredSchema = schema.filter((c) => usedColMap[c.prop]);
  $: columns = !showAllColumns && allMarksAreDuplicates ? filteredColumns : allColumns;
  $: usedSchema = !showAllColumns && allMarksAreDuplicates ? filteredSchema : schema;
  $: columnsAndParams = usedSchema.concat(template_columns);
  $: invalidRows = verified.reduce((indices, row, i) => {
    if (!rowIsValid(row)) indices.push(i);
    return indices;
  }, []);
  $: canAutoDelete = invalidRows.length < verified.length;
  $: valid =
    invalidRows.length === 0 &&
    newDuplicates.length === 0 &&
    !(hasDuplicateMarks && updateDuplicates && existingDuplicates.length > 0);
  $: allInvalid = uniq([...invalidRows, ...newDuplicates]);
  $: markCol = columns.findIndex((c) => c.prop === "mark");
  $: warningCells = duplicateMarks.map((i) => [i, markCol, i, markCol]);
  $: invalidCells = getInvalidCells(verified, newDuplicates, columnsAndParams);
  $: isUpdatingShape = columns.some((c) =>
    ["width", "height", "width_offset", "height_offset"].includes(c.prop),
  );
  $: flaggedRows = flagged.reduce((o, f, i) => {
    if (f) o[i] = f;
    return o;
  }, {});

  function validate(val, col) {
    try {
      const none = [undefined, null].includes(val);
      if (col.required && none) return false;
      if (none) return true;
      if (!typeIsCorrect(val, col.type)) return false;
      if (col.validator) return col.validator(val);
      return true;
    } catch (error) {
      return false;
    }
  }

  function getInvalidCells(verified, newDuplicates, columns) {
    const markCol = columns.findIndex((c) => c.prop === "mark");
    const dup = newDuplicates.reduce((obj, i) => {
      obj[i] = true;
      return obj;
    }, {});

    return verified
      .map((row, i) => {
        if (dup[i]) return [i, markCol, i, markCol];
      })
      .filter((row, i) => dup[i]);
  }

  function checkDuplicateMarks(items) {
    const usedMarks = {};
    const dupMarks = {};

    items.forEach((item) => {
      if (usedMarks[item.mark]) {
        dupMarks[item.mark] = true;
        dupMarks[usedMarks[item.mark]] = true;
      } else {
        usedMarks[item.mark] = item.mark;
      }
    });

    return Object.keys(dupMarks);
  }

  function newDuplicateIndices(verified) {
    const usedMarks = {};
    const dupMarks = {};

    verified.forEach((item, index) => {
      if (usedMarks[item.mark] !== undefined) {
        dupMarks[index] = true;
        dupMarks[usedMarks[item.mark]] = true;
      } else {
        usedMarks[item.mark] = index;
      }
    });

    return Object.keys(dupMarks).map((index) => parseInt(index));
  }

  function rowIsValid(row) {
    return columns.every((col) => {
      const val = row[col.prop];
      return validate(val, col);
    });
  }

  function checkMarks(data, items) {
    const existingMarks = items.reduce((marks, row) => {
      marks[row.mark] = true;
      return marks;
    }, {});

    let dupes = [];

    data.forEach((item, index) => {
      if (existingMarks[item.mark]) {
        dupes.push(index);
      }
    });

    return dupes;
  }

  function renameDuplicateMarks() {
    const dupeSets = {};
    newDuplicates.forEach((index) => {
      const row = verified[index];
      if (!dupeSets[row.mark]) dupeSets[row.mark] = [];
      dupeSets[row.mark].push(index);
    });

    Object.keys(dupeSets).forEach((mark) => {
      const indices = dupeSets[mark];
      const changed = indices.slice(1);
      changed.forEach((index) => {
        const row = verified[index];
        const newMark = incrementMarkCopy(
          row.mark,
          verified.map((i) => i.mark),
        );
        verified[index] = { ...row, mark: newMark };
      });
    });
  }

  function deleteInvalidRows() {
    const newData = verified.reduce((data, row, i) => {
      if (!allInvalid.includes(i)) data.push(row);
      return data;
    }, []);
    verified = newData;
  }

  function deleteRow(e) {
    const { row } = e.detail;
    flagged = flagged.filter((f, i) => i !== row);
  }

  function prev() {
    dispatch("prev");
  }

  function finish() {
    let finalized;
    const marks = items.map((i) => i.mark);
    if (hasDuplicateMarks && !updateDuplicates) {
      finalized = verified.map((i) => ({
        ...i,
        mark: incrementMarkCopy(i.mark, marks),
      }));
    } else {
      finalized = verified;
    }

    dispatch("finish", { verified: finalized, updateDuplicates });
  }
</script>

<div class="space-y-4 flex flex-col overflow-hidden">
  <p>Confirm data before importing.</p>
  {#if flagged.some((f) => f)}
    <p class="text-red-500">
      The highlighted items do not match the expected template. Please contact support for assistance.
    </p>
  {/if}
  {#if hasDuplicateMarks}
    <div class="flex justify-between items-start">
      <div class="space-y-1 px-4">
        <div>
          {duplicateMarks.length} item(s) have <span class="bg-amber-200">marks</span> that are already in use:
        </div>
        <div class="flex gap-2 items-center px-4 text-xs">
          <input
            type="radio"
            checked={updateDuplicates}
            value={true}
            on:input={() => (updateDuplicates = true)} />
          <div>Update existing items</div>
        </div>
        <div class="flex gap-2 items-center px-4 text-xs">
          <input
            type="radio"
            checked={!updateDuplicates}
            value={false}
            on:input={() => (updateDuplicates = false)} />
          <div>Create new items</div>
        </div>
      </div>
      {#if allMarksAreDuplicates}
        <div class="flex gap-2 items-center text-xs">
          <input type="checkbox" bind:checked={showAllColumns} />
          <div>Show all columns</div>
        </div>
      {/if}
    </div>

    {#if existingDuplicates.length && updateDuplicates}
      <div class="space-y-1 px-4">
        <div>
          WARNING: Duplicate marks are present among existing items. Please close this import dialog and
          ensure that all marks are unique before importing.
        </div>
      </div>
    {/if}
  {/if}
  <Datagrid
    bind:data={verified}
    columns={columnsAndParams}
    {flaggedRows}
    {warningCells}
    {invalidCells}
    rowDelete
    on:deleteRow={deleteRow} />
  {#if hasDuplicateMarks && updateDuplicates && isUpdatingShape}
    <div class="italic px-4">
      * Non-rectangular items with width/height updates will be converted to rectangular shapes.
    </div>
  {/if}
  {#if allInvalid.length}
    <div class="flex gap-2 items-center px-4 text-sm">
      <div>{allInvalid.length} row(s) are invalid:</div>
      {#if newDuplicates.length}
        <button
          class="btn btn-compact flex gap-1 items-center justify-center"
          on:click={renameDuplicateMarks}
          class:disabled={!canAutoDelete}
          disabled={!canAutoDelete}
          ><DuplicateIcon />
          <div>Make Marks Unique</div>
        </button>
      {/if}
      <button
        class="btn btn-compact btn-danger flex gap-1 items-center justify-center"
        on:click={deleteInvalidRows}
        class:disabled={!canAutoDelete}
        disabled={!canAutoDelete}
        ><TrashIcon />
        <div>Remove Invalid Rows</div>
      </button>
    </div>
  {/if}
  <div class="flex justify-end items-center space-x-2">
    <button class="btn w-32" on:click={prev}>Previous</button>
    <button class="btn btn-primary w-32" class:disabled={!valid} disabled={!valid} on:click={finish}
      >Finish</button>
  </div>
</div>
