import { darken } from "drawing";
import colorLuma from "./color-luma.js";

function typeColor(type) {
  if (type.data?.layers?.length > 0) {
    const l0 = type.data.layers[0];
    let color;
    if (l0.outboard_surface?.color) {
      color = l0.outboard_surface.color;
    } else if (l0.inboard_surface?.color) {
      color = l0.inboard_surface.color;
    } else if (l0.material?.color) {
      color = l0.material.color;
    }

    if (color) {
      const l = colorLuma(color);
      if (l < 200) {
        const pct = (200 - l) / 255;
        color = darken(pct, color);
      }
      return color;
    }
  }

  return "#B7D7F7";
}

function typeColorTrue(type) {
  if (type.data?.layers?.length > 0) {
    const l0 = type.data.layers[0];
    let color;
    if (l0.outboard_surface?.color) {
      color = l0.outboard_surface.color;
    } else if (l0.inboard_surface?.color) {
      color = l0.inboard_surface.color;
    } else if (l0.material?.color) {
      color = l0.material.color;
    }

    if (color) return color;
  }

  return null;
}

export default typeColor;
export { typeColorTrue, typeColor };
