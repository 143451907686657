import cloneDeep from "lodash/cloneDeep.js";

import type { ItemRecordCore } from "./item.js";

interface LineVertex {
  x: number;
  y: number;
}

interface ArcVertex extends LineVertex {
  bulge: number;
}

interface FilletVertex extends LineVertex {
  fillet: number;
}

type Vertex = LineVertex | ArcVertex | FilletVertex;

type NoShape = {};

// TODO: Make shape a discriminated union
// interface NoShape {
//   type: "none"
// }

interface FreeShape {
  type: "free",
  vertices: Array<Array<Vertex>>
}

type Shape = NoShape | FreeShape

interface Shaped {
  shape: Shape;
}

function createFreeShape(item: ItemRecordCore) {
  if (item.shape.type === "free") {
    return cloneDeep(item.shape);
  }

  const ox = item.rectangle_offset?.x ?? 0;
  const oy = item.rectangle_offset?.y ?? 0;

  return {
    type: "free",
    vertices: [
      [
        { x: ox, y: oy },
        { x: ox + item.cache.width, y: oy },
        { x: ox + item.cache.width, y: oy + item.cache.height },
        { x: ox, y: oy + item.cache.height },
      ],
    ],
  };
}

function hasNonRectShape({ shape }: Shaped) {
  return "type" in shape && shape.type === "free";
}

function hasNonRectShapes(items: Array<Shaped>) {
  return items.some((item) => hasNonRectShape(item));
}

export type { Vertex, Shape, Shaped }

export { createFreeShape, hasNonRectShape, hasNonRectShapes };
