function typeIsCorrect(v, type) {
  if (v === undefined || v === null || type === undefined) return true;

  if (type === "select") return true;

  if (type === "integer") return Number.isInteger(v);

  return typeof v === type;
}

export default typeIsCorrect;
