<script>
  import PaperclipIcon from "../../assets/icons/paperclip-xs.svg";
  import ItemIcon from "src/assets/icons/location-item-xs.svg";
  import CommentIcon from "../../assets/icons/comment-xs.svg";

  export let attachments = 0;
  export let comments = 0;
  export let items = 0;
  export let quantity = 0;
</script>

{#if attachments > 0 || comments > 0 || items > 0 || quantity > 1}
  <div class="badges">
    {#if items > 0}
      <div class="flex gap-0.5 text-xs items-center">
        <ItemIcon />
        <div>({items})</div>
      </div>
    {/if}
    {#if attachments > 0}
      <div>
        <PaperclipIcon />
      </div>
    {/if}
    {#if comments > 0}
      <div>
        <CommentIcon />
      </div>
    {/if}
    {#if quantity > 1}
      <div class="text-xs">
        ({quantity})
      </div>
    {/if}
  </div>
{/if}

<style lang="scss">
  .badges {
    @apply absolute right-0 flex items-center z-10 p-2 gap-1 rounded-full text-gray-600;
    top: -1.5rem;
  }
</style>
