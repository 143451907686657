import range from "lodash/range";

// By default, do not regard a cell with value 0 as empty.
function isNullishOrEmpty(str) {
  return str === null || str === undefined || str === "";
}

function makeIndex(n) {
  return {
    max: n,

    candidates: new Set(range(n)),

    /** @param {(k: number) => void} fn */
    forEach(fn) {
      [...this.candidates].forEach(fn);
    },

    /** @param {number} m */
    extend(m) {
      if (m > this.max) {
        for (let c = this.max; c < m; c++) {
          this.candidates.add(c);
        }
        this.max = m;
      }
    },
  };
}

const defaultOptions = {
  isEmpty: isNullishOrEmpty,
  // TODO:
  // skipEmptyRows: true,
  // skipEmptyCols: true,
};

/**
 * @template T
 * @param {Array<Array<T>>} rows
 * @param {typeof defaultOptions} options
 */
function removeEmptyDims(rows, options = defaultOptions) {
  const { isEmpty } = options;

  const cidx = makeIndex(rows[0].length);
  const pickRows = [];
  const pickCols = [];

  rows.forEach((row, r) => {
    cidx.extend(row.length);

    // first non-empty column
    const k0 = row.findIndex((cell) => !isEmpty(cell));

    if (k0 !== -1) {
      // row is not empty
      pickRows.push(r);
      cidx.forEach((k) => {
        if (k >= k0 && !isEmpty(row[k])) {
          cidx.candidates.delete(k);
          pickCols.push(k);
        }
      });
    }
  });

  pickCols.sort();

  return pickRows.map((r) => pickCols.map((c) => rows[r][c] ?? ""));
}

export default removeEmptyDims;
