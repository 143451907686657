import { Dimension } from "dimtext";
import type { MaterialRecordCore } from "./product.js";

interface HydratedMaterial {
    data: {
        thickness?: Dimension
    }
    cache?: {
        thickness?: number
    }
}

function updateMaterialCache(material: HydratedMaterial) {
    let thickness: number | undefined = undefined;

    if (material.data?.thickness) {
        thickness = material.data.thickness.toNumber("inches");
    }

    material.cache = { thickness };
    return material;
}

function hydrateMaterial(material: MaterialRecordCore): HydratedMaterial {
    if (material.data?.thickness) {
        material.data.thickness = new Dimension(material.data.thickness);
    }

    const result = material as HydratedMaterial;

    updateMaterialCache(result);
    return result;
}

export {
    updateMaterialCache,
    hydrateMaterial,
};

export type {
    HydratedMaterial,
}
