function transformPt(pt, [a, b, c, d, e, f]) {
  return {
    ...pt,
    x: pt.x * a + pt.y * c + e,
    y: pt.x * b + pt.y * d + f,
  };
}

export function transform(points, matrix) {
  return points.map((pt) => transformPt(pt, matrix));
}

export function translate(points, x = 0, y = 0) {
  return points.map((pt) => ({ ...pt, x: pt.x + x, y: pt.y + y }));
}

export function rotate(points, angle) {
  const cos = Math.cos(angle);
  const sin = Math.sin(angle);
  return transform(points, [cos, sin, -sin, cos, 0, 0]);
}

export function reverse(pts) {
  let result = [];

  if (pts.length > 0) {
    const last = pts[pts.length - 1];
    const first = pts[0];

    result.push({
      x: last.x,
      y: last.y,
    });

    if (first.bulge) {
      result[0].bulge = -first.bulge;
    }
  }

  for (let i = pts.length - 2; i >= 0; i--) {
    const pt = {
      x: pts[i].x,
      y: pts[i].y,
    };

    if (pts[i + 1].bulge) {
      pt.bulge = -pts[i + 1].bulge;
    }

    result.push(pt);
  }

  return result;
}

export function mirrorHorizontal(pts) {
  return reverse(
    pts.map((p) => ({
      x: -p.x,
      y: p.y,
      ...(p.bulge && { bulge: -p.bulge }),
    })),
  );
}

export function mirrorVertical(pts) {
  return reverse(
    pts.map((p) => ({
      x: p.x,
      y: -p.y,
      ...(p.bulge && { bulge: -p.bulge }),
    })),
  );
}

export function mirrorBoth(pts) {
  return rotate(pts, Math.PI);
}
